import {FC} from 'react'
import {IAppliedDailyDealModel} from '../cors/_models'
import {useIntl} from 'react-intl'
import NormalMessage from '../../common/NormalMessage'
import AppliedDailyDealRow from "./AppliedDailyDealRow";

interface IProps {
    products: IAppliedDailyDealModel[],
    refreshData: () => Promise<void>
}

const AppliedDailyDealTable: FC<IProps> = ({products, refreshData}) => {

    const {formatMessage} = useIntl()

    return (
        <>

            {products.length > 0 ? (
                    <table className='table align-middle gs-0 gy-5'>
                        <thead>
                        <tr>
                            <th className='fw-bold p-0'>{formatMessage({id: 'image'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'prod_name'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'barcode'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'quantity'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'sale_price'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'cost_price'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'date_start'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'date_end'})}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {products &&
                            products.map((product) => (
                                <AppliedDailyDealRow
                                    product={product}
                                    key={product.product_special_id}
                                />
                            ))}
                        </tbody>
                    </table>
            ) : (
                <NormalMessage msg={formatMessage({id: 'no_data'})}/>
            )}
        </>
    )
}

export default AppliedDailyDealTable
