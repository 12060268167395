import Select from 'react-select'
import {ISelectOptions} from '../cors/_models'
import {FC, useState} from 'react'

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]
type IProps = {
  label?: string
  isRequired?: boolean
  id: string
  onChange?: any
  value?: any
  options?: any
  errorMsg?: string
  hasError?: boolean
}

const DDLField: FC<IProps> = ({isRequired, id, onChange, value, options, errorMsg, hasError}) => {
  

  const handleChange = (event: {label: string; value: number} | any) => {
    const e = {
      target: {
        id: id,
        value: event.value,
      },
    }
    onChange(e)
  }

  return (
    <>
      <Select
        // defaultInputValue={state.value}
        value={{
          value: value,
          label: options.find((item: any) => {
            if (item.value === value) return item.label
          })?.label || '',
        }}
        onChange={handleChange}
        options={options}
      />
      {isRequired && hasError && (
        <label className='form-label fw-bold text-danger'>
          {errorMsg ? errorMsg : 'This field is required'}
        </label>
      )}
    </>
  )
}

export default DDLField
