import axios from 'axios';
import {
    IReportSalesModel,
    ITotalSalesRow,
    ITransactionReportModel,
    ITotalTransactionRow,
    IInTransitReportModel,
    ITotalInTransitRow, IStatementReportModel
} from './_models';

const API_URL = process.env.REACT_APP_API_URL;
export const GET_SALES_REPORT = `${API_URL}/api/partner-portal/supplier-sales`;
export const GET_TRANSACTION_REPORT = `${API_URL}/api/partner-portal/supplier-transaction`;
export const GET_IN_TRANSIT_SALES_REPORT = `${API_URL}/api/partner-portal/in-transit`;
export const GET_STATEMENT_REPORT =  `${API_URL}/api/partner-portal/supplier-statement-list`;

export function getSalesReports(
    filtersObj: any,
    token: string | undefined,
    language: string
) {
    return axios.get<{ data: { sales_products: IReportSalesModel[]; month: string; total_sales: ITotalSalesRow } }>(
        `${GET_SALES_REPORT}${filtersObj}${filtersObj === '' ? '?' : '&'}`,
        {headers: {token: token || '', language: language}}
    );
}


export function getTransactionReports(
    filtersObj: any,
    token: string | undefined,
    language: string
) {
    const queryParams = `${filtersObj}${filtersObj === '' ? '?' : '&'}`;

    return axios.get<{
        data: { transaction_products: ITransactionReportModel[]; total_transaction: ITotalTransactionRow }
    }>(
        `${GET_TRANSACTION_REPORT}${queryParams}`,
        {
            headers: {
                token: token || '',
                language: language,
            },
        }
    );
}

export function getInTransitReports(
    filtersObj: any,
    token: string | undefined,
    language: string
) {

    const queryParams = `${filtersObj}${filtersObj === '' ? '?' : '&'}`;
    return axios.get<{ data: { in_transit_products: IInTransitReportModel[]; total_sales: ITotalInTransitRow } }>(
        `${GET_IN_TRANSIT_SALES_REPORT}${queryParams}`,
        {
            headers: {
                token: token || '',
                language: language,
            },
        }
    );
}

export function getStatementReports(
    token: string | undefined,
    language: string
) {
    return axios.get<{
        status: number
        message: string
        supplier_statements_list: IStatementReportModel[]
    }>(`${GET_STATEMENT_REPORT}`, {
        headers: {
            token: token || '',
            language: language,
        },
    })
}



