import {FC} from 'react'
import {TransactionReports} from '../../modules/reports/TransactionReports'

const TransactionReportsPage: FC = () => {
    return (
        <>
            <TransactionReports/>
        </>
    )
}

export default TransactionReportsPage
