import { useIntl } from 'react-intl';
import PageHeader from '../common/PageHeader'
import SpecialBulkCard from './add-special/SpecialBulkCard'

const AddSpecialBulk = () => {
  const {formatMessage} = useIntl();
  return (
    <>
        <PageHeader title={formatMessage({ id: "add_discount_bulk" })} />
        <SpecialBulkCard />
    </>
  )
}

export default AddSpecialBulk