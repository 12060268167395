interface tabs {
  label: string
  link: string
}
export const RequestPagesTabs: tabs[] = [
  {
    label: 'add_products',
    link: '/request-add-products',
  },
  {
    label: 'update_products',
    link: '/request-update-products',
  },
  {
    label: 'add_discount',
    link: '/request-add-discount',
  },
]

export interface ISuggestedCostModel {
  status: string
  cost: string
  suggested_cost: string
  date_added: string
  name: string
  model: string
  barcode: string
  supplier_order_id: number
  reason_id?: null | string
  reason?: null | string
}
export enum SUGGESTED_COST_FILTERS {
  ID = 'supplier_order_id_filter',
  NAME = 'name_filter',
  BARCODE = 'barcode_filter',
  MODEL = 'model_filter',
  DATE = 'date_added_filter',
  STATUS = 'status_filter'
}
export type ISuggestedCostTableFilter = {
  current_page: number

  supplier_order_id_filter: string
  name_filter: string
  barcode_filter: string
  model_filter: string
  date_added_filter: string
  status_filter: string
}

export const SuggestedCostTableFilterInitialState: ISuggestedCostTableFilter = {
  supplier_order_id_filter: '',
  name_filter: '',
  barcode_filter: '',
  model_filter: '',
  date_added_filter: '',
  status_filter: '',
  current_page: 1,
}
