import SharedData from '../common/SharedData'
import ActiveProductsCard from './active-products/ActiveProducts'
import BestSellerProductsCard from './best-seller-products/BestSellerProductsCard'
import DailySales from './daily-sales/DailySales'
import MonthlyServiceCard from './monthly-service/MonthlyServiceCard'
import NextPaymentCard from './next-payment/NextPaymentCard'
import PendingOrdersCard from './pending/PendingOrdersCard'
import SalesChart from './sales/SalesChart'
import StockQtyCard from './stock-quantity/StockQtyCard'
import LossOfOpportunityTotalCard from './loss-of-opportunity/LossOfOpportunityTotalCard'

const MyDashboard = () => {
  const {currentUser, language} = SharedData()
  return (
    <>
      <div className='row g-10 mb-10'>
        <div className='col-md-6 col-lg-4'>
          <SalesChart
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch h-md-100'
          />
        </div>
        <div className='col-md-6 col-lg-3 gap-10 d-flex flex-column'>
          <DailySales
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch'
          />
          <LossOfOpportunityTotalCard
              currentUser={currentUser}
              language={language}
              className='card-xl-stretch'
          />
          <StockQtyCard
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch'
          />
          <ActiveProductsCard
              currentUser={currentUser}
              language={language}
              className='card-xl-stretch'
          />
        </div>
        <div className='col-md-12 col-lg-5 gap-10 d-flex flex-column'>
          <MonthlyServiceCard
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch h-md-50'
          />
        </div>
      </div>
      <div className='row g-10'>
        <div className='col-md-6'>
          <BestSellerProductsCard
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch mb-xl-8 h-md-100'
          />
        </div>
        <div className='col-md-6'>
          <PendingOrdersCard
            currentUser={currentUser}
            language={language}
            className='card-xl-stretch mb-xl-8 h-md-100'
          />
        </div>
      </div>
    </>
  )
}

export default MyDashboard
