import React, {FC, useRef} from 'react'
import { ISpecialProductModel } from '../core/_models'

type IProps = {
  item: ISpecialProductModel
  onChange: any
  isNewBarcode?: boolean
}
const ConnectedBarcodeTableRow: FC<IProps> = ({item, onChange, isNewBarcode}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(item.barcode, item.supplier_product_special_id, e.target.checked)
  }
  

  return (
    <tr className={`${isNewBarcode ? 'border-top border-dashed border-dark' : ''}`}>
      <td>
        <input
          ref={inputRef}
          onChange={handleChange}
          className='form-check-input'
          type='checkbox'
          value=''
          id='flexCheckDefault'
        />
      </td>
      <td>{item.barcode}</td>
      <th>
        <img width='100' height='100' src={item.image} />
      </th>
      <td>{item.name}</td>
      <td>{item.model}</td>
      <td>{item.data.quantity}</td>
      <td>{item.data.cost_price.new_cost_price.toFixed(2)}</td>
      <td>{item.data.sale_price.new_sale_price}</td>
    </tr>
  )
}

export default ConnectedBarcodeTableRow
