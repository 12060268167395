import {FC} from 'react'
import { SalesReports } from '../../modules/reports/SalesReports'

const SalesReportsPage: FC = () => {
  return (
    <>
      <SalesReports />
    </>
  )
}

export default SalesReportsPage
