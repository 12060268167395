import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ILossOfOpportunityModel} from "../products/cors/_models";

type IProps = {
    item: ILossOfOpportunityModel,
    showDetails: (params?: ILossOfOpportunityModel) => void
}

const LossOfOpportunityTableRow: FC<IProps> = ({item, showDetails}) => {
    const {formatMessage} = useIntl()

    return (
        <tr>
            <th>
                <img src={item.image} className='table-img'/>
            </th>
            <td>{item.product_name}</td>
            <td>{item.barcode}</td>
            <td>{item.model}</td>
            <td>{item.po_id}</td>
            <td>{formatMessage({id: item.remove_reason})}</td>
            <td> {item.removed_quantity}</td>
            <td>{item.total_cost} {formatMessage({id: 'JD'})} </td>
            <td>{item.availability_fees} {formatMessage({id: 'JD'})} </td>
            <td>{item.user_name}</td>
            <td>{item.date_added}</td>
            <td>{item.availability_date}</td>
        </tr>
    )
}

export default LossOfOpportunityTableRow
