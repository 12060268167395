import {useState} from 'react'
import SubmitButtonRowDeleteSpecial from './components/SubmitButtonRowDeleteSpecial'
import {REMOVE_BULK_SPECIAL_TEMPLATE} from './core/_requests'
import SharedData from '../../common/SharedData'
import BulkUploadField from '../add-bulk/components/BulkUploadField'

const RemoveDiscountBulkCard = () => {
  const {currentUser} = SharedData()

  const [bulkFile, setBulkFile] = useState<any>({
    value: null,
    error: false
  })

  return (
      <>
        <div className='card'>
          <div className='card-body'>
            <>
              <div className='d-flex flex-column gap-9'>
                {/* Bulk sheet */}
                <BulkUploadField
                    hasError={bulkFile.error}
                    id='file'
                    setField={setBulkFile}
                    isRequired={true}
                    templateUrl={REMOVE_BULK_SPECIAL_TEMPLATE}
                    token={currentUser?.token}
                    user={currentUser}
                />

                <SubmitButtonRowDeleteSpecial
                    bulkFile={bulkFile}
                    setBulkFile={setBulkFile}
                />
              </div>
            </>
          </div>
        </div>
      </>
  )
}

export default RemoveDiscountBulkCard
