import {FC, useState} from 'react'
import {DAILY_DEAL_ACTIONS, IDailyDealModel} from '../cors/_models'
import {useIntl} from "react-intl";

type IProps = {
    product: IDailyDealModel,
    onAction: (action: DAILY_DEAL_ACTIONS, supplierDealId: number) => Promise<any>
}

const DailyDealTableRow: FC<IProps> = ({product, onAction}) => {

    const {formatMessage} = useIntl()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleAction = async (action: DAILY_DEAL_ACTIONS) => {

        setIsLoading(true)
        await onAction(action, product.supplier_deal_id)
        setIsLoading(false)
    }

    return (
        <tr>
            <th><img src={product.image} className='table-img'/></th>
            <td>{product.name}</td>
            <td>{product.barcode}</td>
            <td>{product.model}</td>
            <td className='text-nowrap'>{product.quantity}</td>
            <td>{product.new_price}</td>
            <td>{product.new_cost} {product.compensation_note && ` - ${product.compensation_note}`} </td>
            <td>{product.discount_percentage} %</td>
            <td>{product.discount_date}</td>
            <td>
                <button
                    className='btn btn-danger p-2 px-5 align-self-baseline'
                    onClick={() => handleAction(DAILY_DEAL_ACTIONS.REMOVE_DEAL_PRODUCT)}>
                    {formatMessage({id: 'remove'})}
                </button>
            </td>
        </tr>
    )
}

export default DailyDealTableRow



