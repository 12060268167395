import {useState} from 'react'
import BulkUploadField from './components/BulkUploadField'
import {PRODUCTS_UPDATE_BULK_TEMPLATE} from './core/_requests'
import SharedData from '../../common/SharedData'
import WeightLengthFields from './components/WeightLengthFields'
import EditSubmitButtonRow from './components/EditSubmitButtonRow'

const EditBulkCard = () => {
  const {currentUser} = SharedData()
  const [bulkFile, setBulkFile] = useState<any>({
    value: null,
    error: false,
  })

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <>
            <div className='d-flex flex-column gap-9'>
              {/* Bulk sheet */}
              <BulkUploadField
                hasError={bulkFile.error}
                id='sheet'
                isRequired={true}
                templateUrl={PRODUCTS_UPDATE_BULK_TEMPLATE}
                isDownloadable={true}
                token={currentUser?.token}
                setField={setBulkFile}
                user={currentUser}
              />


              <EditSubmitButtonRow
                bulkFile={bulkFile}
                setBulkFile={setBulkFile}
              />
            </div>
          </>
        </div>
      </div>
    </>
  )
}

export default EditBulkCard
