import {FC} from 'react'
import {ISpecialProductModel} from '../../products/add-special/core/_models'
type IProps = {
  item: ISpecialProductModel
}

const AddDiscountReqTableRow: FC<IProps> = ({item}) => {
  return (
    <tr>
      <th className='w-100px'>
        <img src={item.image} className='table-img' />
      </th>
      <td className='w-200px'>{item.name}</td>
      <td>{item.barcode}</td>
      <td className='text-nowrap'>{item.model}</td>
      <td className='text-nowrap'>{item.data.quantity}</td>
      <td className='text-nowrap'>{item.data.cost_price.new_cost_price}</td>
      <td className='text-nowrap'>{item.data.sale_price.new_sale_price}</td>
      <td className='text-nowrap'>{item.manufacturer_name}</td>
      <td className='text-nowrap'>{new Date(item.date_added).toISOString().slice(0,10)}</td>
      <td className='text-nowrap'>{item.special_status}</td>
    </tr>
  )
}

export default AddDiscountReqTableRow
