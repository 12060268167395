import React, {FC} from 'react'

type IProps = {
    title: string,
    url: string
}

const OrangeButton: FC<IProps> = ({title, url}) => {

    return (
        <div className='card-header border-0 pt-5 mb-7 justify-content-between align-items-center d-flex flex-column-p'>
            <a href={url}>
                <button className='p-2 px-5 align-self-baseline orange-button'>
                    {title}
                </button>
            </a>

        </div>
    )
}

export default OrangeButton
