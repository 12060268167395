/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, useEffect, useState} from 'react'
import {IReportSalesModel, ITotalSalesRow} from './core/_models'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import {useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import SharedData from '../common/SharedData'
import SalesReportTable from './sales/SalesReportTable'
import SalesReportFilters from './sales/SalesReportFilters'
import {prepareFilters} from '../orders/core/_requests'
import {getSalesReports} from './core/_requests'

type Props = {
    className?: string
}

const SalesReports: React.FC<Props> = ({className}) => {
    const {formatMessage} = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();

    const {currentUser, language} = SharedData()
    const [dataList, setDataList] = useState<IReportSalesModel[]>([])
    const [salesTotal, setSalesTotal] = useState<ITotalSalesRow | null>(null);

    const [isLoading, setIsLoading] = useState(true)
    const [canFilter, setCanFilter] = useState(true)

    useEffect(() => {
        prepareFilters(searchParams, setSearchParams);

        const getDate = async () => {
            try {
                setIsLoading(true);
                const queryParams = document.location.search;
                const res = await getSalesReports(queryParams, currentUser?.token, language);
                const data  = res.data.data;
                setDataList(data.sales_products);
                setSalesTotal(data.total_sales);
            } catch (exp: any) {
                console.log(exp);
            }
            setIsLoading(false);
        };
        getDate();
    }, [searchParams]);

    const renderResults = (): ReactNode => {
        if (isLoading) {
            return <Loader/>;
        }

        if (dataList.length > 0) {
            return (
                <div className='card-body py-3'>
                    <SalesReportTable data={dataList} salesTotal={salesTotal}/>
                </div>
            );
        }

        return <NormalMessage msg={formatMessage({id: "no_data"})}/>;
    }

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
            </div>
            {canFilter && <SalesReportFilters/>}
            {renderResults()}
        </div>
    );
}

export {SalesReports};
