import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {UserModel} from '../../auth'
import {getInTransitReports, getSalesData} from '../core/_requests'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

type Props = {
    className: string
    language: string
    currentUser: UserModel | undefined
}

const SalesChart: React.FC<Props> = ({language, currentUser, className}) => {
    const {formatMessage} = useIntl()
    const chartRef = useRef<HTMLDivElement | null>(null)

    const [inTransitTotal, setInTransitTotal] = useState({
        total_quantity: '',
        total_cost_price: '',
    });

    const [chart, setChart] = useState<ApexCharts | null>(null)

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const {data} = await getSalesData(currentUser?.token, language)
                if (data) {
                    const salesData = [data.data.current_month_sales, data.data.last_month_sales]
                    const newChart = refreshChart(salesData)
                    setChart(newChart)
                }
            } catch (error) {
                console.error('Error fetching sales data:', error)
            }
        }

        fetchSalesData()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef])

    useEffect(() => {
        const getData = async () => {
            const data = await getInTransitReports(currentUser?.token, language)
            if (data) {
                const total = data.data.total_in_transit;
                setInTransitTotal({
                    total_quantity: total.total_quantity,
                    total_cost_price: total.total_cost_price
                })
            }
        }
        getData()
    }, [currentUser, language])

    const refreshChart = (salesData: number[]): ApexCharts | null => {
        if (!chartRef.current) {
            return null
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        const options = getChartOptions(height, salesData, {
            revenue: formatMessage({id: 'DASHBOARD.SALES.revenue'}),
            this_month: formatMessage({id: 'DASHBOARD.SALES.THIS_MONTH'}),
            last_month: formatMessage({id: 'DASHBOARD.SALES.LAST_MONTH'}),
        })

        const newChart = new ApexCharts(chartRef.current, options)
        newChart.render()

        return newChart
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const dateEnd = today.toISOString().split('T')[0];
    const dateStart = yesterday.toISOString().split('T')[0];

    return (
        <>
            <div className="container"
                 style={{display: 'flex', flexDirection: 'column', gap: '20px', flexWrap: 'wrap'}}>
                {/* In Transit Report Card */}
                <div className={`card ${className}`} style={{backgroundColor: "#9658F1", flex: '1', minWidth: '300px'}}>
                    <div className='h-100 d-flex flex-column justify-content-between'>
                        <div className='card-header border-0 pt-2'>
                            <h3 className='card-title align-items-start flex-column'>
                            <span className='fw-semibold fs-2hx mb-1' style={{color: "#FFFFFF"}}>
                                {inTransitTotal.total_cost_price} {formatMessage({id: "jod"})}
                            </span>
                                <div>
                                <span className='text-gray-100'>
                                    {formatMessage({id: "total_cost_price_form"})}
                                </span>
                                    <span className='text-gray-100 fw-semibold' style={{fontSize: '16px'}}>
                                    {inTransitTotal.total_quantity}
                                </span>
                                    <span className='text-gray-100'>
                                    {formatMessage({id: "total_transit_product_quantities"})}
                                </span>
                                </div>
                            </h3>
                        </div>
                        <Link
                            to={`/sales/in-transit?filter_date_start=${dateStart}&filter_date_end=${dateEnd}`}
                            className='card-header fs-4 border-0 d-flex justify-content-end align-items-center text-gray-100 fw-bold'>
                            {formatMessage({id: "view_in_transit_products_list"})}
                        </Link>
                    </div>
                </div>
                <div className={`card ${className}`}>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>
                            {formatMessage({id: "DASHBOARD.SALES.NAME"})}
                        </span>
                            <span className='text-muted fw-semibold fs-7'>
                            {formatMessage({id: "DASHBOARD.SALES.DESC"})}
                        </span>
                        </h3>
                    </div>
                    <div className='card-body'>
                        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '500px'}}/>
                    </div>
                </div>
            </div>
        </>
    );

}

export default SalesChart

function getChartOptions(height: number, salesData: number[], t: any): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-primary')
    const secondaryColor = getCSSVariableValue('--kt-gray-300')

    return {
        series: [
            {
                name: t.revenue,
                data: salesData,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,

        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: [t.this_month, t.last_month],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',

                },
            },
        },
        yaxis: {
            opposite: true,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {

            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        colors: [baseColor, secondaryColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
