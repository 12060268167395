import {FC, useState} from 'react'
import {ISpecialProductModel} from '../core/_models'
import { useIntl } from 'react-intl'

type IProps = {
  item: ISpecialProductModel
  onRemove: any
}
const PendingSpecialTableRow: FC<IProps> = ({item, onRemove}) => {
  const {formatMessage} = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const removeProduct = async () => {
    setIsLoading(true)
    await onRemove(item.supplier_product_special_id);
    setIsLoading(false)
  }

  return (
    <tr>
      <th>
        <img width='100' height='100' src={item.image} />
      </th>
      <th className='w-300px'>{item.name}</th>
      <th>{item.barcode}</th>
      <th>{item.model}</th>
      <th>{item.data.quantity}</th>
      <th>{item.data.cost_price.new_cost_price}</th>
      <th>{item.data.sale_price.new_sale_price}</th>
      {/* <th>{item.data.compensation_details.compensation}</th> */}
      <th>
        <div className='d-flex justify-content-end'>
        <button disabled={isLoading} className='btn btn-secondary' onClick={removeProduct}>
          {isLoading ? (
            <span className='align-middle spinner-grow text-danger'></span>
          ) : (
            formatMessage({id: 'remove'})
          )}
        </button>
        </div>
      </th>
    </tr>
  )
}

export default PendingSpecialTableRow
