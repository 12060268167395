import React, {useEffect, useReducer, useState} from 'react'
import PageHeader from '../common/PageHeader'
import {Tab, Tabs} from 'react-bootstrap'
import ProductGeneralTab from './add-product/ProductGeneralTab'
import ProductDataTab from './add-product/ProductDataTab'
import ProductPriceTab from './add-product/ProductPriceTab'
import {fieldReducer, productActionType, productInitialState, productState} from './cors/reducers'
import SharedData from '../common/SharedData'
import {addProduct, getProduct} from './cors/_requests'
import Loader from '../common/Loader'
import AlertMessage from '../common/alert/AlertMsg'
import {useNavigate, useParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import ProductDiscountTab from "./add-product/ProductDiscountTab";
// import * as aa from  '../../../global'
const AddProduct = () => {
  const {formatMessage} = useIntl()
  const {currentUser, language} = SharedData()
  const {productID} = useParams()
  const navigate = useNavigate();
  const [showDiscountTab, setShowDiscountTab] = useState(false)

  const [errorMsg, setErrorMsg] = useState({
    title: '',
    message: '',
  })
  const [key, setKey] = useState('general')
  const [state, dispatch] = useReducer(fieldReducer, productInitialState)


  const toggleRenderLoadFlag = () => {
    dispatch({type: productActionType.isLoading})
    dispatch({type: productActionType.isRendering})   
  }

  useEffect(() => {
    
    const getData = async () => {
      toggleRenderLoadFlag()
      const data = await getProduct(productID || '', language, currentUser?.token)
      if (data) {
        dispatch({
          type: productActionType.fillProductData,
          payload: {id: '', value: '', ...data.data, product_specials: data.data.product_specials },
        })
      } else {
        // to do => if product not exist any more
      }
      if (data) {
        setShowDiscountTab(true)
      }
      toggleRenderLoadFlag()
    }
    if (productID) getData()
  }, [])

  const isFormHasErrors = (): boolean => {
    dispatch({type: productActionType.clearErrors, payload: {id: '', value: ''}})
    let atLeastOneError = false
    const notValidateKeys = ['aboutEn','aboutAr','productDescriptionEn','productDescriptionAr', 'expiryDate']

    Object.keys(state).forEach((item) => {
      const key = state[item as keyof typeof state]
      if (typeof key === 'object' && key.hasOwnProperty('error')) {
        if (!notValidateKeys.includes(item) &&
            (key.value === '' || typeof key.value === "undefined")
            || (item == 'image' && typeof key.value == 'string' && key.value.includes('logos/img-placeholder.png'))) {
          console.log(`Field ${item} is missing or invalid. Current value:`, key.value);
          dispatch({type: productActionType.fieldRequired, payload: {id: item, value: true}})
          atLeastOneError = true
        }
      }
    })
    
    return atLeastOneError
  }

  const saveProduct = async () => {
    const showAlert = (title: string, message: string) => {
      setErrorMsg({
        title,
        message,
      })
      dispatch({type: productActionType.hasError, payload: {value: true}})
    }

    try {
      if (!isFormHasErrors()) {  
        dispatch({type: productActionType.isLoading})
        const resp:any = await addProduct(language, currentUser?.token, state,productID);
        
        if (!resp || resp.errors) {
          if (resp.errors) {
            const payload = {errors:resp.errors}
            dispatch({type: productActionType.validationErrors,payload});
            showAlert(formatMessage({id: 'something_wrong_title_add_prod'}),formatMessage({id: 'something_wrong_desc_add_prod'}))
            return false;
          } else {
            throw new Error('')
          }
        } else {
          localStorage.setItem('thankYouPageURL', window.location.href);
          navigate(`/thank-you?go=request-${productID ? 'update' : 'add'}-products&message=${encodeURIComponent(resp.data.message)}`);
        }
        
        dispatch({type: productActionType.isLoading})
        
      } else {
        showAlert(formatMessage({id: 'requird_fields_missing'}), formatMessage({id: 'please_fill_all_fields'}))
      }
    } catch {
      showAlert(formatMessage({id: 'something_wrong_title'}), formatMessage({id: 'something_wrong_desc'}))
    }
  }

  return (
    <div className='position-relative add-product-page'>
      <div className='d-flex align-items-center'>
        <PageHeader title={formatMessage({id: 'add_single_prod'})} />
        <a href="https://youtu.be/N8myUxdxzAQ?feature=shared" target="_blank" rel="noopener noreferrer" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '4px'}}>
          {formatMessage({ id: "video_tutorial" })}
        </a>
      </div>
      <div className='mt-7 d-flex justify-content-end align-items-center gap-2 submit-box-product-page'>
        <p className='mb-0'>{formatMessage({id: 'add_prod_standard'})}</p>
        <button
          onClick={saveProduct}
          className='btn btn-sm fs-4 py-1 px-9 btn-light  btn-close-white'
        >
          {formatMessage({id: 'submit'})}
        </button>
      </div>
    {((!state.isRendering && productID) || !productID) && (
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
        className='border-transparent flex-nowrap fs-5 fw-bolder nav nav-line-tabs nav-line-tabs-2x nav-stretch mb-8 px-0'
      >
        <Tab tabClassName='text-active-primary' eventKey='general' title={formatMessage({id: 'general'})}>
          <ProductGeneralTab prodState={state} prodDispatch={dispatch} />
        </Tab>
        <Tab tabClassName='text-active-primary' eventKey='data' title={formatMessage({id: 'data'})}>
          <ProductDataTab prodState={state} prodDispatch={dispatch} />
        </Tab>
        <Tab tabClassName='text-active-primary' eventKey='price' title={formatMessage({id: 'price_quantity'})}>
          <ProductPriceTab prodState={state} prodDispatch={dispatch} />
        </Tab>
        {showDiscountTab && (
            <Tab tabClassName='text-active-primary' eventKey='discounts' title={formatMessage({id: 'add_discounts_label'})}>
              <ProductDiscountTab prodState={state} prodDispatch={dispatch} />
            </Tab>
        )}
      </Tabs>
    )}

      

      {state.hasError === true && (
        <AlertMessage
          title={errorMsg.title}
          message={errorMsg.message}
          onClose={() => dispatch({type: productActionType.hasError})}
        />
      )}
      {state.isLoading && <Loader isFull={true} />}
    </div>
  )
}

export default AddProduct
