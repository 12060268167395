import React, {FC, useState} from 'react'
import {getBulkTemplate} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import fileDownload from 'js-file-download'
import { useIntl } from 'react-intl'
import { UserModel } from '../../../auth'

type IProps = {
  id: string
  label?: string
  onChange?: any
  value?: any
  errorMsg?: string
  hasError?: boolean
  isRequired: boolean
  templateUrl?: string
  token?: string
  isDownloadable?: boolean
  setField: React.Dispatch<any>
  user:UserModel | undefined
}

const BulkUploadField: FC<IProps> = ({
  id,
  label,
  onChange,
  value,
  errorMsg,
  hasError,
  isRequired,
  templateUrl,
  setField,
  isDownloadable,
  token = '',
  user
}) => {
  const {formatMessage} = useIntl();
  const defaultFileName = formatMessage({ id: "upload_from_computer" })
  const [fileName, setFileName] = useState(defaultFileName)
  const getTemplate = async () => {
    const data: any = await getBulkTemplate(templateUrl || '', token)
    fileDownload(data.data, `${user?.name}_${new Date().toISOString().slice(0,10)}.xlsx`)
  }

  const handleChange = (e: any) => {
    const {files} = e.currentTarget
    if (files && files.length > 0) {
      setFileName(files[0].name)
      setField((prev: any) => ({
        error: false,
        value: files[0],
      }))
    } else {
      setField((prev: any) => ({
        error: true,
        value: '',
      }))
      setFileName(defaultFileName)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <label className='form-label fw-bold fs-5'>
            {label ? label : formatMessage({ id: "bulk_sheet" })}{' '}
            {isRequired && <sup className='fw-bold text-danger'>*</sup>}
          </label>
          <div className='upload-from-box border mb-1 px-3 py-3 rounded-2 position-relative'>
            <p className='mb-0 d-flex justify-content-between fw-bold text-gray-600'>
              {fileName} <img width='17' src={toAbsoluteUrl('/media/logos/upload.svg')} />
            </p>
            <input
              type='file'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              id={id}
              onChange={handleChange}
              className='position-absolute w-100 h-100 opacity-0 top-0 left-0 cursor-pointer'
            />
          </div>
          {hasError && (
            <label className='form-label fw-bold text-danger'>
              {errorMsg ? errorMsg : formatMessage({ id: "please_upload_bulk" })}
            </label>
          )}
        </div>
        {templateUrl && (
          <div className='col-md-6'>

            <label className='text-gray-500 fw-bold fs-6 mt-md-12'>
              {formatMessage({ id: "download_bulk_template" })}{' '}
              {isDownloadable ? (
                <a href={templateUrl} download={`${user?.name}_${new Date().toISOString().slice(0,10)}`} className='text-primary btn p-0 fw-bold'>
                  {formatMessage({ id: "from_here" })}
                </a>
              ) : (
                <button className='text-primary btn p-0 fw-bold' onClick={getTemplate}>
                  {formatMessage({ id: "from_here" })}
                </button>
              )}
            </label>
            <div>
              <span className='fw-bold fs-6 mt-md-12'
                    style={{ color: 'orange', margin: '10'}}>
                    {formatMessage({id: 'new_bulk_template'})}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BulkUploadField
