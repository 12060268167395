import {FC} from 'react'
import {IPOInfoModel} from '../../core/_models'

type IProps = {
  item: IPOInfoModel
}
const ReturnTableRow: FC<IProps> = ({item}) => {
  return (
    <tr>
      <th>
        <img width='100' height='100' src={item.image} />
      </th>
      <td>{item.barcode}</td>
      <td>{item.model}</td>
      <td className='w-250px'>{item.name}</td>
      <td>{item.received_quantity}</td>
      <td>{item.cost}</td>
      <td>{item.price}</td>
      <td>{item.total_cost.toFixed(2)}</td>
    </tr>
  )
}

export default ReturnTableRow
