import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useSearchParams, useNavigate } from 'react-router-dom'

const ThankyouPage = () => {
  const {formatMessage} = useIntl()
  const [searchParams, setSearchParams] = useSearchParams();
  const [goTo, setGoTo] = useState<string>('/request-add-products');
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  var savedURL = localStorage.getItem('thankYouPageURL');
  useEffect(()=>{
      const msgForAutoApproved = searchParams.get('message');
      setMessage(msgForAutoApproved);
      if (savedURL) {
          setGoTo(savedURL);
          localStorage.removeItem('thankYouPageURL');
      }
  }, []);

  if (goTo.includes('discount') || goTo.includes('bulk-update')  || goTo.includes('daily-deal') || message === "Auto approved") {
      return (
          <>
              <div className='d-flex align-center justify-center pt-20 mt-20 text-center'>
                  <div className='w-100 mx-auto px-5'>
                      <h1 className='fw-bold fs-3hx mb-10'>{formatMessage({id: 'auto_app'})}</h1>
                      <div className='d-flex justify-content-center'>
                          <a className='btn btn-primary' href={goTo}>{formatMessage({id: 'continue'})}</a>
                      </div>
                  </div>
              </div>
          </>
      )
  } else {
      return (
          <>
              <div className='d-flex align-center justify-center pt-20 mt-20 text-center'>
                  <div className='w-100 mx-auto px-5'>
                      <h1 className='fw-bold fs-3hx mb-10'>{formatMessage({id: 'your_req_sent'})}</h1>
                      <p className='fs-2 mb-14'>{formatMessage({id: 'reviewed_by_manager'})}
                          <a href={goTo}>{formatMessage({id: 'requests_page'})}</a>
                      </p>
                      <div className='d-flex justify-content-center'>
                          <a className='btn btn-primary' href={goTo}>{formatMessage({id: 'continue'})}</a>
                      </div>
                  </div>
              </div>
          </>
      )
    }
}

export default ThankyouPage