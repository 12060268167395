import React, {FC, useState} from 'react'
import CustomModal from '../../../common/CustomModal'
import {Button} from 'react-bootstrap'
import InputField from '../../components/InputField'
import {addProductsDiscount} from '../core/_requests'
import {UserModel} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {prepareErrors} from '../../../errors/core/_models'

type IProps = {
  onCloseModal: () => void
  language: string
  currentUser: UserModel | undefined
  products_id: number[]
}

const SpecialSelectionModal: FC<IProps> = ({onCloseModal, language, currentUser, products_id}) => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const currentDate = new Date().toISOString().split('T')[0];
  const [form, setForm] = useState({
    date_start: {
      error: false,
      value: '',
      msg: '',
    },
    date_end: {
      error: false,
      value: '',
      msg: '',
    },
    quantity: {
      error: false,
      value: '',
      msg: ""
    },
    sale_discount_percentage: {
      error: false,
      value: '',
      msg: ""
    },
    // cost_discount_percentage: {
    //   error: false,
    //   value: '',
    // },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    setForm({...form, [id]: {value: value}})
  }

  const isFormHasError = () => {
    let hasError = false
    setIsLoading(true)

    setForm((prev: any) => ({
      ...prev,
      date_start: {...prev.date_start, error: false, msg: ''},
      date_end: {...prev.date_end, error: false, msg: ''},
      quantity: {...prev.quantity, error: false},
      sale_discount_percentage: {...prev.sale_discount_percentage, error: false},
      // cost_discount_percentage: {...prev.cost_discount_percentage, error: false},
    }))

    if (form.date_start.value === '') {
      setForm((prev: any) => ({
        ...prev,
        date_start: {...prev.date_start, error: true},
      }))
      hasError = true
    }
    if (form.date_end.value === '') {
      setForm((prev: any) => ({
        ...prev,
        date_end: {...prev.date_end, error: true},
      }))
      hasError = true
    }
    if (form.quantity.value === '') {
      setForm((prev: any) => ({
        ...prev,
        quantity: {...prev.quantity, error: true},
      }))
      hasError = true
    }
    if (form.sale_discount_percentage.value === '') {
      setForm((prev: any) => ({
        ...prev,
        sale_discount_percentage: {...prev.sale_discount_percentage, error: true},
      }))
      hasError = true
    }
    // if (form.cost_discount_percentage.value === '') {
    //   setForm((prev: any) => ({
    //     ...prev,
    //     cost_discount_percentage: {...prev.cost_discount_percentage, error: true},
    //   }))
    //   hasError = true
    // }
    setIsLoading(false)
    return hasError
  }
  const handleSubmit = async () => {
    if (!isFormHasError()) {
      setIsLoading(true)
      try {
        const data = await addProductsDiscount(currentUser?.token, language, {
          products_id: products_id.map((id) => id.toString()),
          date_start: form.date_start.value,
          date_end: form.date_end.value,
          quantity: +form.quantity.value,
          // cost_discount_percentage: +form.cost_discount_percentage.value,
          sale_discount_percentage: +form.sale_discount_percentage.value,
        })

        if (data) {
          navigate('/discount/pending-special-products?from=/discount/add-selection', {
            replace: true,
          })
        }
        setIsLoading(false)
      } catch (exp: any) {
        setIsLoading(false)
        const updatedState: any = prepareErrors(exp, form)
        if (updatedState) setForm(updatedState)
      }
    } else {
    }
  }

  return (
    <CustomModal
      show={true}
      title={formatMessage({id: 'add_discount_selected_prods'})}
      onCloseModal={onCloseModal}
      eventSlot={
        <Button disabled={isLoading} onClick={handleSubmit}>
          {formatMessage({id: 'apply'})}
        </Button>
      }
    >
      <form className=''>
        <div className='form-group d-flex flex-column gap-4'>
          <div>
            <InputField
              type='date'
              id='date_start'
              value={form.date_start.value}
              isRequired={true}
              hasError={form.date_start.error}
              onChange={handleChange}
              label={formatMessage({id: 'date_start'})}
              min={currentDate}
              errorMsg={form.date_start.msg || formatMessage({id: 'date_start_required'})}
            />
          </div>
          <div>
            <InputField
              type='date'
              id='date_end'
              value={form.date_end.value}
              isRequired={true}
              hasError={form.date_end.error}
              onChange={handleChange}
              label={formatMessage({id: 'date_end'})}
              errorMsg={form.date_end.msg || formatMessage({id: 'date_end_required'})}
            />
          </div>
          <div>
            <InputField
              type='number'
              id='quantity'
              value={form.quantity.value}
              isRequired={true}
              hasError={form.quantity.error}
              onChange={handleChange}
              label={formatMessage({id: 'quantity'})}
              min='0'
              errorMsg={form.quantity.msg || formatMessage({id: 'SPECIAL.special_product_quantity_required'})}
            />
          </div>
          <div>
            <InputField
              type='number'
              id='sale_discount_percentage'
              value={form.sale_discount_percentage.value}
              isRequired={true}
              hasError={form.sale_discount_percentage.error}
              onChange={handleChange}
              label={formatMessage({id: 'sale_discount_percentage'})}
              min='0'
              errorMsg={form.sale_discount_percentage.msg || formatMessage({id: 'SPECIAL.sale_discount_percentage_required'})}
            />
          </div>
        </div>
      </form>
    </CustomModal>
  )
}

export default SpecialSelectionModal
