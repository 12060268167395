import { FC, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
// const colourOptions = [
//   { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
//   { value: 'blue', label: 'Blue', color: '#0052CC'},
//   { value: 'purple', label: 'Purple', color: '#5243AA' },
//   { value: 'red', label: 'Red', color: '#FF5630' },
//   { value: 'orange', label: 'Orange', color: '#FF8B00' },
//   { value: 'yellow', label: 'Yellow', color: '#FFC400' },
//   { value: 'green', label: 'Green', color: '#36B37E' },
//   { value: 'forest', label: 'Forest', color: '#00875A' },
//   { value: 'slate', label: 'Slate', color: '#253858' },
//   { value: 'silver', label: 'Silver', color: '#666666' },
// ]

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 1 && 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
type IProps = {
    data: {value:string,label:string,color:string}[],
    color: string,
    onChange: (value: string) => void
}
const ColorsDDL:FC<IProps> = ({data,color,onChange}) => {
    const handleChange = (e:any) =>{
        onChange(e.value)
    }
  return (
    <Select
        // defaultValue={colourOptions[2]}
        onChange={handleChange}
        options={data}
        value={{
          value: color,
          label: data.find((item: any) => {
            if (item.value === color) return item.label
          })?.label || '',
        }}
        styles={colourStyles}
    />
  )
}

export default ColorsDDL