import {FC} from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PO } from '../../orders/core/_models'

type IProps = {
  item: PO
}

const PendingOrdersCardRow: FC<IProps> = ({item}) => {
  const {formatMessage} = useIntl()
  return (
    <tr className='border-bottom border-dashed border-gray-400'>
      <td className='text-gray-600 pb-1'>{item.supplier_order_id}</td>
      <td className='text-gray-600 pb-1'>{item.quantity}</td>
      <td className='text-gray-600 pb-1'>{item.total_cost}</td>
      <td className='text-gray-600 pb-1'>
        <Link to={`/orders/purchase-order/${item.supplier_order_id}`} className='btn btn-secondary px-3 py-2 fs-7 min-w-70px'>{formatMessage({ id: "view" })}</Link>
      </td>
    </tr>
  )
}

export default PendingOrdersCardRow
