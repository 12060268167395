import  {FC, useState} from 'react'
import SpecialSelectionModal from './SpecialSelectionModal'
import SharedData from '../../../common/SharedData'
import { useIntl } from 'react-intl'
type IProps = {
  products_id: number[]
}

const SpecialSelectionSubmit: FC<IProps> = ({products_id}) => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <div className='mt-3 mb-5 d-flex justify-content-end align-items-center gap-2'>
        <p className='mb-0'>
          <span>{products_id.length}</span> {formatMessage({ id: "selected_prods" })}
        </p>
        <button
          disabled={products_id.length === 0}
          className='btn btn-sm fs-4 py-1 px-9 btn-light  btn-close-white'
          onClick={toggleModal}
        >
          {formatMessage({ id: "add_discount" })}
        </button>
      </div>
      {isModalOpen && (
        <SpecialSelectionModal
          language={language}
          currentUser={currentUser}
          products_id={products_id}
          onCloseModal={toggleModal}
        />
      )}
    </>
  )
}

export default SpecialSelectionSubmit
