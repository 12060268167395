import {FC, useRef, useState} from 'react'
import CustomModal from '../../../common/CustomModal'
import {Button} from 'react-bootstrap'
import {sendPartiallyAvailableOrder, sendSuggestCost} from '../../core/_requests'
import {useParams} from 'react-router-dom'
import {AlertMsgModel} from '../../../common/alert/AlertMsg'
import SharedData from '../../../common/SharedData'
import {useIntl} from 'react-intl'

interface IProps {
  status: any
  modalParam: any
  flushModals: (alertMessage?: AlertMsgModel) => void
}
const PurchasePartiallyModal: FC<IProps> = ({status, modalParam, flushModals}) => {
  const {formatMessage} = useIntl()
  const {currentUser, language} = SharedData()
  const {orderID} = useParams()

  const inputRef = useRef<HTMLInputElement>(null)
  const [localState, setLocalState] = useState({
    error: modalParam.errorLabel,
  })
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const alertMsgHandle = (isSuccess: boolean) => {
    let alert = {
      title: formatMessage({id: 'success_general_title'}),
      message: formatMessage({id: 'success_general_desc'}),
      status: true,
    }
    if (!isSuccess) {
      alert = {
        title: formatMessage({id: 'something_wrong_title'}),
        message: formatMessage({id: 'something_wrong_desc'}),
        status: false,
      }
    }
    flushModals(alert)
  }

  const onPartiallyAvailable = async () => {
    setIsValid(true)

    if (inputRef.current?.value === '') {
      setIsValid(false)
      return
    }
    const params = {
      [modalParam.key]: inputRef.current?.value,
      reason_id: modalParam.id,
      item_id: modalParam.value,
      supplier_order_id: orderID,
    }

    try {
      setIsSubmitting(true)
      let data
      data = await sendPartiallyAvailableOrder(language, params, currentUser?.token)
      if (!data) {
        setIsSubmitting(false)
        setIsValid(false)
        return false
      }
      

      alertMsgHandle(true)
      setIsSubmitting(false)
    } catch (err: any) {
      if (err.response.data.message) {
        setLocalState({...localState, error: err.response.data.message});
        setIsSubmitting(false)
        return false;
      } else if (err?.response?.data?.error) {
        // modalParam
        setLocalState({...localState, error: err?.response?.data?.error?.availability_date});
        setIsSubmitting(false)
        return false;
      }
      alertMsgHandle(false)
    }
  }

  // handle OUT OF STOCK
  if (modalParam.key === 'out_of_stock' || modalParam.key === 'ready') {
    const sendOutOfStock = async () => {
      const params = {
        [modalParam.key]: inputRef.current?.value,
        reason_id: modalParam.id,
        item_id: modalParam.value,
        supplier_order_id: orderID,
      }

      try {
        await sendPartiallyAvailableOrder(language, params, currentUser?.token)
        alertMsgHandle(true)
      } catch (exp) {
        alertMsgHandle(false)
      }
    }
    sendOutOfStock()
    return <></>
  }

  return (
    <>
      {/* Modal :: partially_available */}
      <CustomModal
        show={status !== null}
        title={modalParam.label}
        onCloseModal={flushModals}
        eventSlot={
          <Button disabled={isSubmitting} onClick={onPartiallyAvailable}>
            {formatMessage({id: 'submit'})}
          </Button>
        }
      >
        <form className=''>
          <div className='form-group'>
            <label>{modalParam.label}</label>
            <div>
              <input
                type={modalParam.type}
                id='available_qty'
                ref={inputRef}
                required
                className='form-control no-arrow'
                onWheel={(e) => e.currentTarget.blur()}
              />
              {(!isValid || localState.error !== modalParam.errorLabel) && (
                <div className='fv-plugins-message-container'>
                  <span role='alert' className='text-danger'>
                    {localState.error}
                  </span>
                </div>
              )}
              {modalParam.description && <p>{modalParam.description}</p>}
            </div>
          </div>
        </form>
      </CustomModal>
      {/* end Modal :: partially_available */}
    </>
  )
}

export default PurchasePartiallyModal
