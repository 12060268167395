import {ReactNode, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {SpecialSelectionFilters} from './SpecialSelectionFilters'
import SharedData from '../../../common/SharedData'
import {IProductModel} from '../../cors/_models'
import {getProductsList} from '../../cors/_requests'
import Loader from '../../../common/Loader'
import Paginator from '../../../common/Paginator'
import NormalMessage from '../../../common/NormalMessage'
import SpecialSelectionTable from './SpecialSelectionTable'
import {prepareFilters} from '../../../orders/core/_requests'
import { useCheckPendingBulk } from '../core/useCheckPendingBulk'
import { useIntl } from 'react-intl'

const SpecialSelection = () => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser, language} = SharedData()
  const [requestList, setRequestList] = useState<IProductModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [canFilter, setCanFilter] = useState(true)
  const [totalResult, setTotalResult] = useState<number>(0)
  const [errorMsg, setErrorMsg] = useState('')

  // if user already has an uploaded bulk but not complete the process
  useCheckPendingBulk()

  useEffect(() => {
    getDate()
  }, [searchParams])

  const getDate = async () => {
    setIsLoading(true)
    try {
      prepareFilters(searchParams, setSearchParams)
      let queryParams = document.location.search
      if (queryParams === '') {
        queryParams += '?filter_status=1'
      } else {
        queryParams += '&filter_status=1'
      }
      const data = await getProductsList(queryParams, currentUser?.token, language)
      if (data) {
        setRequestList(data.data.data.products)
        setTotalResult(data.data.data.total_products)
      } else {
        setTotalResult(0)
        setRequestList([])
        setErrorMsg(formatMessage({ id: "no_data" }))
      }
    } catch (exp) {
      setErrorMsg(formatMessage({ id: "something_wrong_desc" }))
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    return (
      <>
        <div className='card-body py-3'>
          <SpecialSelectionTable data={requestList} />
          <Paginator totalResult={totalResult} />
        </div>

        {isLoading && <Loader isFull={true} />}
        {!isLoading && requestList.length === 0 && errorMsg !== '' && (
          <NormalMessage msg={errorMsg} />
        )}
      </>
    )
  }

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'></span>
          </h3>
          {/* begin::Menu */}
          <div className='card-toolbar'>
            <button
              type='button'
              className={`${
                canFilter && 'active'
              } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
              onClick={() => setCanFilter(!canFilter)}
            >
              {formatMessage({ id: "filter" })} <i className='fas fa-filter'></i>
            </button>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {canFilter && ( // to do
          <SpecialSelectionFilters />
        )}

        {resultsData()}
      </div>
    </>
  )
}

export default SpecialSelection
