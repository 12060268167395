import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import LossOfOpportunityTableRow from "../../modules/loss-of-opportunity/LossOfOpportunityTableRow";
import {ILossOfOpportunityModel} from "../products/cors/_models";

interface IProps {
    data: ILossOfOpportunityModel[],
    refreshData: () => Promise<void>
}

const LossOfOpportunityTable: FC<IProps> = ({data, refreshData}) => {

    const {formatMessage} = useIntl()

    const [modalParam, setModalParam] = useState<{ show: boolean, data: ILossOfOpportunityModel | null }>({
        show: false,
        data: null
    });

    const toggleModal = (params?: ILossOfOpportunityModel) => {

        setModalParam((prev) => ({
            data: params ? params : prev.data,
            show: !modalParam.show
        }))
    }

    return (
        <>
                <table className='table align-middle gs-0 gy-5'>
                    <thead>
                    <tr>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'image'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'prod_name'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'barcode'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'model_num'})}
                        </th>

                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'po_id'})}
                        </th>
                        <th className='fw-bold p-0 text-uppercase'>
                            {formatMessage({id: 'remove_reason'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>
                            {formatMessage({id: 'removed_quantity'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>
                            {formatMessage({id: 'total_cost'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>
                            {formatMessage({id: 'availability_fees'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>
                            {formatMessage({id: 'user_name'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'removed_date'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'availability_date'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && (
                        data.map((item) => (
                            <LossOfOpportunityTableRow showDetails={toggleModal} item={item}/>
                        ))
                    )}
                    </tbody>
                </table>
        </>
    )
}

export default LossOfOpportunityTable
