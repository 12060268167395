import {FC, useState} from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import JSZip from 'jszip'

type IProps = {
  id: string
  label?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: File | string | null;
  errorMsg?: string
  hasError?: boolean
  isRequired: boolean
  hint?: string
  setField: React.Dispatch<React.SetStateAction<{ error: boolean; value: File | string | null }>>;
}

const BulkImageField: FC<IProps> = ({
  id,
  label,
  onChange,
  value,
  errorMsg,
  hint,
  hasError,
  isRequired,
  setField
}) => {
  const {formatMessage} = useIntl();
  const defaultFileName = formatMessage({ id: "upload_from_computer" })
  const [fileName, setFileName] = useState<string>(defaultFileName)
  const [hasImageError, setHasImageError] = useState<boolean>(false);
  const [hasFileSizeError, setHasFileSizeError] = useState<boolean>(false);
  const [hasFileNameError, setHasFileNameError] = useState<boolean>(false);
  const [fileSizeErrorFiles, setFileSizeErrorFiles] = useState<string[]>([]);
  const [imageDimensionErrorFiles, setImageDimensionErrorFiles] = useState<string[]>([]);
  const [fileNameErrorFiles, setFileNameErrorFiles] = useState<string[]>([]);

  const HintLabel: FC = () => {
    const { formatMessage } = useIntl();
    return (
        <span dangerouslySetInnerHTML={{ __html: formatMessage({ id: "upload_image_hint" }) }}></span>
    )
  }
  const ImageSizeHint: FC = () => {
    const { formatMessage } = useIntl();
    return (
      <span dangerouslySetInnerHTML={{ __html: formatMessage({ id: "image_size_hint" }) }}></span>
    );
  };

  const FileSizeHint: FC = () => {
    const { formatMessage } = useIntl();
    return (
      <span dangerouslySetInnerHTML={{ __html: formatMessage({ id: "file_size_hint" }) }}></span>
    );
  };


  const handleChange = async(e: React.ChangeEvent<HTMLInputElement>) => {
    
    const {files} = e.currentTarget
    if (files && files.length > 0) {

      const file = files[0];
      const maxSizeInBytes = 100 * 1024 * 1024;
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

      setFileSizeErrorFiles([]);
      setImageDimensionErrorFiles([]);
      setFileNameErrorFiles([]);
      if (file.size > maxSizeInBytes) {
        setFileSizeErrorFiles(prev => [...prev, file.name]);
        setHasFileSizeError(true);
      } else {
        setHasFileSizeError(false);
      }
      if (fileExtension === '.zip') {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);
        const imageValidationPromises = Object.keys(zipContent.files).map(async (filePath) => {
          const zipEntry = zipContent.files[filePath];
          if (!zipEntry.dir) {
            const fileContent = await zipEntry.async('blob');
            const img = new Image();
            const baseFileName = filePath.split('/').pop() || '';
            const fileNamePattern = /^[a-zA-Z0-9]+-\d{2}\.(jpeg|jpg|png|gif|webp)$/i;
            if (!fileNamePattern.test(baseFileName)) {
              setFileNameErrorFiles(prev => [...prev, baseFileName]);
              setHasFileNameError(true);
            }
            return new Promise<void>((resolve) => {
              img.onload = () => {
                if (img.width < 800 || img.height < 800) {
                  setImageDimensionErrorFiles(prev => [...prev, baseFileName]);
                  setHasImageError(true);
                }
                resolve();
              };
              img.onerror = () => resolve();
              img.src = URL.createObjectURL(fileContent);
            });
          }
        })
        await Promise.all(imageValidationPromises);
        if (fileSizeErrorFiles.length > 0 || imageDimensionErrorFiles.length > 0 || fileNameErrorFiles.length > 0) {
          setField({ error: true, value: '' });
          setFileName(defaultFileName);
        } else {
          setField({ error: false, value: file });
          setFileName(file.name);
        }
      } else {
        setFileSizeErrorFiles(prev => [...prev, file.name]);
        setField({ error: true, value: '' });
        setFileName(defaultFileName);
      }

    } else {
      setField({ error: true, value: '' });
      setFileName(defaultFileName);
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <label className='form-label fw-bold fs-5'>
            {label ? label : formatMessage({ id: "images" })}
            {isRequired && <sup className='fw-bold text-danger'>*</sup>}
          </label>
          <div className='upload-from-box border mb-1 px-3 py-3 rounded-2 position-relative'>
            <p className='mb-0 d-flex justify-content-between fw-bold text-gray-600'>
            {fileName}
              <img width='17'
                   src={toAbsoluteUrl('/media/logos/upload.svg')}
              />
            </p>
            <input
              type='file'
              accept=".zip"
              id={id}
              onChange={handleChange}
              className='position-absolute w-100 h-100 opacity-0 top-0 left-0 cursor-pointer'
            />
          </div>
          {!hasError && !hasFileSizeError && !hasFileNameError && !hasImageError &&(
            <div>
              <label className="text-gray-500 fw-bold fs-7" style={{display: 'block'}}>
                {hint ? hint : <HintLabel />}
              </label>
              <br />
              <label className="text-gray-500 fw-bold fs-7" style={{display: 'block'}}>
                <ImageSizeHint />
              </label>
              <br />
              <label className="text-gray-500 fw-bold fs-7" style={{display: 'block'}}>
                <FileSizeHint />
              </label>
            </div>
          )}
          {hasError && !hasImageError && !hasFileSizeError && !hasFileNameError &&(
            <label className="form-label fw-bold text-danger">
              {errorMsg ? errorMsg : formatMessage({id: "images_required"})}
            </label>
          )}
          {hasImageError &&  imageDimensionErrorFiles.length > 0 &&(
            <label className='form-label fw-bold text-danger'>
              {formatMessage({id: "invalid_image_dimensions"}, {files: imageDimensionErrorFiles.join(', ')})}
            </label>
          )}
          {hasFileSizeError && fileSizeErrorFiles.length > 0 && (
            <label className='form-label fw-bold text-danger'>
              {formatMessage({id: "file_size_exceeded"}, {files: fileSizeErrorFiles.join(', ')})}
            </label>
            )}
          {hasFileNameError && fileNameErrorFiles.length > 0 && (
            <label className='form-label fw-bold text-danger'>
              {formatMessage({id: "invalid_file_name_format"}, {files: fileNameErrorFiles.join(', ')})}
            </label>
          )}
        </div>
      </div>
    </>
  )
}

export default BulkImageField
