import { FC } from "react"
import { useIntl } from "react-intl"

type IProps = {
  fee_first_time:any
  fee_first_time_type:any
  data_fee:any
  SKU_listin_fee:any
}

const ContractRangeTable:FC<IProps> = ({fee_first_time,fee_first_time_type,data_fee,SKU_listin_fee}) => {
  const {formatMessage} = useIntl();

  return (
    <div className='col-sm-12'>
      <label className='text-gray-600 fw-bold fs-5 d-block mb-5'>{formatMessage({ id: "rng_dte_mgmnt_lbl" })}</label>
      
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5 border-top mt-2'>
          {/* begin::Table body */}
          <tbody>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600 mw-150px'>{formatMessage({ id: "netry_fees_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{fee_first_time}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{formatMessage({ id: "payment_method" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{fee_first_time_type}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600 mw-150px'>{formatMessage({ id: "data_fee_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{data_fee}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{formatMessage({ id: "sku_fee_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{SKU_listin_fee}</th>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {/* end::Table Container */}
    </div>
  )
}

export default ContractRangeTable
