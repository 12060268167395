import React, {FC} from 'react'
import Pagination from 'react-bootstrap/Pagination'
import { useSearchParams } from 'react-router-dom';

interface IProps {
  setCurrentPage?: any;
  currentPage?: number
  totalResult: number
  perPage?: number
}


const Paginator: FC<IProps> = ({currentPage = 1, totalResult, perPage,setCurrentPage}) => {
  const [searchParams,setSearchParams] = useSearchParams()
  let resultPerPage = perPage || 20
  let totalPages = Math.ceil(totalResult / resultPerPage)
  const currentPageTemp = +(searchParams.get('current_page') || 1);
  let pagination = [], i = 1;
  const handleChange = (e:React.ChangeEvent<any>):void => {
    const page = +(e.target.getAttribute('data-index') || 1) 
    if (page !== currentPageTemp) {
      searchParams.set('current_page',page.toString())
      setSearchParams(searchParams)
    }
  }

  while (i <= totalPages) {
    if (i <= 3 || //the first three pages
        i >= totalPages - 2 || //the last three pages
        i >= currentPageTemp - 1 && i <= currentPageTemp + 1) { //the currentPage, the page before and after
      pagination.push(
        <Pagination.Item data-index={i} onClick={event=>handleChange(event)} key={i} active={i === currentPageTemp}>
          {i}
        </Pagination.Item>
        // <Link href={`?page=${i}`} key={i}> 
        //   <a>{i}</a> 
        // </Link>
      );
      i++;
    } else { //any other page should be represented by ...
      pagination.push(<div key="...">...</div>);
      //jump to the next page to be linked in the navigation
      i = i < currentPageTemp ? currentPageTemp - 1 : totalPages - 2;
    }
  }

  // for (let number = 1; number <= totalPages; number++) {
  //   items.push(
  //     <Pagination.Item onClick={()=>handleChange(number)} key={number} active={number === currentPage}>
  //       {number}
  //     </Pagination.Item>
  //   )
  // }
  
  if (pagination.length === 0 || pagination.length === 1) {
    // return null
  } 
  return (
    <div>
      <Pagination>{pagination}</Pagination>
    </div>
  )
}

export default Paginator
