import { FC } from "react"
import { IProfileContractModel } from "../../core/_models"
import { useIntl } from "react-intl";

const ContractStockTable:FC<IProfileContractModel> = ({days_stock_in_warehouse,delivery_duration_name,delivery_responsibility,logistics_fees,percentage_fulfilment,percentage_penalty,non_return_discount}) => {
  const {formatMessage} = useIntl();
  
  return (
    <div className='col-sm-12'>
      <label className='text-gray-600 fw-bold fs-5 d-block mb-5'>{formatMessage({ id: "stock_delivery_lbl" })}</label>
      
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5 border-top mt-2'>
          {/* begin::Table body */}
          <tbody>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "says_stock_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "days_stock_cover_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{days_stock_in_warehouse}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "lead_time_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "bsns_days_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{delivery_duration_name}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "warehouse_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'></th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{delivery_responsibility}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "logistic_fee_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "percentage_discount_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{logistics_fees}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "fufilment_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "perc_fufilment_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{percentage_fulfilment}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "loss_profit_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "perc_pentaly_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{percentage_penalty}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 w-50 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "no_returns_lbl" })}</th>
              <th colSpan={2} className='fs-5 fw-bold px-sm-4 text-center text-gray-600 text-nowrap'>{formatMessage({ id: "percentage_discount_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{non_return_discount}</th>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {/* end::Table Container */}
    </div>
  )
}

export default ContractStockTable
