import { FC } from 'react'
import { useIntl } from 'react-intl';
import {NavLink} from 'react-router-dom'

const MyProfileNav:FC<{pageTitle:string}> = ({pageTitle}) => {
  const {formatMessage} = useIntl();
  return (
    <>
      <div className='card-header border-0 pt-5 mb-7'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{pageTitle}</span>
        </h3>
      </div>
      <div className={`card mb-10`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <NavLink className='nav-link text-active-primary me-6' to='/myprofile'>
                  {formatMessage({ id: "basic_info" })}
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-active-primary me-6' to='/contract-info'>
                  {formatMessage({ id: "contract_info" })}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Header */}
      </div>
    </>
  )
}

export default MyProfileNav
