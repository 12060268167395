import React, { FC, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl';

interface IProps {
  title: string;
  show: boolean;
  children?: React.ReactNode; 
  onCloseModal?:any;
  eventSlot?:any
}

const CustomModal:FC<IProps> = ({title,children,onCloseModal,eventSlot,show}) => {
  const {formatMessage} = useIntl();
  const handleClose = () => {
    if (onCloseModal){
      onCloseModal()
    }
  };

  return (
    <>
      
      <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {formatMessage({ id: "close" })}
          </Button>
          {eventSlot}
        </Modal.Footer>
      </Modal>
    </div>
    </>
  )
}

export default CustomModal
