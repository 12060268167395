import { FC } from 'react'
import { PurchaseOrder } from '../../modules/orders/PurchaseOrder'


const PurchaceOrder:FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
        <PurchaseOrder />
      </div>
      {/* end::Col */}
    </div>
    {/* begin::Row */}
  </>
)

const PurchaceOrderPage:FC = () => {
  return (
    <>
      <PurchaceOrder />
    </>
  )
}

export default PurchaceOrderPage