import { FC } from 'react'
import UpdateBulk from '../../modules/products/UpdateBulk'
import { useCheckPendingBulk } from '../../modules/products/add-special/core/useCheckPendingBulk'

const UpdateProductBulkPage:FC = () => {
  // if user already has an uploaded bulk but not complete the process
  useCheckPendingBulk()
  return (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
            <UpdateBulk />
        </div>
        {/* end::Col */}
        </div>
        {/* begin::Row */}
    </>
  )
}

export default UpdateProductBulkPage