import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import PuchaceReturnOrders from '../pages/orders/Orders'
import PurchaceOrderPage from '../pages/orders/PurchaceOrder'
import MyProfilePage from '../pages/my-profile/MyProfilePage'
import ContractInfoPage from '../pages/my-profile/ContractInfoPage'
import AddProductPage from '../pages/products/AddProductPage'
import AddProductBulkPage from '../pages/products/AddProductBulkPage'
import ProductsListPage from '../pages/products/ProductsListPage'
import ThankyouPage from '../pages/thank-you/ThankyouPage'
import SuggestedCostReqPage from '../pages/requests/SuggestedCostReqPage'
import AddProductReqPage from '../pages/requests/AddProductReqPage'
import UpdateProductBulkPage from '../pages/products/UpdateProductBulkPage'
import DuplicateBarcodesPage from '../pages/products/DuplicateBarcodesPage'
import UpdateProductReqPage from '../pages/requests/UpdateProductReqPage'
import AddSpecialBulkPage from '../pages/products/special/AddSpecialBulkPage'
import RemoveDiscountBulkPage from '../pages/products/special/RemoveDiscountBulkPage'
import SpecialSelectionPage from '../pages/products/special/SpecialSelectionPage'
import PendingSpecialPage from '../pages/products/special/PendingSpecialPage'
import AddDiscountReq from '../modules/requests/add-discount/AddDiscountReq'
import ConnectedBarcodesPage from '../pages/products/ConnectedBarcodesPage'
import EditProductPage from '../pages/products/EditProductPage'
import MyDashboardPage from '../pages/dashboard/MyDashboardPage'
import ReturnOrderPage from '../pages/orders/ReturnOrderPage'
import GRNInvoicesPage from '../pages/orders/GRNInvoicesPage'
import SalesReportsPage from '../pages/reports/SalesReportsPage'
import TransactionReportsPage from '../pages/reports/TransactionReportsPage'
import StatementReportsPage from '../pages/reports/StatementReportsPage'

import DailyDealListPage from "../pages/daily-deals/DailyDealListPage";
import AppliedDailyDealListPage from "../pages/daily-deals/AppliedDailyDealListPage";
import LossOfOpportunityPage from "../pages/loss-of-opportunity/LossOfOpportunityPage";
import {InTransitReports} from "../modules/reports/InTransitReports";

const PrivateRoutes = () => {
  
  return (
    <Routes>
      <Route path="thank-you" element={<ThankyouPage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<MyDashboardPage />} />
        {/* po order */}
        <Route path='orders' element={<PuchaceReturnOrders />} />
        <Route path='orders/purchase-order/:orderID' element={<PurchaceOrderPage />} />
        <Route path='orders/return-order/:orderID' element={<ReturnOrderPage />} />
        {/* reports */}
        <Route path='sales/loss-of-opportunity' element={<LossOfOpportunityPage />} />
        <Route path='sales/sales-report' element={<SalesReportsPage />} />
        <Route path='sales/in-transit' element={<InTransitReports />} />
        <Route path='payment/transaction-report' element={<TransactionReportsPage />} />
        <Route path='payment/statement-report' element={<StatementReportsPage />} />
        {/* supplier profile */}
        <Route path='myprofile' element={<MyProfilePage />} />
        <Route path='contract-info' element={<ContractInfoPage />} />

        {/* products */}
        <Route path='products/list' element={<ProductsListPage />} />
        <Route path='products/custom/list' element={<ProductsListPage />} />
        <Route path='grn-invoices/:orderID' element={<GRNInvoicesPage />} />
        <Route path='products/product-add' element={<AddProductPage />} />
        <Route path='products/product-edit/:productID' element={<EditProductPage />} />
        <Route path='products/bulk-add' element={<AddProductBulkPage />} />
        <Route path='products/bulk-update' element={<UpdateProductBulkPage />} />
        <Route path='products/duplicate-barcodes' element={<DuplicateBarcodesPage />} />
        <Route path='discount/add-discount-bulk' element={<ConnectedBarcodesPage />} />

        {/* discounts */}
        <Route path='discount/add-using-bulk' element={<AddSpecialBulkPage />} />
        <Route path='discount/add-selection' element={<SpecialSelectionPage />} />
        <Route path='discount/pending-special-products' element={<PendingSpecialPage />} />
        <Route path='discount/remove-discount-bulk' element={<RemoveDiscountBulkPage />} />
        {/* requests */}
        <Route path='request-suggested-cost' element={<SuggestedCostReqPage />} />
        <Route path='request-add-products' element={<AddProductReqPage />} />
        <Route path='request-update-products' element={<UpdateProductReqPage />} />
        <Route path='request-add-discount' element={<AddDiscountReq />} />

        {/* daily deal */}
        <Route path='daily-deal/list' element={<DailyDealListPage />} />
        <Route path='applied-daily-deal/list' element={<AppliedDailyDealListPage />} />

        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
