import {FC, useEffect, useState} from 'react'
import InputField from '../components/InputField'
import {IProductProps, productActionType} from '../cors/reducers'
import {
  getBrands,
  getColors,
  getGenders,
  getLengthClasses,
  getRecommendedAges,
  getWeightClasses,
} from '../cors/_requests'
import SharedData from '../../common/SharedData'
import {
  IBrandModel,
  IColorsModel,
  IGenderModel,
  ILengthClassesModel,
  IRecommendedAgeModel,
  ISelectOptions,
  IWeightClassesModel,
} from '../cors/_models'
import ColorsDDL from '../components/ColorsDDL'
import DDLField from '../components/DDLField'
import {useIntl} from 'react-intl'

const ProductDataTab: FC<IProductProps> = ({prodState, prodDispatch}) => {
  const {formatMessage} = useIntl()
  const {currentUser, language} = SharedData()
  // start DDL data
  const [lengthClasses, setLengthClasses] = useState<ISelectOptions[]>([])
  const [weightClasses, setWeightClasses] = useState<ISelectOptions[]>([])
  const [recommendedAges, setRecommendedAges] = useState<ISelectOptions[]>([])
  const [gender, setGender] = useState<ISelectOptions[]>([])
  const [colors, setColors] = useState<any[]>([])
  // end DDL data

  const [brand, setBrand] = useState<ISelectOptions[]>([])
  const currentDate = new Date().toISOString().split('T')[0];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    prodDispatch({type: productActionType.text, payload: {id, value}})
  }

  useEffect(() => {
    const getLengthClass = async () => {
      const {data} = await getLengthClasses(currentUser?.token, language)
      const convertData = data.map((item: ILengthClassesModel) => {
        return {label: item.name, value: item.length_class_id}
      })

      setLengthClasses(convertData)
    }
    const getWeightClass = async () => {
      const {data} = await getWeightClasses(currentUser?.token, language)

      const convertData = data.map((item: IWeightClassesModel) => {
        return {label: item.name, value: item.weight_class_id}
      })

      setWeightClasses(convertData)
    }
    const getColorsData = async () => {
      const {data} = await getColors(currentUser?.token, language)

      const convertData = data.map((item: IColorsModel) => {
        return {label: item.name, value: item.color_id, color: `#${item.color_id}`}
      })

      setColors(convertData)
    }
    const getRecommendedAgesData = async () => {
      const {data} = await getRecommendedAges(currentUser?.token, language)

      const convertData = data.map((item: IRecommendedAgeModel) => {
        return {label: item.name, value: item.recommended_age_id}
      })

      setRecommendedAges(convertData)
    }

    const getGenderData = async () => {
      const {data} = await getGenders(currentUser?.token, language)

      const convertData = data.map((item: IGenderModel) => {
        return {label: item.name, value: item.gender_id}
      })

      setGender(convertData)
    }
    const getBrandsData = async () => {
      const {data} = await getBrands(currentUser?.token, language)
      const convertData = data.map((item: IBrandModel) => {
        return {label: item.name, value: item.manufacturer_id}
      })

      setBrand([{label: formatMessage({id: 'no_brand'}), value: '-1'}, ...convertData])
    }
    getLengthClass()
    getWeightClass()
    getColorsData()
    getRecommendedAgesData()
    getGenderData()
    getBrandsData()
  }, [])
  const handleColorChange = (value: string) => {
    prodDispatch({type: productActionType.text, payload: {id: 'color', value}})
  }

  return (
    <div className='card default-image-box'>
      <div className='card-body'>
        <>
          <div className='d-flex flex-column gap-9'>
            {/* brandName */}
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>
                  {formatMessage({ id: "brand_manu" })}
                  <sup className='text-danger'>*</sup>
                </label>
                <DDLField
                  id='manufacturer_id'
                  options={brand}
                  onChange={handleChange}
                  isRequired={true}
                  hasError={prodState.manufacturer_id.error}
                  value={prodState.manufacturer_id.value}
                />
              </div>
            </div>
            {/* Dimension (L x W x H) */}
            <div className='w-100'>
              <label className='form-label fw-bold fs-3'>{formatMessage({ id: "prod_dimensions" })}</label>
              <div className='row gap-1 gap-md-0'>
                <div className='col-md-3 d-flex'>
                  <div className='row gap-1 gap-md-0'>
                    <div className='col-sm-4'>
                      <InputField
                        id='dimensionL'
                        value={prodState.dimensionL}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-sm-4'>
                      <InputField
                        id='dimensionW'
                        value={prodState.dimensionW}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-sm-4'>
                      <InputField
                        id='dimensionH'
                        value={prodState.dimensionH}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <InputField
                    type='select'
                    id='lengthClass'
                    value={prodState.lengthClass}
                    onChange={handleChange}
                    options={[{label: formatMessage({ id: "length_class" }), value: ''}, ...lengthClasses]}
                  />
                </div>
              </div>
            </div>

            {/* Weight  */}
            <div className='w-100'>
              <label className='form-label fw-bold fs-3'>{formatMessage({ id: "weight" })}</label>
              <div className='row gap-1 gap-md-0'>
                <div className='col-md-3 d-flex'>
                  <InputField id='weight' value={prodState.weight} onChange={handleChange} />
                </div>
                <div className='col-md-3'>
                  <InputField
                    type='select'
                    id='weightClass'
                    value={prodState.weightClass}
                    onChange={handleChange}
                    options={[{label: formatMessage({ id: "weight_class" }), value: ''}, ...weightClasses]}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>{formatMessage({ id: "color" })}</label>
                <ColorsDDL
                data={colors}
                onChange={handleColorChange}
                color={prodState.color} />

              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>{formatMessage({ id: "gender" })}</label>
                <InputField
                  id='gender'
                  type='select'
                  value={prodState.gender}
                  onChange={handleChange}
                  options={[{label: formatMessage({ id: "select_gender" }), value: ''}, ...gender]}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>
                  {formatMessage({ id: "recommended_age" })}<sup className='text-danger'>*</sup>
                </label>
                <InputField
                  hasError={prodState.recommendedAge.error}
                  value={prodState.recommendedAge.value}
                  isRequired={true}
                  id='recommendedAge'
                  type='select'
                  options={[{label: formatMessage({ id: "recommended_age" }), value: ''}, ...recommendedAges]}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>{formatMessage({ id: "youtube_URL" })}</label>
                <InputField id='youtubeUrl' value={prodState.youtubeUrl} onChange={handleChange} />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label fw-bold fs-3'>{formatMessage({ id: "expiry_date" })}</label>
                <InputField
                    type="date"
                    id='expiryDate'
                    value={prodState.expiryDate.value}
                    onChange={handleChange}
                    min={currentDate}
                    isRequired={false}
                    hasError={prodState.expiryDate.error}
                    errorMsg={prodState.expiryDate.msg}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default ProductDataTab
