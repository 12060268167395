import { useIntl } from 'react-intl';
import ContractInfoDetails from './components/contract-info/ContractInfoDetails'
import MyProfileNav from './components/my-profile/MyProfileNav'

const ContractInfo = () => {
  const {formatMessage} = useIntl();
  return (
    <>
      <MyProfileNav pageTitle={formatMessage({ id: "contract_info" })} />
      <ContractInfoDetails />
    </>
  )
}

export default ContractInfo
