import {FC, useState, useEffect } from 'react'
import TextTableFilter from '../../common/TextTableFilter'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import fileDownload from 'js-file-download';
import * as XLSX from 'xlsx';

import {getSalesReports} from "../core/_requests";
import SharedData from "../../common/SharedData";
import {prepareFilters} from "../../orders/core/_requests";
import {IReportSalesModel} from '../core/_models'
export const SalesReportFilters: FC = ({}) => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [startDateFilter, setStartDateFilter] = useState(searchParams.get('filter_date_start') || '')
  const [endDateFilter, setEndDateFilter] = useState(searchParams.get('filter_date_end') || '')
  const {currentUser,language} = SharedData();

  const handleChange = (id: string, value: string, type: string) => {
    if (value) {
      searchParams.set(id, value)
      searchParams.set('current_page', '1')
    } else {
      searchParams.delete(id)
    }
  }

  useEffect(() => {
    filter();
  }, [searchParams]);

  const clearFilters = (): void => {
    setSearchParams({},{
      replace:true
    })
    setStartDateFilter('')
    setEndDateFilter('')
  }

  const filter = (): void => {
    setSearchParams(searchParams,{
      replace:true
    })
  }

  const ExportSalesReport = async (): Promise<void> => {
    try {
      prepareFilters(searchParams, setSearchParams);
      const queryParams = document.location.search;
      const res = await getSalesReports(queryParams, currentUser?.token, language);
      const data = res.data.data;
      const responseData = Object.values(data.sales_products);

      const headers = Object.keys(responseData[0]);
      const excelData = responseData.map(product => headers.map(header => product[header as keyof IReportSalesModel]));

      excelData.push([]);
      excelData.push(["Total Cost Price with Quantity", data.total_sales.total_cost_price]);
      excelData.push(["Total Quantity", data.total_sales.total_quantity]);

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...excelData]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Report');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

      try {
        fileDownload(blob, 'Sales Report.xlsx');
      } catch (error) {
        console.error('Error during file download:', error);
      }
    } catch (error) {
      console.error('Error preparing export data:', error);
    }
  };

  return (
    <>
      <div className='card-body border-bottom pb-10 mb-5'>
        <div className='row'>
          {/* start DATE */}
          <TextTableFilter
            id='filter_date_start'
            label={formatMessage({ id: "from" })}
            value={startDateFilter}
            setValue={setStartDateFilter}
            onChange={handleChange}
            type='date'
          />
          {/* end DATE */}
          
          {/* start DATE */}
          <TextTableFilter
            id='filter_date_end'
            label={formatMessage({ id: "to" })}
            value={endDateFilter}
            setValue={setEndDateFilter}
            onChange={handleChange}
            type='date'
          />
          {/* end DATE */}

          <div className='col-sm-12 col-md-2'>
            <button
                className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                onClick={filter}
            >
              {formatMessage({ id: "filter" })}
            </button>
          </div>

          {/* reset button */}
          <div className='col-sm-12 col-md-2'>
            <button
              type='reset'
              className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
              onClick={clearFilters}
            >
              {formatMessage({ id: "reset" })}
            </button>
          </div>

          <div className='col-sm-12 col-md-2'>
            <button
                className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                onClick={ExportSalesReport}
            >
              {formatMessage({ id: "export" })}
            </button>
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SalesReportFilters
