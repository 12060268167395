import ConnectedBarcodeDetails from './ConnectedBarcodeDetails'
import PageHeader from '../../../common/PageHeader';
import { useIntl } from 'react-intl';

const ConnectedBarcode = () => {
  const {formatMessage} = useIntl();
  return (
    <>
      <PageHeader title={formatMessage({ id: "add_discount_bulk" })} />
      <ConnectedBarcodeDetails />
    </>
  )
}

export default ConnectedBarcode