import { FC } from "react";

interface IProps {
  msg: string;
}
const NormalMessage:FC<IProps> = ({msg}) => {
  return (
    <p className='fs-1 text-center fw-bold'>
      {msg}
    </p>
  )
}

export default NormalMessage