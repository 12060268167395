/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, useEffect, useState} from 'react'
import {ITransactionReportModel, ITotalTransactionRow} from './core/_models'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import {useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import SharedData from '../common/SharedData'
import TransactionReportTable from './transaction/TransactionReportTable'
import TransactionReportFilters from './transaction/TransactionReportFilters'
import {prepareFilters} from '../orders/core/_requests'
import {getTransactionReports} from './core/_requests'

type Props = {
    className?: string
}

const TransactionReports: React.FC<Props> = ({className}) => {
    const {formatMessage} = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();

    const {currentUser, language} = SharedData()
    const [dataList, setDataList] = useState<ITransactionReportModel[]>([])
    const [transactionTotal, setTransactionTotal] = useState<ITotalTransactionRow | null>(null);

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        prepareFilters(searchParams, setSearchParams);

        const getDate = async () => {
            try {
                setIsLoading(true);
                const queryParams = document.location.search;
                const res = await getTransactionReports(queryParams, currentUser?.token, language);
                const data = res.data.data;
                 setDataList(data.transaction_products);
                 setTransactionTotal(data.total_transaction);
            } catch (exp: any) {
                console.log(exp);
            }
            setIsLoading(false);
        };
        getDate();
    }, [searchParams]);

    const renderResults = (): ReactNode => {
        if (isLoading) {
            return <Loader/>;
        }

        if (dataList.length > 0 || transactionTotal !== null) {
            return (
                <div className='card-body py-3'>
                    <TransactionReportTable data={dataList} transactionTotal={transactionTotal}/>
                </div>
            );
        }

        return <NormalMessage msg={formatMessage({id: "no_data"})}/>;
    }

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
            </div>
            <TransactionReportFilters/>
            {renderResults()}
        </div>
    );
}

export {TransactionReports};
