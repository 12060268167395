import React, {ReactNode, useEffect, useState} from 'react'
import SharedData from '../common/SharedData'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import {getDailyDealList} from '../products/cors/_requests'
import {IDailyDealModel} from "../products/cors/_models";
import Loader from "../common/Loader";
import DailyDealTable from "../products/daily-deal/DailyDealTable";

const DailyDealList = () => {

    const {formatMessage} = useIntl();
    const [isLoading, setIsLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const {currentUser, language} = SharedData()
    const [dailyDealList, setDailyDealList] = useState<IDailyDealModel[]>([])

    useEffect(() => {
        getDate()
    }, [searchParams])

    const getDate = async () => {

        setIsLoading(true)

        try {

            const data = await getDailyDealList(currentUser?.token, language)
            if (data.data.products) {
                setDailyDealList(data.data.products)
            } else {
                setDailyDealList([])
            }

        } catch (exp) {

        }

        setIsLoading(false)
    }

    const resultsData = (): ReactNode => {

        return (
            <div className='card-body py-3'>
                {isLoading && <Loader isFull={true}/>}
                <DailyDealTable refreshData={getDate} products={dailyDealList}/>

            </div>
        )
    }

    return (
        <div className={`card`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <div className='d-flex align-items-center'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "prods_list" })}</span>
                    </h3>
                    <a href="https://youtu.be/1QPkQW9E4fA?feature=shared" target="_blank" rel="noopener noreferrer" style={{marginLeft: '10px', marginRight: '10px'}}>
                        {formatMessage({ id: "video_tutorial" })}
                    </a>
                </div>
            </div>
            {/* end::Header */}

            {resultsData()}
        </div>
    )

}

export default DailyDealList
