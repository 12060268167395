import { FC } from 'react'
import { useIntl } from 'react-intl'
import { IStatementReportModel } from '../core/_models'

type IProps = { item: IStatementReportModel }

const StatementReportTableRow: FC<IProps> = ({ item }) => {
    const {formatMessage} = useIntl()
    return (
        <tr>
            <th> {item.date_start} - {item.date_end}</th>
            <td>{parseFloat(item.beginning_balance).toFixed(3)} {formatMessage({id: 'JD'})}</td>
            <td>{parseFloat(item.total_sales).toFixed(3)} {formatMessage({id: 'JD'})}</td>
            <td>{parseFloat(item.total_refund).toFixed(3)} {formatMessage({id: 'JD'})}</td>
            <td>{parseFloat(item.payout_amount).toFixed(3)} {formatMessage({id: 'JD'})}</td>
            <td>{item.user_name}</td>

        </tr>
    )
}

export default StatementReportTableRow
