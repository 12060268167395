import { useIntl } from 'react-intl';
import PageHeader from '../../common/PageHeader'
import DuplicateBarcodeDetails from './DuplicateBarcodeDetails'

const DuplicateBarcode = () => {
  const {formatMessage} = useIntl();
  
  return (
    <>
      <PageHeader title={formatMessage({ id: "update_prod_bulk" })} />
      <DuplicateBarcodeDetails />
    </>
  )
}

export default DuplicateBarcode