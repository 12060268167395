import {ICities, ILocations, IProfileContractModel, IProfileInfoModel} from './_models'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const GET_SUPPLIER_INFO = `${API_URL}/api/partner-portal/supplier`
export const UPDATE_SUPPLIER_INFO = `${API_URL}/api/partner-portal/supplier`
export const GET_SUPPLIER_CONTRACT_INFO = `${API_URL}/api/partner-portal/supplier/contract-info`
export const GET_ALL_CITIES = `${API_URL}/api/partner-portal/supplier/cities?country_id=108`
export const GET_ALL_LOCATIONS = `${API_URL}/api/partner-portal/supplier/zones`

// GET SUPPLIER INFO
export function getSupplierInfo(token: string | undefined, language: string) {
  return axios.get<{supplier_info: IProfileInfoModel}>(`${GET_SUPPLIER_INFO}`, {
    headers: {token: token || '', language},
  })
}


// Update SUPPLIER INFO
export function updateSupplierInfo(token: string | undefined, language: string, data: any) {
  return axios.put<any>(`${UPDATE_SUPPLIER_INFO}`,data, {
    headers: {token: token || '', 'Accept-Language':language,language},
  })
}

// GET CONTRACT INFO
export function getSupplierContractInfo(token: string | undefined, language: string) {
  return axios.get<{supplier_contract_info:IProfileContractModel }>(`${GET_SUPPLIER_CONTRACT_INFO}`, {
    headers: {token: token || '', language},
  })
}

// GET ALL CITIES
export function getCities(token: string | undefined, language: string) {
  return axios.get<{cities: ICities[]}>(`${GET_ALL_CITIES}`, {
    headers: {token: token || '', language},
  })
}
// GET ALL LOCATIONS
export function getLocations(token: string | undefined, language: string,city:string|number) {
  return axios.get<{zones: ILocations[]}>(`${GET_ALL_LOCATIONS}?city_id=${city}`, {
    headers: {token: token || '', language},
  })
}