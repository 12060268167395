import { FC } from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import { PurchaceReturnOrders } from '../../modules/orders/PurchaseReturnOrders';

const ReturnOrdersPage:FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
        <PurchaceReturnOrders />
      </div>
      {/* end::Col */}
    </div>
    {/* begin::Row */}
  </>
)

const ReturnOrders:FC = () => {
  return (
    <>
      <ReturnOrdersPage />
    </>
  )
}

export default ReturnOrders