import {FC} from 'react'

import PendingSpecialTableRow from './PendingSpecialTableRow'
import {ISpecialProductModel} from '../core/_models'
import {removePendingSpecialProduct} from '../core/_requests'
import {useAuth} from '../../../auth'
import { useIntl } from 'react-intl'

interface IProps {
  products: ISpecialProductModel[]
  refreshData: () => Promise<void>
  // totalComp: number
}
const PendingSpecialTable: FC<IProps> = ({products, refreshData}) => {
  const {formatMessage} = useIntl()
  const {currentUser} = useAuth()

  const handleRemoveRow = async (specialID: number): Promise<any> => {
    await removePendingSpecialProduct(currentUser?.token, specialID)
    refreshData()
  }
  
  return (
    <>
      {/* begin::Table container */}
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          {/* begin::Table head */}
          <thead>
            <tr>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'image'})}</th>
              <th className='fw-bold p-0 text-uppercase'>{formatMessage({id: 'prod_name'})}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'barcode'})}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'model_num'})}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'qty'})}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'cost_price'})}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'sale_price'})}</th>
              {/* <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({id: 'compensation'})}</th> */}
              <th className='fw-bold p-0'></th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {products.length > 0 ? (
              <>
                {products.map((item) => (
                  <PendingSpecialTableRow
                    key={item.supplier_product_special_id}
                    item={item}
                    onRemove={handleRemoveRow}
                  />
                ))}
                {/* {totalComp > 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td colSpan={5}>
                      <p className='mb-0 fw-bold text-black'>{formatMessage({id: 'compensation_total_calculated'})}</p>
                    </td>
                    <td>{totalComp}</td>
                  </tr>
                )} */}
              </>
            ) : (
              <tr>
                <td colSpan={9} className='text-center'>
                {formatMessage({id: 'no_data'})}
                </td>
              </tr>
            )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {/* end::Table container */}
    </>
  )
}

export default PendingSpecialTable
