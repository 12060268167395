import {ReactNode, useEffect, useState} from 'react'
import SharedData from '../../common/SharedData'
import RequestPagesHeader from '../components/RequestPagesHeader'
import Loader from '../../common/Loader'
import NormalMessage from '../../common/NormalMessage'
import {getAddDiscountReq} from '../../products/cors/_requests'
import Paginator from '../../common/Paginator'
import AddProductReqTable from './AddDiscountReqTable'

import {AddDiscountReqFilters} from './AddDiscountReqFilters'
import {ISpecialProductModel} from '../../products/add-special/core/_models'
import {useSearchParams} from 'react-router-dom'
import {prepareFilters} from '../../orders/core/_requests'
import {useIntl} from 'react-intl'
import { DISCOUNT_FILTERS } from '../../products/cors/_models'

const AddDiscountReq = () => {
  const {formatMessage} = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser, language} = SharedData()
  const [requestList, setRequestList] = useState<ISpecialProductModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [canFilter, setCanFilter] = useState(true)
  const [totalResult, setTotalResult] = useState<any>({
    pending:0,
    rejected:0,
  })
  const [errorMsg, setErrorMsg] = useState(formatMessage({id: 'something_wrong_title'}))

  useEffect(() => {
    getDate()
  }, [searchParams])

  const getDate = async () => {
    setIsLoading(true)
    try {
      prepareFilters(searchParams, setSearchParams)
      const queryParams = document.location.search;
      const {data} = await getAddDiscountReq(queryParams, currentUser?.token, language)

      if (data) {
        if (data.special_list.length === 0) {
          setErrorMsg(formatMessage({id: 'no_data'}))
        }
        setRequestList(
          data.special_list.map((item: ISpecialProductModel) => {
            let temp = {...item}
            if (typeof temp.data == 'string') temp.data = JSON.parse(temp.data)
            return {...temp}
          })
        )
          
        setTotalResult({
          pending: data.total_request.total_pending_request,
          rejected: data.total_request.total_rejected_request,
        })
        // if (data.data.message && data.data.message === 'Empty list') {
        //   setRequestList([])
        //   setErrorMsg(formatMessage({id: 'no_data'}))
        // } else {
        //   setRequestList(data.data.data.products)
        //   setTotalResult(data.data.data.total_products)
        // }
      } else {
        setRequestList([])
        setErrorMsg(formatMessage({id: 'no_data'}))
      }
    } catch (exp) {
      setErrorMsg(formatMessage({id: 'something_wrong_title'}))
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (requestList.length > 0) {
      return (
        <div className='card-body py-3'>
          <AddProductReqTable data={requestList} />
          {/* <Paginator totalResult={totalResult} /> */}
        </div>
      )
    }
    return <NormalMessage msg={errorMsg} />
  }

  return (
    <>
      <RequestPagesHeader totalResult={totalResult} statusFilterID={DISCOUNT_FILTERS.STATUS} />
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'></span>
          </h3>
          {/* begin::Menu */}
          <div className='card-toolbar'>
            <button
              type='button'
              className={`${
                canFilter && 'active'
              } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
              onClick={() => setCanFilter(!canFilter)}
            >
              {formatMessage({id: 'filter'})} <i className='fas fa-filter'></i>
            </button>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {canFilter && ( // to do
          <AddDiscountReqFilters />
        )}

        {resultsData()}
      </div>
    </>
  )
}

export default AddDiscountReq
