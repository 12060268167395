import React, {FC} from 'react'
import {PO} from '../../core/_models'
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl'
type IProps = {
  order: PO
}
const PoTableRow: FC<IProps> = ({order}) => {
  const {formatMessage} = useIntl();
  const orderLink = () => {
    console.log(order.type)
    if (order.type === 'PO' || order.type === 'طلب شراء') {
      return `/orders/purchase-order/${order.supplier_order_id}`
    }
    return `/orders/return-order/${order.supplier_order_id}`
  }

  return (
    <tr>
      <th>{order.supplier_order_id}</th>
      {/* <td>{order.invoice_number}</td> */}
      <td>{order.GRN_number && order.GRN_number !== '0' && <Link target='_blank' className='text-decoration-underline' to={`/grn-invoices/${order.supplier_order_id}`}>{order.GRN_number}</Link>}</td>
      <td className=''>{order.date_added.slice(0,10)}<br/>{order.date_added.slice(11,16)}</td>
      <td>{order.type}</td>
      <td>{order.status}</td>
      <td>{order.quantity}</td>
      <td>{order.received_quantity}</td>
      <td>{order.total_cost.toFixed(2)}</td>
      <td className='text-center'>
        <Link className='btn btn-secondary p-2 px-5' to={orderLink()}>
        {formatMessage({ id: "view" })}
        </Link>
      </td>
    </tr>
  )
}

export default PoTableRow
