/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, createRef, useCallback, useEffect, useRef, useState} from 'react'
import SharedData from '../../common/SharedData'
import Loader from '../../common/Loader'
import NormalMessage from '../../common/NormalMessage'
import {getDuplicateBarcodes} from '../add-bulk/core/_requests'
import {IDuplicateBarcodeModel} from '../cors/_models'
import DuplicateBarcodeTable from './DuplicateBarcodeTable'
import { useIntl } from 'react-intl'
import DeleteRequestBtn from '../add-special/components/DeleteRequestBtn'

interface IProps {
  
}
export  interface IDuplicateTableRef {
  submitTable(): void;
}
const DuplicateBarcodeDetails: React.FC<IProps> = ({}) => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const [duplicateList, setDuplicateList] = useState<IDuplicateBarcodeModel[]>([])
  const tableRef = createRef<IDuplicateTableRef>();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getDate = async () => {
      setIsLoading(true)
      const {data} = await getDuplicateBarcodes(currentUser?.token, language)
      if (data) {
        setDuplicateList(data.requests)
      }
      setIsLoading(false)
    }
    getDate()
  }, [])

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (duplicateList.length > 0) {
      return (
        <>
          <div className='py-3'>
            <DuplicateBarcodeTable currentUser={currentUser} ref={tableRef} products={duplicateList} />
          </div>
        </>
      )
    }
    return <NormalMessage msg={formatMessage({ id: "no_data" })} />
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-7 align-items-center'>
        <span className='fw-bold text-gray-700'>
          <i className='text-dark fas fa-exclamation-circle'></i> {formatMessage({ id: "duplicated_prods_hint" })}
        </span>
        <div className='d-flex gap-5'>
          <DeleteRequestBtn isProductBulk={true} />
          <button onClick={()=>tableRef.current?.submitTable()} className='btn btn-dark'>{formatMessage({ id: "send_req" })}</button>
        </div>
      </div>
      <div className={`card`}>
        <div className='card-body'>{resultsData()}</div>
      </div>
    </>
  )
}

export default DuplicateBarcodeDetails
