import {FC} from 'react'
import {IPOInfoModel} from '../../core/_models'
import PurchaseTableRow from './ReturnTableRow'
import ReturnTableRow from './ReturnTableRow'
import { useIntl } from 'react-intl'


interface IProps {
  orders: IPOInfoModel[]
}
const ReturnTable: FC<IProps> = ({orders}) => {
  const {formatMessage} = useIntl();
  

  return (
    <>
      {/* begin::Table container */}
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          {/* begin::Table head */}
          <thead>
            <tr>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "image" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "barcode" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "model_num" })}</th>
              <th className='fw-bold p-0 text-uppercase'>{formatMessage({ id: "prod_name" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "qty" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "cost_price" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "sale_price" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "total_cost" })}</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {orders &&
              orders.map((item) => (
                <ReturnTableRow
                  key={item.item_id}
                  item={item}
                />
              ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {/* end::Table container */}
    </>
  )
}

export default ReturnTable
