import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {FC, useState} from 'react'
import {IProductProps, productActionType} from '../products/cors/reducers'

import he from 'he'
import { useIntl } from 'react-intl'
interface IProps extends IProductProps {
  label: string
  id: string
  hint?: string
  hasError?: boolean
  errorMsg?: string
  value: any
}
declare global {
  var aboutEn: any
}
// console.log(ClassicEditor.builtinPlugins.map( (plugin:any) => plugin.pluginName ));
const CkeditorField: FC<IProps> = ({
  id,
  label,
  hint,
  value,
  prodDispatch,
  hasError,
  errorMsg,
  prodState,
}) => {
  const {formatMessage} = useIntl();
  const [state,setState] = useState<any>(he.decode(value || ''))
  // useEffect(()=>{
  //   setState(value)
  // },[value])
  
  return (
    <div className=''>
      <label className='form-label fw-bold'>{label}</label>
      {hint && <small> {hint}</small>}
      <div>
        <CKEditor
          editor={ClassicEditor}
          config={{ckfinder: {
            // Upload the images to the server using the CKFinder QuickUpload command
            // You have to change this address to your server that has the ckfinder php connector
            uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
          }}}
          data={state}
          onReady={(editor: any) => {}}
          onChange={(event: Event, editor: unknown) => {
            // setState(value)
            // @ts-ignore
            const Evalue = editor.getData();
            prodDispatch({type: productActionType.editor, payload: {id, value: Evalue}})
            
            setState(Evalue)
            //   console.log({event, editor, data})
          }}
          onBlur={(_: Event, editor: unknown) => {
            // console.log('Blur.', editor)
          }}
          onFocus={(_: Event, editor: unknown) => {
            // console.log('Focus.', editor)
          }}
        />
        {hasError && (
          <label className='form-label fw-bold text-danger'>
            {errorMsg ? errorMsg : formatMessage({ id: "required_field" })}
          </label>
        )}
      </div>
    </div>
  )
}

export default CkeditorField
