import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import {getActiveProductsInfo, getDailySales} from '../core/_requests'
import {IActiveProductsBody, IDailySalesDataBody} from '../core/_models'
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl'

type Props = {
  className: string
  language: string
  currentUser: UserModel | undefined
}

const ActiveProductsCard: React.FC<Props> = ({language, currentUser, className}) => {
  const {formatMessage} = useIntl();
  const [state, setState] = useState<IActiveProductsBody>({
    active_products_count: 0,
  })

  useEffect(() => {
    const getData = async () => {
      const {data} = await getActiveProductsInfo(currentUser?.token, language)
      if (data) {
        setState({
          active_products_count: data.data.active_products_count,
        })
      }
    }
    getData()
  }, [])

  return (
    <div className={`card ${className}`} style={{backgroundColor:"#198754"}}>
      <div className='h-100 d-flex flex-column justify-content-between'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-2'>
          {/* begin::Daily sales */}
          <h3 className='card-title align-items-start flex-column'>
            <span className='text-gray-100 card-label fw-bold fs-2hx mb-1'>{state.active_products_count}</span>
            <span className='text-gray-100'>{formatMessage({ id: "active_prods" })}</span>
          </h3>
          {/* end::Daily sales */}
        </div>
        {/* end::Header */}

        <Link
          to='/products/list?filter_status=1'
          className='card-header fs-4 border-0 d-flex justify-content-end align-items-center text-gray-100 fw-bold'
        >
          {formatMessage({ id: "view_prods" })}
        </Link>
      </div>
    </div>
  )
}

export default ActiveProductsCard
