import {FC} from 'react'
import { useIntl } from 'react-intl';
import {NavLink} from 'react-router-dom'

type IProps = {
  tabs: {label: string; link: string}[]
}

const ToggleTabsNav: FC<IProps> = ({tabs}) => {
  const {formatMessage} = useIntl()
  
  return (
    <div className='d-flex overflow-auto h-55px mb-10'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((item) => (
          <li key={item.label} className='nav-item'>
            <NavLink className='nav-link text-active-primary me-6' to={item.link}>
              {formatMessage({id: item.label})}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ToggleTabsNav
