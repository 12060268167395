import {FC, useState} from 'react'
import {IProductModel, PRODUCT_ACTIONS} from '../cors/_models'
import ProductTableRow from './ProductTableRow'
import {productAction, updateProduct} from '../cors/_requests'
import SharedData from '../../common/SharedData'
import AlertMessage from '../../common/alert/AlertMsg'
import {useIntl} from 'react-intl'
import NormalMessage from '../../common/NormalMessage'
import '../../../stylesheet/product-list/ProductsTable.css'

interface IProps {
  products: IProductModel[]
  refreshData: () => Promise<void>
}
const ProductsTable: FC<IProps> = ({products, refreshData}) => {
  const {formatMessage} = useIntl()
  const {currentUser, language} = SharedData()
  const [alert, setAlert] = useState({
    message: '',
    title: '',
    status: false,
  })

  const setMsg = (message: string, title: string, status: boolean = true) => {
    setAlert({
      message,
      title,
      status,
    })
  }

  const changeQuantity = async (value: string, productID: number) => {
    try {
      const data = await updateProduct(
        {
          supplier_quantity: value,
          product_id: productID,
        },
        currentUser?.token,
        language
      )

      setMsg(
        formatMessage({ id: 'success_general_desc' }),
        formatMessage({ id: 'success_general_title' })
      )
      refreshData()
    } catch {
      setMsg(
        formatMessage({ id: 'something_wrong_desc' }),
        formatMessage({ id: 'something_wrong_title' }),
        false
      )
    }
  }

  const doProductAction = async (action: PRODUCT_ACTIONS, productID: number): Promise<any> => {
    try {
      await productAction(
        {
          product_id: productID,
        },
        action,
        currentUser?.token,
        language
      )
      refreshData()
    } catch {
      return
    }
  }

  return (
    <>
      {/* begin::Table container */}
      {products.length > 0 ? (
        <table className='table align-middle gs-0 gy-5'>
          <thead>
          <tr>
            <th className='fw-bold p-0'>{formatMessage({id: 'image'})}</th>
            <th className='fw-bold p-0'>{formatMessage({id: 'prod_name'})}</th>
            <th className='fw-bold p-0'>{formatMessage({id: 'barcode'})}</th>
            <th className='fw-bold p-0'>{formatMessage({id: 'model_num'})}</th>
            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'qty'})}</th>
            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'warehouse_qty'})}</th>
            <th className='fw-bold p-0 px-3 text-nowrap'>
              {formatMessage({id: 'cost_price'})}
            </th>
            <th className='fw-bold p-0 px-3 text-nowrap'>
              {formatMessage({id: 'sale_price'})}
            </th>
            <th className="fw-bold p-0 px-3 text-nowrap">{formatMessage({id: 'brand'})}</th>
            <th className="fw-bold p-0 px-3 text-nowrap">{formatMessage({id: 'status'})}</th>
            <th className="fw-bold p-0 px-3 text-nowrap">{formatMessage({id: 'performance_col'})}</th>
            <th className="fw-bold p-0">{formatMessage({id: 'actions'})}</th>
          </tr>
          </thead>

          <tbody>
          {products &&
            products.map((product) => (
              <ProductTableRow
                onAction={doProductAction}
                onChangeQty={changeQuantity}
                key={product.product_id}
                product={product}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <NormalMessage msg={formatMessage({id: 'no_data'})} />
      )}
      {/* end::Table container */}

      {alert.message && (
        <AlertMessage
          onClose={() => setAlert({title: '', message: '', status: false})}
          message={alert.message}
          title={alert.title}
          status={alert.status}
        />
      )}
    </>
  );
};

export default ProductsTable;
