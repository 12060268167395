import React, {ReactNode, useEffect, useState} from 'react'
import SharedData from '../common/SharedData'
import {ILossOfOpportunityModel} from "../products/cors/_models";
import LossOfOpportunityTable from "../../modules/loss-of-opportunity/LossOfOpportunityTable";
import LossOfOpportunityListFilter from "../../modules/loss-of-opportunity/LossOfOpportunityListFilter";
import {prepareFilters} from '../orders/core/_requests'
import {useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {getLossOfOpportunityProductsList} from "../../modules/products/cors/_requests";
import Paginator from '../common/Paginator'
import Loader from "../common/Loader";

const LossOfOpportunityListPage = () => {
    const {formatMessage} = useIntl();

    const [searchParams, setSearchParams] = useSearchParams()
    const {currentUser, language} = SharedData()

    const [lossOfOpportunityProductList, setLossOfOpportunityProductList] = useState<ILossOfOpportunityModel[]>([])

    const [isLoading, setIsLoading] = useState(true)

    const [canFilter, setCanFilter] = useState(true)
    const [totalResult, setTotalResult] = useState<number>(0)

    useEffect(() => {
        getDate()
    }, [searchParams])

    const getDate = async () => {
        prepareFilters(searchParams, setSearchParams)
        setIsLoading(true)
        const queryParams = document.location.search
        try {

            const queryParams = document.location.search;
            const data = await getLossOfOpportunityProductsList(queryParams, currentUser?.token,language)

            if (data) {
                setLossOfOpportunityProductList(data.data.data.loss_products_list)
                if (data.data.data.total_loss_products)
                    setTotalResult(data.data.data.total_loss_products)
                else
                    setTotalResult(0)
            } else {
                setLossOfOpportunityProductList([])
                setTotalResult(0)
            }

        } catch (exp) {

        }
        setIsLoading(false)
    }

    const resultsData = (): ReactNode => {
        return (
            <div className='card-body py-3'>
                {  isLoading && <Loader isFull={true}/> }
                <LossOfOpportunityTable refreshData={getDate} data={lossOfOpportunityProductList}/>
                { <Paginator totalResult={totalResult}/>  }
            </div>
        )
    }

    return (
        <div className={`card`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({id: "prods_list"})}</span>
                </h3>
                <div className='card-toolbar'>
                    <button
                        type='button'
                        className={`${
                            canFilter && 'active'
                        } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
                        onClick={() => setCanFilter(!canFilter)}
                    >
                        {formatMessage({id: "filter"})} <i className='fas fa-filter'></i>
                    </button>
                </div>
            </div>
            {canFilter && (
                <LossOfOpportunityListFilter/>
            )}
            {resultsData()}
        </div>
    )
}

export default LossOfOpportunityListPage

