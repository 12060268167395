import {FC, forwardRef, useCallback, useImperativeHandle, useState} from 'react'
import {IDuplicateBarcodeModel} from '../cors/_models'
import DuplicateBarcodeTableRow from './DuplicateBarcodeTableRow'
import DuplicateBarcodeTableHead from './DuplicateBarcodeTableHead'
import {IDuplicateTableRef} from './DuplicateBarcodeDetails'
import AlertMessage from '../../common/alert/AlertMsg'
import { fixDuplicateBarcode } from '../add-bulk/core/_requests'
import { UserModel } from '../../auth'
import { useNavigate } from 'react-router-dom'
import Loader from '../../common/Loader'
import { useIntl } from 'react-intl'

interface IProps {
  products: IDuplicateBarcodeModel[],
  currentUser:UserModel | undefined
}
const DuplicateBarcodeTable = forwardRef<IDuplicateTableRef, IProps>(({products,currentUser}, ref) => {
  const {formatMessage} = useIntl();
  
  let prevBarcode: string
  let barcodeState: string
  const navigate = useNavigate()

  const prepareState = () => {
    const tempState: any = {}
    for (let i = 0; i < products.length; i++) {
      const item = products[i]
      if (item.barcode !== barcodeState) {
        barcodeState = item.barcode
        tempState[item.barcode] = {
          error: true,
          keys: [],
        }
      }
    }
    return tempState
  }
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [state, setState] = useState<any>(prepareState);

  const handleRowChange = useCallback((barcode: string, key: number, isChecked: boolean) => {
    const selectedState = state[barcode]
    if (isChecked) {
      selectedState.keys.push(key)
      selectedState.error = false
    } else {
      selectedState.keys = selectedState.keys.filter((k: number) => k !== key)
      selectedState.error = true
    }
    setState({...state, [barcode]: {...selectedState}})
  }, [])
  const submitTable = async () => {
    setIsError(false);
    let checkValidity = true;
    let selectedKeys:string[] = []
    for(let i in state) {
      if (state[i].error === true) {
        checkValidity = false;
        break;
      }
      selectedKeys.push(...state[i].keys)
    }
    if (checkValidity) {
      try {
        setIsLoading(true)
        const data = await fixDuplicateBarcode(selectedKeys,currentUser?.token)
        if (data) {
          localStorage.setItem('thankYouPageURL', window.location.href);
          navigate('/thank-you?go=request-update-products');
        } else {
          setIsError(true);
        }
        setIsLoading(false)
      } catch {
        setIsError(true);
        setIsLoading(false)
      }
      
    } else {
      setIsError(true);
    }
  }
  useImperativeHandle(ref, () => ({submitTable}))

  return (
    <>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          <DuplicateBarcodeTableHead />

          {/* begin::Table body */}
          <tbody>
            {products &&
              products.map((item, index) => {
                if (index === 0) {
                  prevBarcode = item.barcode
                  return (
                    <DuplicateBarcodeTableRow
                      onChange={handleRowChange}
                      key={item.key}
                      item={item}
                    />
                  )
                }
                if (prevBarcode === item.barcode) {
                  return (
                    <DuplicateBarcodeTableRow
                      onChange={handleRowChange}
                      key={item.key}
                      item={item}
                    />
                  )
                } else {
                  prevBarcode = item.barcode
                  return (
                    <DuplicateBarcodeTableRow
                      isNewBarcode={true}
                      onChange={handleRowChange}
                      key={item.key}
                      item={item}
                    />
                  )
                }
                return <></>
              })}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {isLoading && <Loader isFull={true} />}
      {isError && <AlertMessage title={formatMessage({ id: "at_least_select_alert_title" })} message={formatMessage({ id: "at_least_select_alert_desc" })} onClose={()=> setIsError(false)} />}
    </>
  )
})

export default DuplicateBarcodeTable
