import {ReactNode, useEffect, useState} from 'react'
import SharedData from '../../common/SharedData'
import RequestPagesHeader from '../components/RequestPagesHeader'
import Loader from '../../common/Loader'
import NormalMessage from '../../common/NormalMessage'
import {IProductReqModel, PRODUCT_REQUEST_TYPE, PRODUCT_REQ_FILTERS} from '../../products/cors/_models'
import {getProductsListReq} from '../../products/cors/_requests'
import Paginator from '../../common/Paginator'
import AddProductReqTable from './AddProductReqTable'
import {useLocation, useSearchParams} from 'react-router-dom'
import AddProductReqFilters from './AddProductReqFilters'
import {prepareFilters} from '../../orders/core/_requests'
import {useIntl} from 'react-intl'

const AddProductReq = () => {
  const {formatMessage} = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const {pathname} = useLocation()

  const {currentUser, language} = SharedData()
  const [requestList, setRequestList] = useState<IProductReqModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [canFilter, setCanFilter] = useState(true)
  const [totalResult, setTotalResult] = useState<any>({
    pending:0,
    rejected:0,
  })
  const [errorMsg, setErrorMsg] = useState(formatMessage({id: 'something_wrong_title'}))

  useEffect(() => {
    getDate()
  }, [searchParams])

  const getDate = async () => {
    setIsLoading(true)
    try {
      prepareFilters(searchParams, setSearchParams)
      const queryParams = document.location.search
      let requestType = PRODUCT_REQUEST_TYPE.ADD
      if (!pathname.includes('request-add-products')) {
        requestType = PRODUCT_REQUEST_TYPE.EDIT
      }
      const {data} = await getProductsListReq(
        queryParams,
        currentUser?.token,
        language,
        requestType
      )

      if (data) {
        if (data.product_requests.length === 0) {
          setErrorMsg(formatMessage({id: 'no_data'}))
        }
        setRequestList(data.product_requests)
        setTotalResult({
          pending: data.total_requests.total_pending_request,
          rejected: data.total_requests.total_rejected_request,
        })
        
        // setTotalResult(0)
      } else {
        setRequestList([])
        setErrorMsg(formatMessage({id: 'no_data'}))
      }
    } catch (exp) {
      setErrorMsg(formatMessage({id: 'something_wrong_title'}))
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (requestList.length > 0) {
      return (
        <div className='card-body py-3'>
          <AddProductReqTable data={requestList} />
          {/* <Paginator
            setCurrentPage={setResultFilters}
            currentPage={resultFilters.current_page}
            totalResult={totalResult}
          /> */}
        </div>
      )
    }
    return <NormalMessage msg={errorMsg} />
  }

  return (
    <>
      <RequestPagesHeader totalResult={totalResult} statusFilterID={PRODUCT_REQ_FILTERS.STATUS} />
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'></span>
          </h3>
          {/* begin::Menu */}
          <div className='card-toolbar'>
            <button
              type='button'
              className={`${
                canFilter && 'active'
              } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
              onClick={() => setCanFilter(!canFilter)}
            >
              {formatMessage({id: 'filter'})} <i className='fas fa-filter'></i>
            </button>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {canFilter && ( // to do
          <AddProductReqFilters />
        )}

        {resultsData()}
      </div>
    </>
  )
}

export default AddProductReq
