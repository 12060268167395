import {FC} from 'react'
import {IAppliedDailyDealModel} from '../cors/_models'

type IProps = {
    product: IAppliedDailyDealModel,
}

const AppliedDailyDealRow: FC<IProps> = ({product}) => {
    return (
        <tr>
            <th style={{ backgroundColor: product.bg_color}}><img src={product.image} className='table-img'/></th>
            <td style={{ backgroundColor: product.bg_color}}>{product.name}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.barcode}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.quantity}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.price}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.cost_price}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.date_start}</td>
            <td style={{ backgroundColor: product.bg_color}}>{product.date_end}</td>
        </tr>
    )
}

export default AppliedDailyDealRow



