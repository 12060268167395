import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import {getMonthlyServiceInfo} from '../core/_requests'
import {IMonthlyServiceBody} from '../core/_models'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  language: string
  currentUser: UserModel | undefined
}

const MonthlyServiceCard: React.FC<Props> = ({language, currentUser, className}) => {
  const {formatMessage} = useIntl()
  const [state, setState] = useState<any>()

  useEffect(() => {
    const getData = async () => {
      const {data} = await getMonthlyServiceInfo(currentUser?.token, language)
      if (data) {
        setState(data.data.monthly_service_level)
      }
    }
    getData()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Daily sales */}
        <h3 className='card-title text-center w-100 pt-6'>
          <span className='card-label fw-bold fs-2hx mb-1 w-100'>
            {formatMessage({id: 'monthly_service_lvl'})}
          </span>
        </h3>
        {/* end::Daily sales */}
      </div>
      {/* end::Header */}
      <div className='card-body'>
        <p className='card-label fw-bold fs-2hx mb-1 w-100 mt-2 text-center'> {state}%</p>
        <div className='h-10px mx-3 w-100 bg-secondary rounded mt-7'>
          <div
            className='bg-primary rounded h-10px'
            role='progressbar'
            style={{width: `${state}%`}}
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyServiceCard
