import {useEffect, useState} from 'react'
import SharedData from '../../../common/SharedData'
import {getSupplierContractInfo} from '../../core/_requests'
import {IProfileContractModel} from '../../core/_models'
import MyProfileFieldText from '../my-profile/MyProfileFieldText'
import ContractMarginTable from './ContractMarginTable'
import ContractStockTable from './ContractStockTable'
import ContractRangeTable from './ContractRangeTable'
import ContractGrowthTable from './ContractGrowthTable'
import { useIntl } from 'react-intl'

const ContractInfoDetails = () => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const [profileInfo, setProfileInfo] = useState<IProfileContractModel>()
  useEffect(() => {
    const getDate = async () => {
      const {data: data} = await getSupplierContractInfo(currentUser?.token, language)
      const info = data.supplier_contract_info
      setProfileInfo(info)
    }
    getDate()
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-header pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "contract_info" })}</span>
          </h3>
        </div>
        <div className='card-body py-3 pt-10'>
          <div className='row gap-14'>
            <div className='col-sm-12 d-flex flex-column gap-6'>
              {/* Supplier Name */}
              {/* <MyProfileFieldText label={formatMessage({ id: "agreement_type" })} value={profileInfo?.payment_type} /> */}
              <MyProfileFieldText label={formatMessage({ id: "effective_till" })} value={profileInfo?.effective_till} />
            </div>
            {profileInfo && (
              <>
                <ContractMarginTable
                  paymentType={profileInfo?.payment_type}
                  margin={profileInfo?.margin}
                  st1From={profileInfo?.step_1_from}
                  st2From={profileInfo?.step_2_from}
                  st3From={profileInfo?.step_3_from}
                  st1To={profileInfo?.step_1_to}
                  st2To={profileInfo?.step_2_to}
                  st3To={profileInfo?.step_3_to}
                  st1Margin={profileInfo?.step_1_percentage_margin}
                  st2Margin={profileInfo?.step_2_percentage_margin}
                  st3Margin={profileInfo?.step_3_percentage_margin}
                />

                <ContractStockTable {...profileInfo} />
                <ContractRangeTable
                  fee_first_time={profileInfo.fee_first_time}
                  fee_first_time_type={profileInfo.fee_first_time_type}
                  data_fee={profileInfo.data_fee}
                  SKU_listin_fee={profileInfo.SKU_listin_fee}
                />
                <ContractGrowthTable
                  paymentType={profileInfo?.payment_type}
                  dumyah_activity_percentage={profileInfo.dumyah_activity_percentage}
                  subscription_percentage={profileInfo.subscription_percentage}
                  special_event_percentage={profileInfo.special_event_percentage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ContractInfoDetails
