import {FC} from 'react'
import AppliedDailyDealList from "../../modules/daily-deal/AppliedDailyDealList";

const AppliedDailyDealListPage: FC = () => {

    return (
        <>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
                    <AppliedDailyDealList/>
                </div>
            </div>
        </>
    )
}

export default AppliedDailyDealListPage