import {FC} from 'react'
import {useIntl} from 'react-intl'
import {IInTransitReportModel} from '../core/_models'

type IProps = {
    item: IInTransitReportModel,
    showDetails: (params?: IInTransitReportModel) => void
}

const InTransitReportsTableRow: FC<IProps> = ({item, showDetails}) => {
    const {formatMessage} = useIntl()

    return (
        <tr>
            <th>
                <img src={item.image} className='table-img' alt='Product'/>
            </th>
            <td>{item.barcode}</td>
            <td>{item.model}</td>
            <td>{item.name}</td>
            <td>{item.adjusted_quantity}</td>
            <td style={{color: 'green'}}>{item.average_cost_price}{formatMessage({id: 'JD'})}</td>
            <td style={{color: 'green'}}>{item.total_cost_price}{formatMessage({id: 'JD'})}</td>
        </tr>
    )
}

export default InTransitReportsTableRow
