import { useIntl } from 'react-intl';
import PageHeader from '../common/PageHeader'
import EditBulkCard from './add-bulk/EditBulkCard'

const UpdateBulk = () => {
  const {formatMessage} = useIntl();
  return (
    <>
        <PageHeader title={formatMessage({ id: "edit_prod_bulk" })} />
        <EditBulkCard />
    </>
  )
}

export default UpdateBulk