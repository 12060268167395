import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useIntl} from 'react-intl'
import {Route, useNavigate} from 'react-router-dom'
import { MouseEvent } from 'react'
import AppliedDailyDealListPage from "../../../../../app/pages/daily-deals/AppliedDailyDealListPage";

export function MenuInner() {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  function toLink(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const target = event.currentTarget.href;
    navigateToLink(target);
  }
  function navigateToLink(target: string) {
    const url = new URL(target);
    const pathname = url.pathname + url.search; // remove the hostname and port
    navigate(pathname, { replace: false });
  }

    function getDateEnd() {
        const date = new Date();
        const formatted_Date = date.toLocaleDateString('en-US', {day: '2-digit' , month: '2-digit', year: 'numeric'});
        const [monthValue, dayValue, yearValue] = formatted_Date.split('/');
        return `${yearValue}-${monthValue}-${dayValue}`;
    }

    function getDateStart() {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        const formatted_Date = date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});
        const [monthValue, dayValue, yearValue] = formatted_Date.split('/');
        return `${yearValue}-${monthValue}-${dayValue}`;
    }

    return (
    <>
      <MenuItem title={formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}

      <MenuInnerWithSub
        title={formatMessage({id: 'prods'})}
        to='/products'
        menuPlacement='bottom-start'
        menuTrigger="{default:'click', lg: 'hover'}"
      >
        <MenuItem to='/products/product-add' title={formatMessage({id: 'add_single_prod'})} />
        <div className='menu-item me-lg-1'>
          <a onClick={toLink} className={`menu-link py-3 ${window.location.pathname === '/products/custom/list' ? 'active' : ''}`} href='/products/custom/list?filter_warehouse_quantity=1'>
            <span className="menu-title">{formatMessage({id: 'stock_total_qty'})}</span>
          </a>
        </div>
        <MenuItem to='/products/list' title={formatMessage({id: 'prods_list'})} />
        <MenuItem to='/products/bulk-add' title={formatMessage({id: 'add_using_bulk'})} />

        <MenuItem to='/products/bulk-update' title={formatMessage({id: 'update_using_bulk'})} />
      </MenuInnerWithSub>

      <MenuItem title={formatMessage({id: 'por_title_header'})} to='/orders' />

      <MenuInnerWithSub
        title={formatMessage({id: 'add_discounts_label'})}
        to='/discount'
        menuPlacement='bottom-start'
        menuTrigger="{default:'click', lg: 'hover'}"
      >
        <MenuItem
          to='/discount/add-selection'
          title={formatMessage({id: 'add_on_selected_prod'})}
        />
        <MenuItem
            to='/discount/add-using-bulk'
            title={formatMessage({id: 'add_using_bulk'})}
        />
        <MenuItem
            to='/discount/remove-discount-bulk'
            title={formatMessage({id: 'disable_using_bulk'})}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={formatMessage({id: 'payment'})}
        to='/payment'
        menuPlacement='bottom-start'
        menuTrigger="{default:'click', lg: 'hover'}"
      >
        <MenuItem
            to={`/payment/transaction-report?filter_date_start=${getDateStart()}&filter_date_end=${getDateEnd()}`}
            title={formatMessage({id: 'transaction_report'})}
        />
        <MenuItem
            to={`/payment/statement-report`}
            title={formatMessage({id: 'statement_report'})}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
          title={formatMessage({id: 'daily_deals'})}
          to=''
          menuPlacement='bottom-start'
          menuTrigger="{default:'click', lg: 'hover'}">
        <MenuItem title={formatMessage({id: 'add_your_daily_deals'})} to='/daily-deal/list' />
        <MenuItem  title={formatMessage({id: 'daily_deals_products_list'})} to='/applied-daily-deal/list' />
      </MenuInnerWithSub>

      <MenuInnerWithSub
          title={formatMessage({id: 'sales_title_header'})}
          to='/sales'
          menuPlacement='bottom-start'
          menuTrigger="{default:'click', lg: 'hover'}"
      >
        <MenuItem title={formatMessage({id: 'loss_of_opportunity'})} to='sales/loss-of-opportunity' />
        <MenuItem
            to={`/sales/sales-report?filter_date_start=${getDateStart()}&filter_date_end=${getDateEnd()}`}
            title={formatMessage({id: 'sales_report'})}
        />
        <MenuItem
            to={`/sales/in-transit?filter_date_start=${getDateStart()}&filter_date_end=${getDateEnd()}`}
            title={formatMessage({id: 'in_transit_report'})}
        />
      </MenuInnerWithSub>
      <MenuItem title={formatMessage({id: 'requests'})} to='/request-add-products' />

      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
