import {forwardRef, useCallback, useImperativeHandle, useState} from 'react'

import {IDuplicateTableRef} from './ConnectedBarcodeDetails'
import { ISpecialProductModel } from '../core/_models'
import { UserModel } from '../../../auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Loader from '../../../common/Loader'
import AlertMessage from '../../../common/alert/AlertMsg'
import { applyConnectedProducts } from '../core/_requests'
import ConnectedBarcodeTableHead from './ConnectedBarcodeTableHead'
import ConnectedBarcodeTableRow from './ConnectedBarcodeTableRow'
import { useIntl } from 'react-intl'


interface IProps {
  products: ISpecialProductModel[],
  currentUser:UserModel | undefined
}
const ConnectedBarcodeTablee = forwardRef<IDuplicateTableRef, IProps>(({products,currentUser}, ref) => {
  const {formatMessage} = useIntl()
  let prevBarcode: string
  let barcodeState: string
  const navigate = useNavigate()
  const [search,setSearch] = useSearchParams()

  const prepareState = () => {
    const tempState: any = {}
    for (let i = 0; i < products.length; i++) {
      const item = products[i]
      if (item.barcode !== barcodeState) {
        barcodeState = item.barcode
        tempState[item.barcode] = {
          error: true,
          keys: [],
        }
      }
    }
    return tempState
  }
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [state, setState] = useState<any>(prepareState);

  const handleRowChange = useCallback((barcode: string, key: number, isChecked: boolean) => {
    const selectedState = state[barcode]
    if (isChecked) {
      selectedState.keys.push(key)
      selectedState.error = false
    } else {
      selectedState.keys = selectedState.keys.filter((k: number) => k !== key)
      selectedState.error = true
    }
    setState({...state, [barcode]: {...selectedState}})
  }, [])
  const submitTable = async () => {
    setIsError(false);
    let checkValidity = true;
    let selectedKeys:string[] = []
    for(let i in state) {
      if (state[i].error === true) {
        checkValidity = false;
        break;
      }
      selectedKeys.push(...state[i].keys)
    }
    if (checkValidity) {
      try {
        setIsLoading(true)
        const data = await applyConnectedProducts(currentUser?.token,{
          special_ids: selectedKeys
        })
        
        if (data) {
          const prevPage = search.get('from')
          let from = `${document.location.pathname}`
          if (prevPage)
            from = prevPage
          navigate(`/discount/pending-special-products?from=${from}`);
        } else {
          setIsError(true);
        }
        setIsLoading(false)
      } catch {
        setIsError(true);
        setIsLoading(false)
      }
      
    } else {
      setIsError(true);
    }
  }
  useImperativeHandle(ref, () => ({submitTable}))

  return (
    <>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          <ConnectedBarcodeTableHead />

          {/* begin::Table body */}
          <tbody>
            {products &&
              products.map((item, index) => {
                if (index === 0) {
                  prevBarcode = item.barcode
                  return (
                    <ConnectedBarcodeTableRow
                      onChange={handleRowChange}
                      key={item.supplier_product_special_id}
                      item={item}
                    />
                  )
                }
                if (prevBarcode === item.barcode) {
                  return (
                    <ConnectedBarcodeTableRow
                      onChange={handleRowChange}
                      key={item.supplier_product_special_id}
                      item={item}
                    />
                  )
                } else {
                  prevBarcode = item.barcode
                  return (
                    <ConnectedBarcodeTableRow
                      isNewBarcode={true}
                      onChange={handleRowChange}
                      key={item.supplier_product_special_id}
                      item={item}
                    />
                  )
                }
                return <></>
              })}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {isLoading && <Loader isFull={true} />}
      {isError && <AlertMessage title={formatMessage({id: 'at_least_select_alert_title'})} message={formatMessage({id: 'at_least_select_alert_desc'})} onClose={()=> setIsError(false)} />}
    </>
  )
})

export default ConnectedBarcodeTablee
