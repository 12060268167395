import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IProductProps, productActionType} from '../../cors/reducers'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'
import Loader from '../../../common/Loader'

type IImageBox = {
  imageSrc: string
  onRemove: (imageIndex: number) => void
  index: number
  setPreviewImage: React.Dispatch<React.SetStateAction<string>>
}

const IMAGE_MODAL: React.CSSProperties | undefined = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '50%',
  height: '50%',
  zIndex: 101,
  margin: 'auto',
}
const MODAL_OVERLAY: React.CSSProperties | undefined = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '#0000004d',
  zIndex: 100,
  margin: 'auto',
}

type IPropModal = {
  src: string
  onClose: () => void
}

const ImageModal: FC<IPropModal> = ({src, onClose}) => {
  return ReactDOM.createPortal(
    <>
      <div onClick={onClose} className='z-index-n3 cursor-pointer' style={MODAL_OVERLAY}></div>
      <div
        style={IMAGE_MODAL!}
        className='image-popup z-index-n4 p-5 bg-white d-flex align-items-center justify-content-center'
      >
        <i
          onClick={onClose}
          className='fas bg-white rounded-circle cursor-pointer d-flex align-items-center justify-content-center fa-times-circle position-absolute remove-gallery-img'
        ></i>
        <img src={src} className='mw-100 mh-100' />
      </div>
    </>,
    document.getElementById('root-modals')!
  )
}

const ImageBox: FC<IImageBox> = ({imageSrc, index, setPreviewImage, onRemove}) => {
  const previewImage = () => {
    setPreviewImage(imageSrc)
  }

  return (
    <div className='gallery-img-box position-relative w-50px h-50px shadow  d-flex align-items-center justify-content-center bg-white cursor-pointer'>
      <i
        onClick={() => onRemove(index)}
        className='fas fa-times-circle position-absolute remove-gallery-img'
      ></i>
      <img onClick={previewImage} className='rendered-img mw-100 mh-100' src={imageSrc} />
    </div>
  )
}

const ProductMoreImages: FC<IProductProps> = ({prodState, prodDispatch}) => {
  const {formatMessage} = useIntl()
  const [images, setImages] = useState<any[]>(prodState.product_image)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [isLoading,setIsLoading] = useState(false)
  const handleChange = (files: FileList | null) => {
    if (files) {
      if (imagesCount()) {
        setIsLoading(true)
        var reader = new FileReader();
        const tempImages:any = [];
        const readFile = (index:number) => {
          if (files && index >= files.length) {
            if (tempImages.length > 0) {
              setImages([...images,...tempImages])
            }
            setIsLoading(false)
          };
          var file = files[index]

          reader.onload = function (e: any) {
            prodDispatch({type: productActionType.product_image,payload: {id: 'product_image', value: file}})
            tempImages.push(e.target.result)
            readFile(index+1)
          }
          if (file)
          reader.readAsDataURL(file)
        }
        readFile(0);
      }
    }
  }
  const imagesCount = () => images.length < 10

  const closeImageModal = () => {
    setPreviewImage('')
  }

  const removeImageHandler = (imageIndex: number) => {
    const restImages = [...images]
    restImages.splice(imageIndex, 1)
    prodDispatch({type: productActionType.product_image,payload: {id: 'product_image', value: 'remove',index:imageIndex}})
    setImages(restImages)
  }

  return (
    <>
      <div className='card default-image-box'>
        <div className='card-body'>
          <h3 className='card-title mb-8'>
            <span className='card-label fw-bold fs-3'>{formatMessage({id: 'more_images'})}</span>
          </h3>
          {images.length > 0 && (
            <div className='d-flex gap-4 flex-wrap mb-9'>
              {images.map((img, index) => (
                <ImageBox
                  setPreviewImage={setPreviewImage}
                  key={`${img}${index}`}
                  index={index}
                  imageSrc={img.image || img }
                  onRemove={removeImageHandler}
                />
              ))}
            </div>
          )}
          <div className='upload-from-box border mb-1 px-3 py-2 rounded-2 position-relative'>
            <p className='mb-0 d-flex justify-content-between fw-bold text-gray-600'>
            {formatMessage({id: 'upload_from_computer'})} <img width='17' src={toAbsoluteUrl('/media/logos/upload.svg')} />
            </p>
            <input
              onChange={(e) => handleChange(e.target.files)}
              accept='image/png, image/gif, image/jpeg,image/jpg, image/webp'
              type='file'
              multiple={true}
              className='position-absolute w-100 h-100 opacity-0 top-0 left-0 cursor-pointer'
            />
          </div>
          {imagesCount() ? (
            <small className='fw-bold text-gray-600'>{formatMessage({id: 'upload_images_limit_prod'})}</small>
          ) : (
            <small className='text-danger fw-bold d-block'>
             {formatMessage({id: 'upload_images_limit_prod_err'})} 
            </small>
          )}
        </div>
      </div>
      {previewImage && <ImageModal onClose={closeImageModal} src={previewImage} />}
      {isLoading && <Loader isFull={true} />}
    </>
  )
}

export default ProductMoreImages
