import React, {ReactNode, useEffect, useState} from 'react'
import {
  IPOInfoFilter,
  IPOInfoFilterInitialState,
  IPOInfoModel,
  IPOTypes,
} from './core/_models'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import {useParams} from 'react-router-dom'
import {getPurchaseOrderInfo} from './core/_requests'
import SharedData from '../common/SharedData'
import ReturnTable from './components/return/ReturnTable'
import { useIntl } from 'react-intl'

const ReturnOrder: React.FC = () => {
  const {formatMessage} = useIntl();
  const {orderID} = useParams()

  const {currentUser,language} = SharedData();
  const [purchaseList, setPurchaseList] = useState<IPOInfoModel[]>([])
  const [resultFilters, setResultFilters] = useState<IPOInfoFilter>({
    ...IPOInfoFilterInitialState,
    type: IPOTypes.return,
    supplier_order_id: orderID || ''
  })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setIsLoading(true)
    const {data} = await getPurchaseOrderInfo(resultFilters, currentUser?.token, language)
    if (data) {
      const responseData = data.po_info;
      const products = []
      for (let key in responseData) {
        products.push(responseData[key])
      }
      setPurchaseList(products)
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (purchaseList.length > 0) {
      return (
        <div className='card-body py-3'>
          <ReturnTable orders={purchaseList} />
        </div>
      )
    }
    return <NormalMessage msg={formatMessage({ id: "no_data" })} />
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "return_order_num" })}{orderID}</span>
        </h3>
      </div>
      {/* end::Header */}

      {resultsData()}
    </div>
  )
}

export {ReturnOrder}
