import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { Languages } from '../../../partials/layout/header-menus/Languages'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../app/modules/auth'
import { doLogout } from '../../../../app/modules/auth/core/_requests'
import React, { useEffect, useState } from 'react';
import { getSupplierInfo } from '../../../../app/modules/my-profile/core/_requests';


const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {formatMessage} = useIntl()
  const {config} = useLayout()
  const {currentUser,logout} = useAuth()
  const [supplierName, setSupplierName] = useState('User');

  useEffect(() => {
    const fetchSupplierName = async () => {
      if (!currentUser?.token) return;

      const { data } = await getSupplierInfo(currentUser.token, 'en');
      const supplierInfo = data?.supplier_info;
      if (supplierInfo && supplierInfo.name) {
        const firstName = supplierInfo.name.split(' ')[0];
        setSupplierName(firstName);
      } else {
        console.error('Supplier name not found in fetched data.');
      }
    };

    fetchSupplierName();
  }, [currentUser]);
  const logoutHandler = async ()=>{
    await doLogout(currentUser?.token || '')
    logout()
    document.location.reload()
  }

  return (
    <div className='d-flex  justify-content-end align-items-center'>
      <Link className='btn btn-secondary p-2 px-5 bg-light' to='/myprofile'>
        {formatMessage({ id: 'greeting' }, { name: supplierName })}
      </Link>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div data-kt-menu='true' className='menu menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6'>
          <Languages />
        </div>
      </div>

      <button onClick={logoutHandler} className='bg-transparent border-0 fw-bold text-gray-600 logout-btn-header'>
        {formatMessage({id: 'logout'})} <i className="fa-solid fa-power-off"></i>
      </button>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div className='btn btn-icon btn-active-color-primary w-35px h-35px' id='kt_app_header_menu_toggle'>
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
