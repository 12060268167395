import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ITransactionReportModel } from '../core/_models'

type IProps = {
    item: ITransactionReportModel,
    showDetails: (params?: ITransactionReportModel) => void
}

const TransactionReportTableRow: FC<IProps> = ({ item, showDetails }) => {
    const { formatMessage } = useIntl()

    return (
        <tr>
            <th>
                <img src={item.image} className='table-img' alt='Product'/>
            </th>
            <td>{item.barcode}</td>
            <td>{item.model}</td>
            <td>{item.name}</td>
            <td>{item.transaction_type}</td>
            <td>{item.date_completed}</td>
            <td>{item.adjusted_quantity}</td>
            <td style={{color: item.is_return ? 'red' : 'green'}}>
                {item.cost_price}{formatMessage({id: 'JD'})}
            </td>
            <td style={{color: item.is_return ? 'red' : 'green'}}>
                {item.total_cost_price}{formatMessage({id: 'JD'})}
            </td>
            <td>{item.type_id}</td>
        </tr>
    )
}

export default TransactionReportTableRow
