import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import { getApplySpecialProducts } from "./_requests";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";



export const useCheckPendingBulk = ()=>{
    const {currentUser} = useAuth();
    const language = useLang()
    const navigate = useNavigate()

    useEffect(()=>{
        const checkPendingBulk = async () =>{
            const {data} = await getApplySpecialProducts(currentUser?.token, language)
            // if list is empty, return the cycle
            if (data && data.hasOwnProperty('special_list') &&  data.special_list?.length > 0) {
                navigate(`/discount/add-discount-bulk?from=${document.location.pathname}`)
            }
        }
        
        checkPendingBulk()
    },[])
}
