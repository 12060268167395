import React from 'react'
import PageHeader from '../common/PageHeader'
import BulkCard from './add-bulk/BulkCard'
import { useIntl } from 'react-intl';

const AddBulk = () => {
  const {formatMessage} = useIntl();
  return (
    <>
        <div className='d-flex align-items-center'>
            <PageHeader title={formatMessage({ id: "add_product_bulk" })} />
            <a href="https://www.youtube.com/watch?v=PdFTP2Zkah4" target="_blank" rel="noopener noreferrer" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '4px'}}>
                {formatMessage({ id: "video_tutorial" })}
            </a>
        </div>
        <BulkCard />
    </>
  )
}

export default AddBulk