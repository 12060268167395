import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { IProductModel, PRODUCT_ACTIONS } from '../cors/_models'
import { useIntl } from 'react-intl'

type IProps = {
  product: IProductModel
  onChangeQty: (value: string, prdID: number) => void
  onAction: (action: PRODUCT_ACTIONS, productID: number) => Promise<any>
}
let userUpdateQtyTimeout: NodeJS.Timeout

const ProductTableRow: FC<IProps> = ({ product, onChangeQty, onAction }) => {
  const { formatMessage } = useIntl()
  const [qtyInput, setQtyInput] = useState(product.supplier_quantity)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleQuantityChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target
    if (value !== '') {
      setQtyInput(value)

      clearTimeout(userUpdateQtyTimeout)

      userUpdateQtyTimeout = setTimeout(() => {
        onChangeQty(value, product.product_id)
      }, 1000)
    }
  }

  const handleAction = async (action: PRODUCT_ACTIONS) => {
    setIsLoading(true)
    await onAction(action, product.product_id)
    setIsLoading(false)
  }

  const specialDiscount = (oldPrice: string, newPrice: string) => {
    let convertedOldPrice = (+oldPrice).toFixed(2)
    return (
      <>
        {newPrice ? (
          <>
            <del>{convertedOldPrice}</del>
            <br />
            <span className="text-danger">{newPrice}</span>
          </>
        ) : (
          <>{convertedOldPrice}</>
        )}
      </>
    )
  }

  return (
    <tr>
      <th>
        <img src={product.image} className='table-img' />
      </th>
      <td className="w-250px" style={{ padding: '10px', verticalAlign: 'middle' }}>
        {product.name}
      </td>

      <td
        style={{
          whiteSpace: 'nowrap',
          padding: '10px',
          paddingRight: '15px',
          verticalAlign: 'middle',
        }}
      >
        {product.barcode}
      </td>

      <td className="text-nowrap" style={{ padding: '5px', verticalAlign: 'middle' }}>
        {product.model}
      </td>

      <td style={{ padding: '30px', verticalAlign: 'middle' }}>
        <input
          onChange={handleQuantityChange}
          type="number"
          className="form-control"
          style={{
            width: `${String(qtyInput).length + 2.5}ch`,
            height: '23.44px',
            padding: '0 2px',
          }}
          onWheel={(e: any) => e.target.blur()}
          value={qtyInput}
          min="1"
        />
      </td>
      <td>{product.warehouse_quantity}</td>

      <td>{specialDiscount(product.cost_price, product.special_cost)}</td>

      <td>{specialDiscount(product.price, product.special_sale)}</td>

      <td>{product.manufacturer_name}</td>
      <td>{product.product_status}</td>

      <td>
        {formatMessage({ id: 'sold_qty' })}: {product.sold_units}
        <br />
        {formatMessage({ id: 'view' })}: {product.views}
      </td>

      <td
        className="text-end px-0"
        style={{ whiteSpace: 'normal', verticalAlign: 'top', width: '100px' }}
      >
        {isLoading ? (
          <span className="align-middle spinner-border spinner-border-sm"></span>
        ) : (
          <>
            <div className="d-flex flex-column align-items-stretch" style={{ gap: '4px', padding: '0', width: '100px' }}>
              <Link
                className="btn btn-secondary p-2 px-5 align-self-baseline"
                style={{ textAlign: 'center', width: '100px'}}
                to={`/products/product-edit/${product.product_id}`}
              >
                {formatMessage({ id: 'edit' })}
              </Link>
              {product.status_id && product.status_id !== 3 && product.supplier_quantity > 0 && (
                <button
                  className="btn btn-warning p-1 px-2 align-self-baseline"
                  style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100px'}}
                  onClick={() => handleAction(PRODUCT_ACTIONS.OUT_OF_STOCK)}
                >
                  {formatMessage({ id: 'out_of_stock' })}
                </button>
              )}
              {typeof product.view !== 'boolean' && (
                <a
                  target="_blank"
                  className="btn btn-primary p-1 px-2 align-self-baseline"
                  style={{ textAlign: 'center', width: '100px'}}
                  href={product.view}
                >
                  {formatMessage({ id: 'view' })}
                </a>
              )}
              {product.status_id && product.status_id !== 2 && (
                <button
                  className="btn btn-danger p-1 align-self-baseline"
                  style={{ textAlign: 'center', width: '100px'}}
                  onClick={() => handleAction(PRODUCT_ACTIONS.INACTIVE_PRODUCT)}
                >
                  {formatMessage({ id: 'inactivate' })}
                </button>
              )}
            </div>
            <div style={{ marginBottom: '16px' }}></div>
          </>
        )}
      </td>
    </tr>
  )
}

export default ProductTableRow
