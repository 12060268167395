import { FC } from "react"
import { IBestSellerProductsModel } from "../core/_models"

type IProps = {
    item: IBestSellerProductsModel
}

const BestSellerProductsCardRow:FC<IProps> = ({item}) => {
  return (
    <tr className="border-bottom border-dashed border-gray-400">
        <td className=" text-gray-600">{item.name}</td>
        <td className=" text-gray-600">{item.barcode}</td>
        <td className=" text-gray-600">{item.sold_quantity}</td>
    </tr>
  )
}

export default BestSellerProductsCardRow