import axios from 'axios'
import {IPOInfoModel, IPOTableFilter, PO, IPOInfoFilter, IPaymentDetailsRow, IGRNInvoicesModel, MonthlyGRNDetailsModel} from './_models'
import {APPLIED_DAILY_DEAL_LIST} from "../../products/cors/_requests";

const API_URL = process.env.REACT_APP_API_URL
export const GET_PURCHASE_RETURN_ORDERS = `${API_URL}/api/partner-portal/po`
export const GET_PURCHASE_ORDER_INFO = `${API_URL}/api/partner-portal/po/info`
export const SET_PURCHASE_ORDER_REMOVE_REASON = `${API_URL}/api/partner-portal/po/remove-item`
export const SET_PURCHASE_ORDER_COST_REQ = `${API_URL}/api/partner-portal/po/cost-request`
export const GET_PO_LIST_PAYMENT_DETAILS = `${API_URL}/api/partner-portal/po/payment-details`
export const GET_GRN_INVOICES = `${API_URL}/api/partner-portal/po/GRN?supplier_order_id=`
export const GET_MONTHLY_RECEIVE_POS_AND_RETURNS = `${API_URL}/api/partner-portal/po/grn-monthly-sales`

export const prepareFilterParams = (obj: any): string => {
  let queryParams = ''
  Object.keys(obj).map((filter) => {
    if (obj[filter] !== '') queryParams += '&' + filter + '=' + obj[filter]
  })
  return queryParams
}

export function getPurchaseReturnOrders(filtersObj: any, token: string | undefined,language:string) {
  return axios.get<{data:{po_list: PO[]; total_po: number}}>(
    `${GET_PURCHASE_RETURN_ORDERS}${filtersObj}`,
    {headers: {token: token || '',language: language}}
  )
}

export function getMonthlyReceivePosAndReturns(filtersObj: any, token: string | undefined,language:string) {
  return axios.get<any>(
      `${GET_MONTHLY_RECEIVE_POS_AND_RETURNS}${filtersObj}`,
      {headers: {token: token || '',language: language}}
  )
}

export function getGrnInvoces(supplierOrderID:string, token: string | undefined,language:string) {
  return axios.get<IGRNInvoicesModel>(
    `${GET_GRN_INVOICES}${supplierOrderID}`,
    {headers: {token: token || '',language: language}}
  )
}

// /api/partner-portal/po/info?supplier_order_id=56530&type=return
export function getPurchaseOrderInfo(filtersObj: IPOInfoFilter, token: string | undefined,language:string) {
  return axios.get<{po_info: IPOInfoModel[]; show_suggested_btn: boolean}>(
    `${GET_PURCHASE_ORDER_INFO}?${prepareFilterParams(filtersObj)}`,
    {headers: {token: token || '',language}}
  )
}


export function getPOListPaymentDetails(filtersObj: any, token: string | undefined,language:string) {
  return axios.get<{payment_details:IPaymentDetailsRow}>(
    `${GET_PO_LIST_PAYMENT_DETAILS}?${prepareFilterParams(filtersObj)}`,
    {headers: {token: token || '',language}}
  )
}

// purchase order :: partially_available | available_later
export function sendPartiallyAvailableOrder(
  language: string,
  data: any,
  token: string | undefined
) {
  return axios.post<any>(`${SET_PURCHASE_ORDER_REMOVE_REASON}`,data, {
    headers: {token: token || '',language},
  })
}

// purchase order :: SUGGEST COST
export function sendSuggestCost(
  language: string,
  data: any,
  token: string | undefined
) {
  return axios.post<any>(`${SET_PURCHASE_ORDER_COST_REQ}`,data, {
    headers: {token: token || '',language},
  })
}

export function prepareFilters(searchParams:URLSearchParams,setSearchParams:any){
  const currentParams = Object.fromEntries(searchParams)
  Object.keys(currentParams).map((keyName) => {
    const key = currentParams[keyName]
    if (key) {
      searchParams.set(keyName, key.toString())
    }
  });
  setSearchParams(searchParams,{
    replace:true
  })
}