import {FC} from 'react'
import ProductDefaultImage from './general/ProductDefaultImage'
import ProductMoreImages from './general/ProductMoreImages'
import BarcodeCompany from './general/BarcodeCompany'
import ProductGeneralForm from './general/ProductGeneralForm'
import {IProductProps} from '../cors/reducers'

const ProductGeneralTab: FC<IProductProps> = ({prodState, prodDispatch}) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-3'>
          <div className='d-flex flex-column gap-3'>
            <ProductDefaultImage prodState={prodState} prodDispatch={prodDispatch} />
            <ProductMoreImages prodState={prodState} prodDispatch={prodDispatch} />
            <BarcodeCompany prodState={prodState} prodDispatch={prodDispatch} />
          </div>
        </div>
        <div className='col-md-9 mt-10 mt-md-0'>
          <ProductGeneralForm prodState={prodState} prodDispatch={prodDispatch} />
        </div>
      </div>
    </>
  )
}

export default ProductGeneralTab
