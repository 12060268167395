import {ReactNode, useEffect, useState} from 'react'
import SharedData from '../common/SharedData'
import {IProductModel} from './cors/_models'
import {getProductsList} from './cors/_requests'
import Loader from '../common/Loader'
import Paginator from '../common/Paginator'
import NormalMessage from '../common/NormalMessage'
import ProductsTable from './product-list/ProductsTable'
import ProductsTableFilter from './product-list/ProductsTableFilter'
import {prepareFilters} from '../orders/core/_requests'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'

const ProductsList = () => {
  const {formatMessage} = useIntl();

  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser, language} = SharedData()
  const [productList, setProductList] = useState<IProductModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [canFilter, setCanFilter] = useState(true)
  const [totalResult, setTotalResult] = useState<number>(0)

  useEffect(() => {
    getDate()
  }, [searchParams])

  const getDate = async () => {
    prepareFilters(searchParams, setSearchParams)
    setIsLoading(true)
    const queryParams = document.location.search
    try {
      const data = await getProductsList(queryParams, currentUser?.token, language)
      
      if (data) {
        setProductList(data.data.data.products)
        if (data.data.data.total_products)
          setTotalResult(data.data.data.total_products)
        else
          setTotalResult(0)
      } else {
        setProductList([])
        setTotalResult(0)
      }
    } catch (exp) {
      
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    
    return (
      <div className='card-body py-3'>
        {isLoading && <Loader isFull={true} />}
        <ProductsTable refreshData={getDate} products={productList} />
        <Paginator totalResult={totalResult} />
      </div>
    )
  }

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "prods_list" })}</span>
        </h3>
        {/* begin::Menu */}
        <div className='card-toolbar'>
          <button
            type='button'
            className={`${
              canFilter && 'active'
            } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
            onClick={() => setCanFilter(!canFilter)}
          >
            {formatMessage({ id: "filter" })} <i className='fas fa-filter'></i>
          </button>
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Header */}

      {canFilter && (
        <ProductsTableFilter />
      )}

      {resultsData()}
    </div>
  )
}

export default ProductsList
