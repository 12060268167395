import axios from 'axios'
import { IActiveProductsBody, IBestSellerProductsModel, IDailySalesDataBody, IMonthlyServiceBody, INextPaymentBody,
  IPendingPOSModel, ISalesDataBody, IStockQtyBody, ILossOfOpportunityTotalBody } from './_models'
import {IInTransitReportModel, ITotalInTransitRow} from "../../reports/core/_models";
import {GET_IN_TRANSIT_SALES_REPORT} from "../../reports/core/_requests";

const API_URL = process.env.REACT_APP_API_URL
export const GET_SALES_DATA = `${API_URL}/api/partner-portal/dashboard/sales`
export const GET_DAILY_SALES = `${API_URL}/api/partner-portal/dashboard/total-daily-sales`
export const GET_ACTIVE_PRODUCTS_INFO = `${API_URL}/api/partner-portal/dashboard/active-products-count`
export const GET_STOCK_QTY_INFO = `${API_URL}/api/partner-portal/dashboard/stock-qty`
export const GET_MONTHLY_SERVICE_INFO = `${API_URL}/api/partner-portal/dashboard/monthly-service-level`
export const GET_NEXT_PAYMENT_INFO = `${API_URL}/api/partner-portal/dashboard/next-payment`
export const GET_BEST_SELLER_PRODUCTS_INFO = `${API_URL}/api/partner-portal/dashboard/best-seller-products`
export const GET_PENDING_POS_INFO = `${API_URL}/api/partner-portal/dashboard/pending-pos`
export const GET_LOSS_OF_OPPORTUNITY_TOTAL = `${API_URL}/api/partner-portal/dashboard/total-loss-from-unavailability`
export const GET_TOTAL_IN_TRANSIT_PRODUCT = `${API_URL}/api/partner-portal/dashboard/total-in-transit-products`

export function getSalesData(token:string|undefined,language:string) {
  return axios.get<{data: ISalesDataBody}>(`${GET_SALES_DATA}`, {
    headers: {token: token || "", language},
  })
}

export function getDailySales(token:string|undefined,language:string) {
  return axios.get<{data: IDailySalesDataBody}>(`${GET_DAILY_SALES}`, {
    headers: {token: token || "", language},
  })
}

export function getActiveProductsInfo(token:string|undefined,language:string) {
  return axios.get<{data: IActiveProductsBody}>(`${GET_ACTIVE_PRODUCTS_INFO}`, {
    headers: {token: token || "", language},
  })
}
export function getStockQtyInfo(token:string|undefined,language:string) {
  return axios.get<{data: IStockQtyBody}>(`${GET_STOCK_QTY_INFO}`, {
    headers: {token: token || "", language},
  })
}

export function getLossOfOpportunityTotal(token:string|undefined,language:string) {
  return axios.get<{data: ILossOfOpportunityTotalBody}>(`${GET_LOSS_OF_OPPORTUNITY_TOTAL}`, {
    headers: {token: token || "", language},
  })
}

export function getMonthlyServiceInfo(token:string|undefined,language:string) {
  return axios.get<{data:IMonthlyServiceBody}>(`${GET_MONTHLY_SERVICE_INFO}`, {
    headers: {token: token || "", language},
  })
}

export function getNextPaymentInfo(token:string|undefined,language:string) {
  return axios.get<{next_payment:INextPaymentBody}>(`${GET_NEXT_PAYMENT_INFO}`, {
    headers: {token: token || "", language},
  })
}


export function getBestSellerProductsInfo(token:string|undefined,language:string) {
  return axios.get<{data:{best_seller_products:IBestSellerProductsModel[]}}>(`${GET_BEST_SELLER_PRODUCTS_INFO}?language=${language}`, {
    headers: {token: token || "", language},
  })
}


export function getPendingPOSInfo(token:string|undefined,language:string,limit=5) {
  return axios.get<{data:{pending_pos:IPendingPOSModel[],pending_pos_count:number}}>(`${GET_PENDING_POS_INFO}?limit=${limit}`, {
    headers: {token: token || "", language},
  })
}



export function getInTransitReports(
    token: string | undefined,
    language: string
) {

  return axios.get<{ total_in_transit: ITotalInTransitRow  }>(
      `${GET_TOTAL_IN_TRANSIT_PRODUCT}`,
      {
        headers: {
          token: token || '',
          language: language,
        },
      }
  );
}