import React, { FC } from 'react'

type IProps = {
    title: string,
    children?: React.ReactNode
}

const PageHeader:FC<IProps> = ({title,children}) => {
    return (
    <div className='card-header border-0 pt-5 mb-7 justify-content-between align-items-center d-flex flex-column-p'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
      </h3>
      {children && (
        <div className='card bg-transparent mt-5 mt-md-0'>
          <div className='px-9 card-body pt-0 pb-0 '>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default PageHeader
