import AddProductReq from '../../modules/requests/add-product/AddProductReq'

const UpdateProductReqPage = () => {
  return (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
            <AddProductReq />
        </div>
        {/* end::Col */}
        </div>
        {/* begin::Row */}
    </>
  )
}

export default UpdateProductReqPage