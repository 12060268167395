import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import {getLossOfOpportunityTotal} from '../core/_requests'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ILossOfOpportunityTotalBody} from '../core/_models'
import {left} from "@popperjs/core";

type Props = {
    className: string
    language: string
    currentUser: UserModel | undefined
}

const LossOfOpportunityTotalCard: React.FC<Props> = ({language, currentUser, className}) => {
    const {formatMessage} = useIntl();
    const [state, setState] = useState<ILossOfOpportunityTotalBody>({
        total_supplier_loss: '0',
        total_dumyah_loss: '0',
        product_count: 0,
        order_count: 0
    })

    useEffect(() => {
        const getData = async () => {
            const {data} = await getLossOfOpportunityTotal(currentUser?.token, language)
            if (data) {
                setState({
                    total_supplier_loss: data.data.total_supplier_loss,
                    total_dumyah_loss: data.data.total_dumyah_loss,
                    product_count: data.data.product_count,
                    order_count: data.data.order_count
                })
            }
        }
        getData()
    }, [])
    // Loss_Of_Opportunity_Total
    return (
        <div className={`card ${className}`} style={{backgroundColor: "#FFC107"}}>
            <div className='h-100 d-flex flex-column justify-content-between'>
                <div className='card-header border-0 pt-2'>
                    <h3 className='card-title align-items-start flex-column'>
                       <span className='fw-semibold fs-2hx mb-1'
                             style={{
                                 color: "#D42F37",
                                 paddingLeft: '4px',
                                 paddingRight: '4px'
                             }}>{state.total_supplier_loss} {formatMessage({id: "jod"})}</span>
                        <div>
                            <span className='text-gray-100'>{formatMessage({id: "loss_of_opportunity_total"})}</span>
                            <span className='text-gray-100' style={{
                                paddingLeft: '4px',
                                paddingRight: '4px'
                            }}>{formatMessage({id: "from"})}</span>
                            <span className='text-gray-100 fw-semibold'
                                  style={{
                                      paddingLeft: '4px',
                                      paddingRight: '4px',
                                      fontSize: '16px'
                                  }}>{state.product_count}</span>

                            <span className='text-gray-100'>{formatMessage({id: "product"})}</span>
                        </div>
                    </h3>
                </div>
                <div className='card-header border-0 pt-2'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='fw-semibold fs-2hx mb-1'
                              style={{
                                  color: "#D42F37",
                                  paddingLeft: '4px',
                                  paddingRight: '4px'
                              }}>{state.total_dumyah_loss} {formatMessage({id: "jod"})}</span>
                        <div>
                            <span className='text-gray-100'>{formatMessage({id: "total_dumyah_loss"})}</span>
                            <span className='text-gray-100' style={{
                                paddingLeft: '4px',
                                paddingRight: '4px'
                            }}>{formatMessage({id: "from"})}</span>
                            <span className='text-gray-100 fw-bold fw-semibold'
                                  style={{paddingLeft: '4px', paddingRight: '4px'}}>{state.order_count}</span>
                            <span className='text-gray-100'>{formatMessage({id: "order"})}</span>
                        </div>
                    </h3>
                </div>
                <Link
                    to='/loss-of-opportunity/list'
                    className='card-header fs-4 border-0 d-flex justify-content-end align-items-center text-gray-100 fw-bold'>
                    {formatMessage({id: "view_prods"})}
                </Link>
            </div>
        </div>
    )
}

export default LossOfOpportunityTotalCard
