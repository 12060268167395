import React, {FC, useEffect, useRef, useState} from 'react'
import {ICities} from '../../core/_models'
import {getCities} from '../../core/_requests'
import SharedData from '../../../common/SharedData'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
interface IProps {
  field: any
  setFields: any
  saveField: (fieldName: string) => void
}
const MyProfileCityField: FC<IProps> = ({field, setFields, saveField}) => {
  const {formatMessage} = useIntl();
  
  const {currentUser, language} = SharedData()
  const [citiesData, setCitiesData] = useState<ICities[]>([])
  const [city, setCity] = useState({...field})

  useEffect(() => {
    const getDate = async () => {
      const {data: citiesData} = await getCities(currentUser?.token, language)
      setCitiesData(citiesData.cities)
    }
    getDate()
  }, [])

  const editField = (fieldID: string) => {
    setFields((prev: any) => ({
      ...prev,
      [fieldID]: {...prev[fieldID], edit: !prev[fieldID].edit},
    }))
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const {id} = e.currentTarget
    const {value} = e.target
    setFields((prev: any) => ({
      ...prev,
      [id]: {...prev[id], value, edit: !prev[id].edit},
      location: {...prev['location'],value:''}
    }))
    setCity({...city, value: value})
  }

  useEffect(() => {
    if (city.value !== field.value || field.value === '') {
      return
    }
    saveField('city')
  }, [field.value])

  const getCityName = () => {
    const cityIDProp = +field.value
    const cityName = citiesData.filter((item: ICities) => item.city_id === cityIDProp)
    if (cityName && cityName.length > 0) return <>{cityName[0].name}</>
    else return <>{formatMessage({ id: "select_city" })}</>
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <label className='text-gray-600 fw-bold fs-5'>{formatMessage({ id: "city" })}:</label>
      </div>
      <div className='col-md-6 d-flex'>
        {!field.edit ? (
          <div className='d-flex gap-1 flex-wrap-p'>
            <p className='text-dark fs-4 mb-0'>{getCityName()}</p>
            <div className='cursor-pointer w-25px' onClick={() => editField('city')}>
              <img alt='edit' width='15' src={toAbsoluteUrl('/media/logos/pen-solid.svg')} />
            </div>
            {field.changed && (
              <p className='mb-0 text-success fw-bold w-100-p'>{formatMessage({ id: "your_change_saved" })}</p>
            )}
          </div>
        ) : (
          <select
            className='form-control border-gray-900 text-gray-900'
            // type={type || 'text'}
            id='city'
            value={field.value}
            onChange={handleChange}
          >
            <option value="">{formatMessage({ id: "please_select" })}</option>
            {citiesData &&
              citiesData.map((item: ICities) => (
                <option key={item.city_id} value={item.city_id}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default MyProfileCityField
