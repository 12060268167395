import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import TransactionReportTableRow from './TransactionReportTableRow'
import {ITransactionReportModel, ITotalTransactionRow} from '../core/_models'
import '../../../stylesheet/transactionReport.css'

interface IProps {
    data: ITransactionReportModel[];
    transactionTotal?: ITotalTransactionRow | null;
}

const TransactionReportTable: FC<IProps> = ({data, transactionTotal}) => {

    console.log(transactionTotal);
    const {formatMessage} = useIntl()
    const [modalParam, setModalParam] = useState<{ show: boolean, data: ITransactionReportModel | null }>({
        show: false,
        data: null
    });

    const toggleModal = (params?: ITransactionReportModel) => {
        setModalParam((prev) => ({
            data: params ? params : prev.data,
            show: !modalParam.show
        }))
    }

    return (
        <>
                <table className='table align-middle gs-0 gy-5'>
                    <thead>
                    <tr>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'image'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'barcode'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'model_num'})}
                        </th>
                        <th className='fw-bold p-0 text-uppercase'>{formatMessage({id: 'prod_name'})}</th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>
                            {formatMessage({id: 'transaction_type'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({id: 'transaction_date'})}</th>
                        <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({id: 'sold_qty'})}</th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'total'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'total_cost'})}
                        </th>
                        <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                            {formatMessage({id: 'order_id'})} /   {formatMessage({id: 'return_id'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && (
                        data.map((item) => (
                            <TransactionReportTableRow
                                showDetails={toggleModal}
                                key={`${item.product_id}${item.supplier_order_id}&${item.type_product_id}`}
                                item={item}
                            />
                        ))
                    )}
                    {transactionTotal && (
                        <>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{formatMessage({id: 'total_sold_products'})}</td>
                                <td className='green-text td-border'>
                                    {String(transactionTotal?.total_sold_products?.total_quantity || 0)}
                                </td>
                                <td className='green-text td-border'>
                                    {String(transactionTotal?.total_sold_products?.total_cost_price || 0)}
                                </td>
                                <td className='green-text td-border'>
                                    {String(transactionTotal?.total_sold_products?.total_cost_price_with_quantity || 0)}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{formatMessage({id: 'total_returned_products'})}</td>
                                <td className='red-text td-border'>
                                    {String(transactionTotal?.total_return_products?.total_quantity || 0)}
                                </td>
                                <td className='red-text td-border'>
                                    {String(transactionTotal?.total_return_products?.total_cost_price || 0)}
                                </td>
                                <td className='red-text td-border'>
                                    {String(transactionTotal?.total_return_products?.total_cost_price_with_quantity || 0)}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{formatMessage({id: 'availability_fees'})}</td>
                                <td className='red-text td-border'>
                                    {String(transactionTotal?.total_availability_fees?.total_quantity || 0)}
                                </td>
                                <td className='td-border'></td>
                                <td className='red-text td-border'>
                                    {String(transactionTotal?.total_availability_fees?.total_availability_fees || 0)}
                                    {transactionTotal?.availability_fee_flag
                                        ? ''
                                        : formatMessage({id: 'availability_fees_flag'})}
                                </td>

                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{formatMessage({id: 'the_amount_due'})}</td>
                                <td className='blue-text td-border'>
                                    {String(transactionTotal?.final_totals?.final_total_quantity || 0)}
                                </td>
                                <td className='td-border'></td>
                                <td className='blue-text td-border'>
                                    {String(transactionTotal?.final_totals?.final_total_cost_price_with_quantity || 0)}
                                </td>
                                <td></td>
                            </tr>
                        </>
                    )}
                    </tbody>
                </table>
        </>
    )
}

export default TransactionReportTable
