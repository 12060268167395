import { FC, useState } from 'react'
import { IProductModel } from '../../cors/_models'
import SpecialSelectionTableRow from './SpecialSelectionTableRow'
import SpecialSelectionSubmit from './SpecialSelectionSubmit'
import { useIntl } from 'react-intl'
interface IProps {
    data: IProductModel[]
}
const SpecialSelectionTable:FC<IProps> = ({data}) => {
  const {formatMessage} = useIntl();
  const [selectedProductIDs, setSelectedProductIDs] = useState<number[]>([])
  const handleRowSelection = (productID:number)=>{
    if (selectedProductIDs.includes(productID)) {
      setSelectedProductIDs(selectedProductIDs.filter((id)=> id !== productID))
    } else {
      setSelectedProductIDs([...selectedProductIDs,productID])
    }
  }
  
  return (
    <>
        {/* begin::Table container */}
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='w-25px'></th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({ id: "image" })}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({ id: "prod_name" })}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({ id: "barcode" })}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({ id: "model_num" })}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({ id: "cost_price" })}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({ id: "sale_price" })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data && (
                data.map((item)=>(
                  <SpecialSelectionTableRow selectedProductIDs={selectedProductIDs} onChange={handleRowSelection} key={item.product_id} item={item} />
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        {/* end::Table container */}
        <SpecialSelectionSubmit products_id={selectedProductIDs} />
    </>
  )
}

export default SpecialSelectionTable