import {useIntl} from 'react-intl'

const DuplicateBarcodeTableHead = () => {
  const {formatMessage} = useIntl()
  return (
    <thead>
      <tr>
        <th className='w-25px'></th>
        <th className={`w-125px fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "barcode" })}</th>
        <th className={`w-125px fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "image" })}</th>
        <th style={{width: '40%'}} className={`fw-bold p-0 text-uppercase`}>{formatMessage({ id: "prod_name" })}</th>
        <th className={`fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "model_num" })}</th>
        <th className={`fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "qty" })}</th>
        <th className={`fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "cost_price" })}</th>
        <th className={`fw-bold p-0 px-2 text-nowrap text-uppercase`}>{formatMessage({ id: "sale_price" })}</th>
      </tr>
    </thead>
  )
}

export default DuplicateBarcodeTableHead
