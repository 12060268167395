import {FC, useState} from 'react'
import { ISuggestedCostModel } from '../cors/_models'
type IProps = {
  item: ISuggestedCostModel
}

const SuggestedCostTableRow: FC<IProps> = ({item}) => {


  return (
    <tr>
      <th>
        <img src={item.barcode} className='w-100px' />
      </th>
      <td>{item.supplier_order_id}</td>
      <td>{item.name}</td>
      <td className='text-nowrap'>{item.barcode}</td>
      <td className='text-nowrap'>{item.model}</td>
      <td className='text-nowrap'>{item.cost}</td>
      <td className='text-nowrap'>{item.suggested_cost}</td>
      <td className='text-nowrap'>{item.date_added}</td>
      <td className='text-nowrap'>{item.status}</td>
    </tr>
  )
}

export default SuggestedCostTableRow
