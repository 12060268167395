import {FC, useEffect, useState} from 'react'
import { PRODUCT_FILTERS, ProductsTableFilterInitialState } from '../../cors/_models'
import TextTableFilter from '../../../common/TextTableFilter'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface IProps {
  
}

export const SpecialSelectionFilters: FC<IProps> = ({}) => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [nameFilter, setNameFilter] = useState('')
  const [barcodeFilter, setBarcodeFilter] = useState('')
  const [modelFilter, setModelFilter] = useState('')
  const [brandFilter, setBrandFilter] = useState(searchParams.get('filter_brand') || '')
  const [qtyFilter, setQtyFilter] = useState(searchParams.get('filter_quantity') || '')

  useEffect(() => {
    filter();
  }, [searchParams]);

  const handleChange = (id: string, value: string, type: string) => {
    if (value) {
      searchParams.set(id, value)
      searchParams.set('current_page', '1')
    } else {
      searchParams.delete(id)
    }
  }
  const filter = (): void => {
    setSearchParams(searchParams,{
      replace:true
    })
  }
  const clearFilters = (): void => {
    setSearchParams({},{
      replace:true
    })
    setNameFilter('')
    setBarcodeFilter('')
    setModelFilter('')
    setBrandFilter('')
    setQtyFilter('')
  }

  return (
      <>
        <div className='card-body border-bottom pb-10 mb-5'>
          <div className='row'>
            <TextTableFilter
                id='filter_name'
                label={formatMessage({ id: "prod_name" })}
                value={nameFilter}
                setValue={setNameFilter}
                onChange={handleChange}
            />
            <TextTableFilter
                id='filter_barcode'
                label={formatMessage({ id: "barcode" })}
                value={barcodeFilter}
                setValue={setBarcodeFilter}
                onChange={handleChange}
            />
            <TextTableFilter
                id='filter_model'
                label={formatMessage({ id: "model" })}
                value={modelFilter}
                setValue={setModelFilter}
                onChange={handleChange}
            />
            <TextTableFilter
                id='filter_brand'
                label={formatMessage({ id: "brand" })}
                value={brandFilter}
                setValue={setBrandFilter}
                onChange={handleChange}
            />
            <TextTableFilter
                id='filter_quantity'
                label={formatMessage({ id: "quantity" })}
                value={qtyFilter}
                setValue={setQtyFilter}
                onChange={handleChange}
            />
            <div className='col-sm-12 col-md-3'>
              <button
                  className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                  onClick={filter}
              >
                {formatMessage({ id: "filter" })}
              </button>
            </div>
            <div className='col-sm-12 col-md-3'>
              <button
                  type='reset'
                  className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                  onClick={clearFilters}
              >
                {formatMessage({ id: "reset" })}
              </button>
            </div>
          </div>
        </div>

      </>
  )
}

export default SpecialSelectionFilters
