export interface ISelectOptions {
  value: number | string
  label: string | number
}
export interface ILengthClassesModel {
  length_class_id: number
  name: string
}
export interface IWeightClassesModel {
  weight_class_id: number
  name: string
}
export interface IColorsModel {
  color_id: number
  image: string
  name: string
  color?: any
}
export interface IRecommendedAgeModel {
  name: string
  recommended_age_id: number
}
export interface ITaxModel {
  name: string
  tax_class_id: number
}
export interface IGenderModel {
  gender_id: number
  name: string
}
export interface IBrandModel {
  manufacturer_id: number
  name: string
}
export interface IProductModel {
  image: string
  name: string
  product_id: number
  barcode: string
  model: string
  supplier_quantity: number
  manufacturer_name: string
  cost_price: string
  price: string
  special_cost: string
  special_sale: string
  discontinued?: number
  product_status: string
  status?: number
  view: boolean | string
  warehouse_quantity:number
  status_id: number
  sold_units: number
  views: number
}

export interface IProductReqModel {
  barcode: string
  brand: string
  cost_price: string
  date_added: string
  image: string
  model: string
  name: string
  price: string
  status: string
  supplier_quantity: string
}

export type IProductTableFilter = {
  current_page: number
  filter_name: string
  filter_barcode: string
  filter_model: string
  filter_status: string
  filter_brand: string
  filter_manufacturer_name: string
}
export enum PRODUCT_REQUEST_TYPE {
  ADD = 'add',
  EDIT = 'edit',
}

export enum PRODUCT_FILTERS {
  NAME = 'filter_name',
  BARCODE = 'filter_barcode',
  MODEL = 'filter_model',
  DATE = 'date_added_filter',
  STATUS = 'filter_status',
  BRAND = 'filter_brand',
}
export enum PRODUCT_REQ_FILTERS {
  NAME = 'product_name_filter',
  BARCODE = 'barcode_filter',
  MODEL = 'model_filter',
  DATE = 'date_added_filter',
  STATUS = 'status_filter',
  BRAND = 'brand_filter',
}

export const ProductsTableFilterInitialState: IProductTableFilter = {
  filter_name: '',
  filter_barcode: '',
  filter_model: '',
  filter_status: '',
  filter_brand: '',
  filter_manufacturer_name: '',
  current_page: 1,
}
export enum DISCOUNT_STATUS_REQ {
  PENDING = 'pending',
  REJECTED = 'rejected',
}
export type IDiscountReqTableFilter = {
  filter_name: string
  filter_barcode: string
  filter_model: string
  filter_date_added: string
  special_status: string | DISCOUNT_STATUS_REQ
  current_page: number
}
export const DiscountREQTableFilterInitialState: IDiscountReqTableFilter = {
  filter_name: '',
  filter_barcode: '',
  filter_model: '',
  filter_date_added: '',
  special_status: '',
  current_page: 1,
}

export enum DISCOUNT_FILTERS {
  NAME = 'filter_name',
  BARCODE = 'filter_barcode',
  MODEL = 'filter_model',
  DATE = 'filter_date_added',
  STATUS = 'special_status',
}

export const enum PRODUCT_ACTIONS {
  INACTIVE_PRODUCT = 'inactivate-product',
  OUT_OF_STOCK = 'out-of-stock',
}

export interface IDuplicateBarcodeModel {
  barcode: string
  cost_price: string
  image: string
  key: string
  model: string
  name: string
  price: string
  product_id: number
  supplier_quantity: number
}

export const ADD_PRODUCT_ERRORS:any = {
  barcode: {
    'partner_product.used_barcode': {
      "en": "this barcode is used",
      "ar": "this barcode is used جرب غيره"
    },
    'partner_portal.required_field': {
      "en": "this barcode is used test",
      "ar": "بالعربي ودغري من الآخر"
    }
  }
}

export interface IDailyDealModel {
  compensation_note: string;
  barcode: string
  supplier_deal_id: number
  product_id: number
  quantity: number
  discount_percentage: number
  new_cost: number
  new_price: number
  discount_date: number
  name: string
  model: string
  image: string
}

export interface IAppliedDailyDealModel {
  product_special_id: number;
  barcode: string
  supplier_deal_id: number
  product_id: number
  quantity: number
  date_start: number
  price: number
  cost_price: number
  date_end: number
  name: string
  bg_color: string
  image: string
}

export const enum DAILY_DEAL_ACTIONS {
  REMOVE_DEAL_PRODUCT = 'remove-special-requests',
}

export interface ILossOfOpportunityModel {
  image: string
  model: string
  removed_quantity: number
  total_cost: number
  date_added: string
  product_name: string
  barcode: string
  user_name: string
  remove_reason: string
  availability_date: string
  po_id: number
  availability_fees: string
}

export const IRemoveReason = {
  out_of_stock: '1',
  partially_available: '10',
}