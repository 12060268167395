import { FC } from 'react'
import DailyDealList from '../../modules/daily-deal/DailyDealList'

const DailyDealListPage:FC = () => {
    // @ts-ignore
    // @ts-ignore

    return (
        <>
            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
                    <DailyDealList />
                </div>
                {/* end::Col */}
            </div>
            {/* begin::Row */}
        </>
    )
}

export default DailyDealListPage