import React, { FC } from 'react';
import {IProductProps} from '../cors/reducers';
import { useIntl } from 'react-intl';
import {setDiscountToInactive} from '../cors/_requests'
import SharedData from "../../common/SharedData";
import '../../../stylesheet/ProductDicount.css'


const ProductDiscountTab: FC<IProductProps> = ({ prodState }) => {
  const { formatMessage } = useIntl();
  const {currentUser, language} = SharedData()

  var special_data = prodState.product_specials;
  var salePrice = Number(prodState.salePrice.value);
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = String(currentDate.getMonth() + 1).padStart(2, '0');
  var day = String(currentDate.getDate()).padStart(2, '0');
  var dayPlusOne = String(currentDate.getDate()+1).padStart(2, '0');
  var formattedDate = `${year}-${month}-${day}`;
  var tomorrowDate = `${year}-${month}-${dayPlusOne}`;


  if (!Array.isArray(special_data)) {
    return null;
  }
  const isDiscountActive = (startDate: string, endDate: string, quantity: number) => {
    if ((quantity == -1 || quantity > 0 ) &&
        ((formattedDate < startDate && endDate > formattedDate) || endDate == '0000-00-00')) {
      return formatMessage({ id: 'active' });
    } else if (formattedDate >= startDate && formattedDate < endDate) {
      if (tomorrowDate === endDate) {
        return formatMessage({ id: 'active_now_cannot_be_deactivated' });
      }
      return formatMessage({ id: 'active_now' });
    } else {
      return formatMessage({ id: 'not_active' });
    }
  };

  const calculateDiscount = (price: number):number => {
    const discountPercentage = Math.floor(((salePrice - price) / salePrice) * 100);
    return discountPercentage;
  };

  const handleButtonClick = async (product_special_id: string) => {

    var token = currentUser?.token;
    try {
      await setDiscountToInactive(token, language, product_special_id);
      window.location.reload();
    } catch (error) {
      console.error('Error setting bulk special:', error);
    }
  };

  return (
      <>
        <div>
          <table className='align-middle border-bottom-1 gs-0 gy-5 table edit-width'>
            <thead>
            <tr>
              <th style={{ padding: '20px' }} className='fw-bold text-uppercase'>{formatMessage({ id: 'percentage' })}</th>
              <th className='fw-bold text-uppercase'>{formatMessage({ id: 'date_start' })}</th>
              <th className='fw-bold text-uppercase'>{formatMessage({ id: 'date_end' })}</th>
              <th className='fw-bold text-uppercase'>{formatMessage({ id: 'status' })}</th>
            </tr>
            </thead>
            <tbody style={{ backgroundColor: 'white', padding: '10px' }}>
            {special_data.map((item: any, index: number) => (
                <tr key={index}>
                  {/*<td>{item.price}</td>*/}
                  <td style={{ padding: '20px' }}>{calculateDiscount(item.price)}</td>
                  <td style={{ padding: '20px' }}>{item.date_start}</td>
                  <td style={{ padding: '20px' }}>{item.date_end}</td>
                  <td style={{ padding: '20px' }}>
                    {isDiscountActive(item.date_start, item.date_end, item.quantity) === formatMessage({ id: 'not_active' })
                    || isDiscountActive(item.date_start, item.date_end, item.quantity) === formatMessage({ id: 'active_now_cannot_be_deactivated' }) ? (
                        <span style={{ color: 'black', fontWeight: 'bold' }}>{isDiscountActive(item.date_start, item.date_end, item.quantity)}</span>

                    ) : (
                        <button
                            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '5px' }}
                            onClick={() => handleButtonClick(item.product_special_id)}>
                          {isDiscountActive(item.date_start, item.date_end, item.quantity)}
                        </button>
                    )}
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </>
  );
};

export default ProductDiscountTab;
