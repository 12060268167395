import React, { ReactNode, useEffect, useState } from 'react'
import { IStatementReportModel } from './core/_models'
import NormalMessage from '../common/NormalMessage'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import SharedData from '../common/SharedData'
import StatementReportTable from './statement/StatementReportTable'
import { prepareFilters } from '../orders/core/_requests'
import { getStatementReports } from './core/_requests'

type Props = {
    className?: string
}

const StatementReports: React.FC<Props> = ({ className }) => {
    const { formatMessage } = useIntl()
    const [searchParams, setSearchParams] = useSearchParams()

    const { currentUser, language } = SharedData()
    const [dataList, setDataList] = useState<IStatementReportModel[]>([])

    useEffect(() => {
        prepareFilters(searchParams, setSearchParams)

        const fetchData = async () => {
            try {

                const res = await getStatementReports(currentUser?.token, language)
                setDataList(res.data.supplier_statements_list || [])

            } catch (error: any) {
                console.error('Error fetching statement reports:', error)
            }
        }

        fetchData()
    }, [searchParams, currentUser?.token, language, formatMessage, setSearchParams])

    const renderResults = (): ReactNode => {

        if (dataList.length > 0) {
            return (
                <div className='card-body py-3'>
                    <StatementReportTable data={dataList} />
                </div>
            )
        }

        return <NormalMessage msg={formatMessage({ id: 'coming_soon' })} />
       // return <NormalMessage msg={formatMessage({ id: 'no_data' })} />
    }

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {formatMessage({ id: 'statement_report' })}
          </span>
                </h3>
            </div>
            {renderResults()}
        </div>
    )
}

export { StatementReports }
