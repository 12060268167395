import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import {getDailySales} from '../core/_requests'
import { IDailySalesDataBody } from '../core/_models'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

type Props = {
  className: string
  language: string
  currentUser: UserModel | undefined
}

function getDateEnd() {
    const date = new Date();
    const formatted_Date = date.toLocaleDateString('en-US', {day: '2-digit' , month: '2-digit', year: 'numeric'});
    const [monthValue, dayValue, yearValue] = formatted_Date.split('/');
    return `${yearValue}-${monthValue}-${dayValue}`;
}

function getDateStart() {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const formatted_Date = date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});
    const [monthValue, dayValue, yearValue] = formatted_Date.split('/');
    return `${yearValue}-${monthValue}-${dayValue}`;
}

const DailySales: React.FC<Props> = ({language, currentUser, className}) => {
  const {formatMessage} = useIntl();
    const [state, setState] = useState<IDailySalesDataBody>({
        total_quantity:0,
        total_cost_price:0
    })
    
useEffect(() => {
    const getData = async () => {
      const {data} = await getDailySales(currentUser?.token, language)
      if (data) {
        setState({
            total_quantity:data.data.total_quantity,
            total_cost_price:data.data.total_cost_price
        })
      }
    }
    getData()
  }, [])

  return (
    <div className={`card ${className}`} style={{ backgroundColor:"#DC3545", color:"white"}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-2 h-100'>
        {/* begin::Daily sales */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='text-gray-100 card-label fw-bold fs-2hx mb-1'>{state.total_cost_price} {formatMessage({ id: "JD" })}</span>
          <span className='text-gray-100'>{formatMessage({ id: "daily_sales" })}</span>
        </h3>
        {/* end::Daily sales */}

        {/* begin::Daily sales */}
        <h3 className='card-title align-items-start flex-column w-100'>
          <span className='text-gray-100 card-label fw-bold fs-2hx mb-1'>{state.total_quantity}</span>
          <span className='text-gray-100'>{formatMessage({ id: "daily_sold_prod" })}</span>
        </h3>
        {/* end::Daily sales */}
        <Link
          to={`/sales/sales-report?filter_date_start=${getDateStart()}&filter_date_end=${getDateEnd()}`}
          className='pb-2 w-100 fs-4 border-0 d-flex justify-content-end align-items-center text-gray-100 fw-bold'
        >
          {formatMessage({ id: "sales_report" })} {'>'}
        </Link>
      </div>
      {/* end::Header */}
    </div>
  )
}

export default DailySales
