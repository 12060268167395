import { ReactNode, useEffect, useState } from 'react'
import SharedData from '../../common/SharedData'
import RequestPagesHeader from '../components/RequestPagesHeader'
import { ISuggestedCostModel, ISuggestedCostTableFilter, SuggestedCostTableFilterInitialState } from '../cors/_models'
import { getSuggestedCostRequests } from '../cors/_requests'
import Loader from '../../common/Loader'
import SuggestedCostTable from './SuggestedCostTable'
import NormalMessage from '../../common/NormalMessage'
import SuggestedCostFilters from './SuggestedCostFilters'
import { useIntl } from 'react-intl'

const SuggestedCost = () => {
  const {formatMessage} = useIntl()
  const {currentUser, language} = SharedData()
  const [requestList, setRequestList] = useState<ISuggestedCostModel[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [resultFilters, setResultFilters] = useState<ISuggestedCostTableFilter>(
    SuggestedCostTableFilterInitialState
  )
  const [canFilter, setCanFilter] = useState(true)
//   const [totalResult, setTotalResult] = useState<number>(0)
  const [errorMsg, setErrorMsg] = useState(formatMessage({id: 'something_wrong_title'}))

  useEffect(() => {
    getDate()
  }, [resultFilters])

  const getDate = async () => {
    setIsLoading(true)
    try {
      const data = await getSuggestedCostRequests(resultFilters, currentUser?.token, language)
      if (data) {
        setRequestList(data.data.cost_request)
        // setTotalResult(data.data.data.total_products)
      } else {
        setRequestList([])
        setErrorMsg(formatMessage({id: 'no_data'}))
      }
    } catch (exp) {
      setErrorMsg(formatMessage({id: 'something_wrong_title'}))
    }
    setIsLoading(false)
  }

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (requestList.length > 0) {
      return (
        <div className='card-body py-3'>
          <SuggestedCostTable data={requestList} />
          {/* <Paginator
            setCurrentPage={setResultFilters}
            currentPage={resultFilters.current_page}
            totalResult={totalResult}
          /> */}
        </div>
      )
    }
    return <NormalMessage msg={errorMsg} />
  }

  return (
    <>
      {/* <RequestPagesHeader /> */}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({id: 'prods_list'})}</span>
          </h3>
          {/* begin::Menu */}
          <div className='card-toolbar'>
          <button
              type='button'
              className={`${
                canFilter && 'active'
              } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
              onClick={() => setCanFilter(!canFilter)}
            >
              {formatMessage({id: 'filter'})} <i className="fas fa-filter"></i>
            </button>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {canFilter && ( // to do
          <SuggestedCostFilters resultFilters={resultFilters} setResultFilters={setResultFilters} />
        )}

        {resultsData()}
      </div>
    </>
  )
}

export default SuggestedCost
