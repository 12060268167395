import React, {FC, useState, useEffect, useReducer} from 'react'
import {fieldReducer, IProductProps, productActionType, productInitialState} from '../../cors/reducers'
import InputField from '../../components/InputField'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {checkDuplicateBarcode} from "../../cors/_requests";
import SharedData from "../../../common/SharedData";

type CustomProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
type IProps = IProductProps & CustomProps

const BarcodeField: FC<IProps> = ({prodState, prodDispatch, handleChange}) => {
  const {formatMessage} = useIntl()
  const {productID} = useParams()
  const [isReadOnly, setIsReadOnly] = useState(typeof productID === 'string' || false)
  const {currentUser,language} = SharedData()
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const generateBarcode = () => {
    const product_type = '1'
    const currDate = (Math.floor(Date.now() / 100) % Math.pow(10, 10)).toString()
    var checksum = calculateChecksum(product_type + currDate)
    var barcode = product_type + currDate + checksum
    var payload = {id: 'barcode', value: barcode}
    prodDispatch({type: productActionType.text, payload});
    prodDispatch({type: productActionType.barcode_generated, payload})

    setIsReadOnly(true)
  }
  const handleChangeBarcode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    
    handleChange(e);
    validateBarcodeProduct(e.target.value);
  };
  const validateBarcodeProduct = async (barcode: string) => {
    setErrorMessage(null);
    try {
      if (barcode.trim() !== "") {
        const result = await checkDuplicateBarcode(barcode, currentUser?.token, language);
        if (result.data == '1' && barcode !== "") {
          setErrorMessage(formatMessage({ id: 'barcode_exists_message' }));
        } else {
          setErrorMessage(null);
        }
      }
    } catch (error) {
      setErrorMessage(formatMessage({ id: 'barcode_exists_message' }));
    }
  };
  function calculateChecksum(digits: string, barcode_type: string = 'UPCA'): number {
    const odd_sum: number =
      parseInt(digits[0]) +
      parseInt(digits[2]) +
      parseInt(digits[4]) +
      parseInt(digits[6]) +
      parseInt(digits[8]) +
      parseInt(digits[10])
    const even_sum: number =
      parseInt(digits[1]) +
      parseInt(digits[3]) +
      parseInt(digits[5]) +
      parseInt(digits[7]) +
      parseInt(digits[9])

    let total_sum: number

    if (barcode_type === 'UPCA') {
      total_sum = odd_sum * 3 + even_sum
    } else {
      total_sum = odd_sum + even_sum * 3
      total_sum += parseInt(digits[11]) * 3
    }

    const mod_10: number = total_sum % 10

    if (mod_10 === 0) {
      return 0
    }

    return 10 - mod_10
  }

  return (
      <div className='barcode-box d-flex flex-column gap-1' style={{ marginBottom: '10px' }}>
        <div className='d-flex gap-1' style={{ position: 'relative' }}>
          <InputField
              hasError={prodState.barcode.error}
              value={prodState.barcode.value}
              onChange={handleChangeBarcode}
              id='barcode'
              label={formatMessage({ id: 'barcode' })}
              isRequired={true}
              readOnly={isReadOnly === true}
              errorMsg={prodState.barcode.msg}
          />

          {typeof productID !== 'string' && (
              <>
                <label className='form-label fw-bold opacity-0'>---</label>
                <button
                    disabled={isReadOnly}
                    onClick={generateBarcode}
                    className='btn btn-sm fs-5 px-3 btn-light btn-close-white generate-barcode-btn'
                    style={{ alignSelf: 'flex-end' }}
                >
                  {formatMessage({ id: 'generate' })}
                </button>
              </>
          )}
        </div>

        {errorMessage && (
            <div style={{ color: 'red', marginTop: '8px' }}>
              {errorMessage}
            </div>
        )}
      </div>
  );
}

export default BarcodeField
