import React, {FC, useState} from 'react'
import AlertMessage from '../../../common/alert/AlertMsg'
import SharedData from '../../../common/SharedData'
import {bulkAction, getDuplicateBarcodes} from '../core/_requests'
import {BulkActions} from '../core/_models'
import Loader from '../../../common/Loader'
import { useNavigate } from 'react-router-dom'
import fileDownload from 'js-file-download'
import { useIntl } from 'react-intl'
type IProps = {
  bulkFile: any
  setBulkFile: React.Dispatch<any>
}
const EditSubmitButtonRow: FC<IProps> = ({bulkFile, setBulkFile}) => {
  const {formatMessage} = useIntl();
  const {currentUser,language} = SharedData();
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const isFormHasError = () => {
    let hasError = false
    setBulkFile({
      ...bulkFile,
      error: false,
    })
    if (!bulkFile.value) {
      setBulkFile({
        ...bulkFile,
        error: true,
      })
      hasError = true
    }
    return hasError
  }
  const handleSubmit = async () => {
    if (!isFormHasError()) {
      setIsLoading(true)
      try {
        const data = await bulkAction(currentUser?.token, {
          action: BulkActions.EDIT,
          sheet: bulkFile.value,
        });
        if (data) {
          
          fileDownload(data.data, `${currentUser?.name}_${new Date().toISOString().slice(0,10)}.xlsx`);
          const resp = await getDuplicateBarcodes(currentUser?.token,language);
          
          if (resp && resp.data && resp.data.requests && resp.data.requests.length > 0) {
            navigate('/products/duplicate-barcodes?from='+document.location.pathname);
          } else {
            localStorage.setItem('thankYouPageURL', window.location.href);
            navigate('/thank-you?go=request-update-products');
          }
        } else {
          setError(true)
        }
      } catch (exp) {
        setError(true)
      }
      setIsLoading(false)
    } else {
    }
  }

  return (
    <>
      <div className='mt-3 d-flex justify-content-end align-items-center gap-2 flex-column-p'>
        <p className='mb-0 w-100-p'>{formatMessage({ id: "by_uploading_products_stnrd" })}</p>
        <button
          onClick={handleSubmit}
          className='btn btn-sm fs-4 py-1 px-9 btn-light  btn-close-white'
        >
          {formatMessage({ id: "upload" })}
        </button>
      </div>
      {isLoading && <Loader isFull={true} />}
      {error === true && (
        <AlertMessage
          title={formatMessage({ id: "something_wrong_title" })}
          message={formatMessage({ id: "something_wrong_desc" })}
          onClose={() => setError(false)}
        />
      )}
    </>
  )
}

export default EditSubmitButtonRow
