import { FC } from 'react'

interface IProps  {
  isEnglish: boolean,
  setIsEnglish: React.Dispatch<React.SetStateAction<boolean>>
}
const ProductGeneralFormLangs:FC<IProps> = ({isEnglish,setIsEnglish}) => {
  
  return (
    <ul className='mb-7 nav nav-tabs'>
      <li className='nav-item'>
        <button onClick={()=> setIsEnglish(true)} type='button' className={`nav-link ${isEnglish && 'active'}`}>
          English
        </button>
      </li>
      <li className='nav-item'>
        <button onClick={()=> setIsEnglish(false)} type='button' className={`nav-link ${!isEnglish && 'active'}`}>
          العربية
        </button>
      </li>
    </ul>
  )
}

export default ProductGeneralFormLangs
