import {FC, useRef} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface IProps {
  field: any
  setFields: any
  fieldName: string
  type?: string
  label: string
  saveField:(fieldName:string,params?:any)=>void
}

const MyProfileField: FC<IProps> = ({saveField,field, setFields, fieldName, label, type}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const editField = (fieldID: string) => {
    setFields((prev: any) => ({
      ...prev,
      [fieldID]: {...prev[fieldID], edit: !prev[fieldID].edit},
    }))
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const {id} = e.currentTarget
    const {value} = e.target;
    setFields((prev: any) => ({
      ...prev,
      [id]: {...prev[id], value: value},
    }))
  }

  const handleBlur = (e: React.ChangeEvent<any>) => {
    const {id} = e.currentTarget
    const {value} = e.target
    editField(id)
    saveField(fieldName)
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <label className='text-gray-600 fw-bold fs-5'>{label}:</label>
      </div>
      <div className='col-md-6 d-flex'>
        {!field.edit ? (
          <div className='d-flex gap-1 flex-wrap'>
            <p className='text-dark fs-4 mb-0'>{field.value}</p>
            <div className='cursor-pointer w-25px' onClick={() => editField(fieldName)}>
              <img alt='edit' width='15' src={toAbsoluteUrl('/media/logos/pen-solid.svg')} />
            </div>
            {field.changed && (
              <p className='mb-0 text-success fw-bold'>Your change has been saved</p>
            )}
          </div>
        ) : (
          <input
            className='form-control border-gray-900 text-gray-900'
            type={type || 'text'}
            id={fieldName}
            value={field.value}
            onBlur={handleBlur}
            ref={inputRef}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  )
}

export default MyProfileField
