import { ISuggestedCostModel } from './_models';
import axios from 'axios'
import { prepareFilterParams } from '../../orders/core/_requests'

const API_URL = process.env.REACT_APP_API_URL
export const GET_SUGGESTED_COST_LIST = `${API_URL}/api/partner-portal/po/cost-request`

// GET PRODUCTS LIST
export function getSuggestedCostRequests(filtersObj: any, token: string | undefined, language?: string) {
  return axios.get<{cost_request: ISuggestedCostModel[]}>(
    `${GET_SUGGESTED_COST_LIST}?${prepareFilterParams(filtersObj)}`,
    {headers: {token: token || '', language: language || 'en'}}
  )
}
