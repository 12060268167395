import {FC} from 'react'
import { IProductReqModel } from '../../products/cors/_models'
type IProps = {
  item: IProductReqModel
}

const AddProductReqTableRow: FC<IProps> = ({item}) => {


  return (
    <tr>
      <th className='w-100px'>
        <img src={item.image} className='table-img' />
      </th>
      <td className='w-200px min-w-200px'>{item.name}</td>
      <td>{item.barcode}</td>
      <td className='text-nowrap'>{item.model}</td>
      <td className='text-nowrap'>{item.supplier_quantity}</td>
      <td className='text-nowrap'>{item.cost_price}</td>
      <td className='text-nowrap'>{item.price}</td>
      <td className='text-nowrap'>{item.brand}</td>
      <td className='text-nowrap'>{item.date_added}</td>
      <td className='text-nowrap'>{item.status}</td>
    </tr>
  )
}

export default AddProductReqTableRow
