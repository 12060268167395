import React, {ReactNode, useEffect, useState} from 'react'
import {IInTransitReportModel, ITotalInTransitRow} from './core/_models'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import {useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import SharedData from '../common/SharedData'
import InTransitReportsTable from './in_transit/InTransitReportsTable'
import InTransitReportsFilters from './in_transit/InTransitReportsFilters'


import {prepareFilters} from '../orders/core/_requests'
import {getInTransitReports} from './core/_requests'

type Props = {
    className?: string
}

const InTransitReports: React.FC<Props> = ({className}) => {
    const {formatMessage} = useIntl()
    const [searchParams, setSearchParams] = useSearchParams()

    const {currentUser, language} = SharedData()
    const [dataList, setDataList] = useState<IInTransitReportModel[]>([])
    const [inTransitTotal, setInTransitTotal] = useState<ITotalInTransitRow | null>(null)

    const [isLoading, setIsLoading] = useState(true)
    const [canFilter, setCanFilter] = useState(true)

    useEffect(() => {
        prepareFilters(searchParams, setSearchParams)

        const fetchData = async () => {
            try {
                setIsLoading(true)
                const queryParams = document.location.search
                const res = await getInTransitReports(queryParams, currentUser?.token, language)
                if (res) {
                    const data = res.data.data;
                    setDataList(data.in_transit_products)
                    setInTransitTotal(data.total_sales)
                }
            } catch (exp: any) {
                console.log(exp)
            }
            setIsLoading(false)
        }
        fetchData()
    }, [searchParams])

    const renderResults = (): ReactNode => {
        if (isLoading) {
            return <Loader/>
        }

        if (dataList.length > 0) {
            return (
                <div className='card-body py-3'>
                    <InTransitReportsTable data={dataList} inTransitTotal={inTransitTotal}/>
                </div>
            )
        }

        return <NormalMessage msg={formatMessage({id: "no_data"})}/>
    }

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
            </div>
            {<InTransitReportsFilters/>}
            {renderResults()}
        </div>
    )
}

export {InTransitReports}
