import React, {ChangeEvent, ChangeEventHandler, FC, useRef} from 'react'
import {IDuplicateBarcodeModel} from '../cors/_models'

type IProps = {
  item: IDuplicateBarcodeModel
  onChange: any
  isNewBarcode?: boolean
}
const DuplicateBarcodeTableRow: FC<IProps> = ({item, onChange, isNewBarcode}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(item.barcode, item.key, e.target.checked)
  }

  return (
    <tr className={`${isNewBarcode ? 'border-top border-dashed border-dark' : ''}`}>
      <td>
        <input
          ref={inputRef}
          onChange={handleChange}
          className='form-check-input'
          type='checkbox'
          value=''
          id='flexCheckDefault'
        />
      </td>
      <td>{item.barcode}</td>
      <th>
        <img width='100' height='100' src={item.image} />
      </th>
      <td>{item.name}</td>
      <td>{item.model}</td>
      <td>{item.supplier_quantity}</td>
      <td>{item.cost_price}</td>
      <td>{item.price}</td>
    </tr>
  )
}

export default DuplicateBarcodeTableRow
