import React, { FC, useState, useEffect } from 'react'
import InputField from '../../components/InputField'
import CkeditorField from '../../../common/CkeditorField'
import {IProductProps, productActionType} from '../../cors/reducers'
import ProductGeneralFormLangs from './ProductGeneralFormLangs'
import { useIntl } from 'react-intl'
import BarcodeField from './BarcodeField'
import { getCategoryAutoComplete } from "../../cors/_requests";
import SharedData from "../../../common/SharedData";
import debounce from 'lodash/debounce';

const ProductGeneralForm: FC<IProductProps> = ({ prodState, prodDispatch }) => {
  const { formatMessage } = useIntl();
  const [isEnglish, setIsEnglish] = useState<boolean>(true);
  const { currentUser, language } = SharedData();
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    if (prodState.categoryName.value) {
      const formattedNames = formatCategoryNames(prodState.categoryName.value);
      prodDispatch({ type: productActionType.text, payload: { id: 'categoryName', value: formattedNames } });
    }
  }, [prodState.categoryName.value, prodDispatch]);

  const formatCategoryNames = (names: string) => {
    let formattedNames = names.replace(/(?<!->)-/g, " ");
    formattedNames = formattedNames.replace(/\b\w/g, function (match: string) {
      return match.toUpperCase();
    });
    formattedNames = formattedNames.replace("&Amp;", "&");
    return formattedNames;
  };

  const handleChangeArabic = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    var arabic = RegExp("^[\u0621-\u064A\u0660-\u0669 0-9,-=+'\"×!@#\،$.x%\^\&*\ ]+\$");
    if (!arabic.test(value) && value !== '') {
      return false;
    }
    prodDispatch({ type: productActionType.text, payload: { id, value } });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    prodDispatch({ type: productActionType.text, payload: { id, value } });
    debouncedFetchCategory(value);
  };

  const debouncedFetchCategory = debounce(async (value: string) => {
    try {
      const resp: any = await getCategoryAutoComplete(currentUser?.token, language, value);
      if (resp && resp.data && resp.data.message) {
        const sanitizedOptions = resp.data.message.map((option: any) => ({
          ...option,
          name: formatCategoryNames(option.name)
        }));
        setDropdownOptions(sanitizedOptions);
      } else {
        setDropdownOptions([]);
      }
    } catch (error) {
      console.error('Error fetching category autocomplete:', error);
      setDropdownOptions([]);
    }
  }, 300);

  const handleOptionClick = (option: any) => {
    prodDispatch({ type: productActionType.text, payload: { id: 'categoryName', value: option.name } });
    prodDispatch({ type: productActionType.text, payload: { id: 'categoryID', value: option.category_id } });
    setDropdownOptions([]);
  };

  return (
      <div className='card default-image-box'>
        <div className='card-body'>
          <BarcodeField prodState={prodState} prodDispatch={prodDispatch} handleChange={handleChange} />
          <div style={{ position: 'relative', marginBottom:'10px' }}>
            <InputField
                hasError={prodState.categoryName.error}
                value={prodState.categoryName.value}
                errorMsg={prodState.categoryName.msg}
                isRequired={true}
                onChange={handleChange}
                label={formatMessage({ id: 'category_name' })}
                id='categoryName'
            />
            {dropdownOptions.length > 0 && (
                <ul style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  maxHeight: '150px',
                  overflowY: 'auto',
                  border: '1px solid #ccc',
                  backgroundColor: '#fff',
                  zIndex: 1000,
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0
                }}>
                  {dropdownOptions.map((option: any, index: number) => (
                      <li key={index} style={{ padding: '8px', cursor: 'pointer' }} onClick={() => handleOptionClick(option)}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--kt-primary-active)'}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                      >
                        {option.name}
                      </li>
                  ))}
                </ul>
            )}
          </div>

        <ProductGeneralFormLangs setIsEnglish={setIsEnglish} isEnglish={isEnglish} />

        <div className={!isEnglish ? 'd-none' : ''}>
          <p className='fs-3 fw-bold'>{formatMessage({id: 'general'})}</p>

          <div className='d-flex flex-column gap-9'>
            <div>
              <InputField
                hasError={prodState.productNameEn.error}
                value={prodState.productNameEn.value}
                errorMsg={prodState.productNameEn.msg}
                isRequired={true}
                onChange={handleChange}
                label={formatMessage({id: 'prod_name'})} 
                id='productNameEn'
              />

              {!isEnglish ? <p className="text-gray-600 mb-0 fw-bold">{formatMessage({id: 'brand_prod_hint_ar'})}</p>
                  : <p className="text-gray-600 mb-0 fw-bold">{formatMessage({id: 'brand_prod_hint_en'})}</p>}

            </div>

            <CkeditorField
              id='aboutEn'
              label={formatMessage({id: 'about_prod'})}
              hint={formatMessage({id: 'about_prod_hint'})}
              prodDispatch={prodDispatch}
              prodState={prodState}
              value={prodState.aboutEn.value}
              hasError={prodState.aboutEn.error}
              errorMsg={prodState.aboutEn.msg}
            />
            <CkeditorField
              id='productDescriptionEn'
              label={formatMessage({id: 'desc'})}
              prodDispatch={prodDispatch}
              prodState={prodState}
              value={prodState.productDescriptionEn.value}
              hasError={prodState.productDescriptionEn.error}
              errorMsg={prodState.productDescriptionEn.msg}
            />
          </div>
        </div>
        <div className={isEnglish ? 'd-none' : ''}>
          <p className='fs-3 fw-bold'>{formatMessage({id: 'general'})}</p>

          <div className='d-flex flex-column gap-9'>
            <div >
            <InputField
              hasError={prodState.productNameAr.error}
              errorMsg={prodState.productNameAr.msg}
              value={prodState.productNameAr.value}
              hint={formatMessage({id: 'prod_ar_hint'})}
              isRequired={true}
              label={formatMessage({id: 'prod_name'})}
              id='productNameAr'
              onChange={handleChangeArabic}
            />

              {!isEnglish ? <p className="text-gray-600 mb-0 fw-bold">{formatMessage({id: 'brand_prod_hint_ar'})}</p>
                  : <p className="text-gray-600 mb-0 fw-bold">{formatMessage({id: 'brand_prod_hint_en'})}</p>}

            </div>


            <CkeditorField
              id='aboutAr'
              label={formatMessage({id: 'about_prod'})}
              hint={formatMessage({id: 'about_prod_hint'})}
              prodDispatch={prodDispatch}
              prodState={prodState}
              value={prodState.aboutAr.value}
              hasError={prodState.aboutAr.error}
              errorMsg={prodState.aboutAr.msg}
            />
            <CkeditorField
              id='productDescriptionAr'
              prodDispatch={prodDispatch}
              prodState={prodState}
              label={formatMessage({id: 'desc'})}
              value={prodState.productDescriptionAr.value}
              hasError={prodState.productDescriptionAr.error}
              errorMsg={prodState.productDescriptionAr.msg}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductGeneralForm
