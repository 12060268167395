import { FC } from 'react'
import { useIntl } from 'react-intl'
import StatementReportTableRow from './StatementReportTableRow'
import { IStatementReportModel } from '../core/_models'

interface IProps {
    data: IStatementReportModel[]
}

const StatementReportTable: FC<IProps> = ({ data }) => {
    const { formatMessage } = useIntl()

    return (
        <>
            <table className='table align-middle gs-0 gy-5'>
                <thead>
                <tr>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: 'statement_period' })}</th>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: 'beginning_balance' })}</th>
                    <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({ id: 'total_sales' })}</th>
                    <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({ id: 'total_refund' })}</th>
                    <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({ id: 'payout_amount' })}</th>
                    <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({ id: 'user_name' })}</th>
                </tr>
                </thead>
                <tbody>
                {data &&
                    data.map((item) => (
                        <StatementReportTableRow key={item.statement_id} item={item} />
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default StatementReportTable
