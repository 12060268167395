import { FC, useEffect } from 'react'
import ToggleTabsNav from '../../common/ToggleTabsNav'
import { RequestPagesTabs } from '../cors/_models'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

interface IProps {
  totalResult:{pending:number,rejected:number},
  statusFilterID:string
}

const RequestPagesHeader:FC<IProps> = ({totalResult,statusFilterID}) => {
  const {formatMessage} = useIntl()

  const [search,setSearch] = useSearchParams();

  const handleChange = (status:string)=> {
    search.set(statusFilterID,status)
    search.set('hide_status','true')
    setSearch(search)
  }

  useEffect(()=>{
    
  },[search])

  return (
    <>
      <div className='d-flex justify-content-between align-items-center w-100'>
        <ToggleTabsNav tabs={RequestPagesTabs} />
        <div className='d-flex gap-2'>
          <button onClick={()=>handleChange('pending')} className={`btn ${search.get('hide_status') === 'true' && search.get(statusFilterID) === 'pending' ? 'btn-primary' : 'btn-secondary'}`}>{formatMessage({id: 'pending'})} ({totalResult.pending})</button>
          <button onClick={()=>handleChange('rejected')} className={`btn ${search.get('hide_status') === 'true' && search.get(statusFilterID) === 'rejected' ? 'btn-primary' : 'btn-secondary'}`}>{formatMessage({id: 'rejected'})} ({totalResult.rejected})</button>
        </div>
      </div>
    </>
  )
}

export default RequestPagesHeader
