import React, {FC} from 'react'
import {IProductProps, productActionType} from '../../cors/reducers'
import InputField from '../../components/InputField'
import {useIntl} from 'react-intl'
import BarcodeField from './BarcodeField'

const BarcodeCompany: FC<IProductProps> = ({prodState, prodDispatch}) => {
  const {formatMessage} = useIntl()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    const payload: any = {id, value}
    prodDispatch({type: productActionType.text, payload})
  }

  return (
    <div className='card default-image-box'>
      <div className='card-body'>
        <div className='d-flex flex-column gap-9'>
          <InputField
            hasError={prodState.model.error}
            value={prodState.model.value}
            onChange={handleChange}
            id='model'
            label={formatMessage({id: 'model_number'})}
            isRequired={true}
          />
          <InputField
            value={prodState.companyCode}
            onChange={handleChange}
            id='companyCode'
            label={formatMessage({id: 'company_code'})}
          />
        </div>
      </div>
    </div>
  )
}

export default BarcodeCompany
