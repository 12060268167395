import {FC, useRef} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface IProps {
  label: string
  value: any
}

const MyProfileFieldText: FC<IProps> = ({label, value}) => {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <label className='text-gray-600 fw-bold fs-5'>{label}</label>
      </div>
      <div className='col-md-6'>
        <p className='text-dark fs-4 mb-0 fw-bold'>{value}</p>
      </div>
    </div>
  )
}

export default MyProfileFieldText
