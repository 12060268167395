import {FC, useState} from 'react'
import SharedData from '../../../common/SharedData'
import {deleteRequest} from '../core/_requests'
import {useNavigate, useSearchParams} from 'react-router-dom'
import CustomModal from '../../../common/CustomModal'
import {Button} from 'react-bootstrap'
import { useIntl } from 'react-intl';

interface IProps {
  from?: string
  isProductBulk?:boolean
}

const DeleteRequestBtn: FC<IProps> = ({from,isProductBulk = false}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate()
  const {currentUser, language} = SharedData()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [search, setSearch] = useSearchParams()
  
  const handleRemove = () => {
    deleteRequest(currentUser?.token, language,isProductBulk)
    
    if (from) {
      navigate(`/${from}`, {
        replace: true,
      })
    }
    const prevPage = search.get('from')
    
    if (prevPage) {
      navigate(prevPage, {
        replace: true,
      })
    } else navigate(-1)
  }
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <div>
      <button className='btn btn-danger' onClick={toggleModal}>
        {formatMessage({ id: "delete_req" })}
      </button>
      <CustomModal
        eventSlot={
          <Button variant='danger' onClick={handleRemove}>
            {formatMessage({ id: "delete" })}
          </Button>
        }
        title={formatMessage({ id: "delete_the_req" })}
        show={isModalOpen}
        onCloseModal={toggleModal}
      >
        <p>{formatMessage({ id: "delete_the_req_confirm" })}</p>
      </CustomModal>
    </div>
  )
}

export default DeleteRequestBtn
