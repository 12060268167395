import { FC } from "react"
import ReactDOM from 'react-dom'
type IProps = {
  isFull?:boolean
}

const Loader:FC<IProps> = ({isFull}) => {
  if (isFull) {
    return ReactDOM.createPortal(<>
    <div className="d-flex justify-content-center z-1000 pt-10 pb-10 position-fixed z-index-3 w-100 h-100 top-0 d-flex justify-content-center align-items-center bg-white left-0 bg-opacity-75">
      <span className='align-middle ms-2 spinner-grow text-danger'></span>
    </div>
    </>,document.getElementById('root-modals')!)
  }

  return (
    <div className="d-flex justify-content-center pt-10 pb-10">
      <span className='align-middle ms-2 spinner-grow text-danger'></span>
    </div>
  )
}

export default Loader