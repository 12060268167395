import {FC} from 'react'
import {StatementReports} from '../../modules/reports/StatementReports'

const StatementReportsPage: FC = () => {
    return (
        <>
            <StatementReports/>
        </>
    )
}

export default StatementReportsPage
