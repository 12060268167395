export interface PO {
  GRN_number: string
  date_added: string
  invoice_number: string
  quantity: number
  status: string
  supplier_order_id: number
  total_cost: number
  type: string
  received_quantity?: string
}

export type IPOTableFilter = {
  status_filter: string
  type_filter: string
  GRN_filter: string
  invoice_id_filter: string
  supplier_order_id_filter: string
  date_added_filter: string
  current_page: number | string
  month_filter?: number | string | null
}
export const POTableFilterInitialState: IPOTableFilter = {
  status_filter: '',
  type_filter: '',
  GRN_filter: '',
  invoice_id_filter: '',
  supplier_order_id_filter: '',
  date_added_filter: '',
  month_filter: '',
  current_page: '',
}

export type IPOInfoModel = {
  item_id: number
  image: string
  status: string
  barcode: string
  model: string
  name: string
  quantity: number
  received_quantity: number
  cost: number
  price: number
  total_cost: number
}

export const IPOTypes = {
  po: 'po',
  return: 'return',
}

export type IPOInfoFilter = {
  supplier_order_id: string
  type?: string
  current_page?: number
  month_filter?: string | number | undefined
}

export const IPOInfoFilterInitialState: IPOInfoFilter = {
  supplier_order_id: '',
  type: '',
  current_page: 1,
  month_filter: '',
}

export interface IPaymentDetailsRow {
  breakdown: any[]
  total_debit: string
  total_credit: string
  sub_total: string
  supplier_id: number
  total: string
}

export interface IGRNInvoicesModel {
  GRN: {
    supplier_order_info: {
      order_product_id: number
      date_added: string
      supplier_name: string
      GRN_number: string
      is_returned_type: number
    }
    supplier_order_item_list: {
      barcode: string
      cost: number
      cost_excluding_tax: number
      model: string
      name: string
      received_quantity: number
      tax_type: string
      total_cost: number
      total_cost_excluding_tax: number
    }[]
    supplier_order_total: {
      total_received_quantity: number
      total_cost_excluding_tax: number
      total_tax: string
      total_cost: number
    }
    dumyah_logo: string
    tax_list: {text: string; val: number}[]
    payment_type: string
  }
  invoices: {invoice_image: string}[]
}

export interface MonthlyGRNDetailsModel {
  date_added: string
  po_id: number
  type: string
  GRN_number: string
  debit: string
  credit: number
  balance: number
  total_cost: number
  supplier_order_id: number
}