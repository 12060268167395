import { FC } from "react"

interface IProps {
    label: string,
    value: any
}
const GrnRow:FC<IProps> = ({label,value}) => {
  return (
    <div className='d-flex align-center'>
        <div className='col-4'>{label}</div>
        <span>{value}</span>
    </div>
  )
}

export default GrnRow