import { FC } from 'react'
import { PO } from '../../core/_models'
import PoTableRow from './PoTableRow'
import { useIntl } from 'react-intl'
interface IProps {
    orders: PO[]
}
const PurchaceReturnOrdersTable:FC<IProps> = ({orders}) => {
  const {formatMessage} = useIntl();
  return (
    <>
        {/* begin::Table container */}
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "por_id" })}</th>
                {/* <th className='fw-bold text-nowrap px-md-0 text-uppercase'>INVOICE #</th> */}
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "grn_num" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "date_added" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "type" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "status" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "qty" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "received_qty" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase'>{formatMessage({ id: "total_cost" })}</th>
                <th className='fw-bold text-nowrap px-md-0 text-uppercase text-center'>{formatMessage({ id: "actions" })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {orders && (
                orders.map((order)=>(
                  <PoTableRow key={order.supplier_order_id} order={order} />
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        {/* end::Table container */}
    </>
  )
}

export default PurchaceReturnOrdersTable