/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, createRef, useEffect, useState} from 'react'
import SharedData from '../../../common/SharedData'
import {getApplySpecialProducts} from '../core/_requests'
import Loader from '../../../common/Loader'
import NormalMessage from '../../../common/NormalMessage'
import {ISpecialProductModel} from '../core/_models'
import ConnectedBarcodeTablee from './ConnectedBarcodeTablee'
import DeleteRequestBtn from '../components/DeleteRequestBtn'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
interface IProps {}
export interface IDuplicateTableRef {
  submitTable(): void
}
const ConnectedBarcodeDetails: React.FC<IProps> = ({}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const {currentUser, language} = SharedData()
  const [duplicateList, setDuplicateList] = useState<ISpecialProductModel[]>([])
  const tableRef = createRef<IDuplicateTableRef>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getDate = async () => {
      setIsLoading(true)
      const {data} = await getApplySpecialProducts(currentUser?.token, language, {
        special_status: 1,
      })

      if (data) {
        // debugger;
        if (data.special_list.length === 0) {
          const prevPage = search.get('from')
          let from = document.location.pathname
          if (prevPage) {
            from = prevPage
          } 
          navigate(`/discount/pending-special-products?from=${from}`, {
            replace: true,
          })
        } else {
          
          setDuplicateList(
            data.special_list.map((item: ISpecialProductModel) => {
              let temp = {...item}
              if (typeof temp.data == 'string') temp.data = JSON.parse(temp.data)
              return {...temp}
            })
          )
        }
      }
      setIsLoading(false)
    }
    getDate()
  }, [])

  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (duplicateList.length > 0) {
      return (
        <>
          <div className='py-3'>
            <ConnectedBarcodeTablee
              currentUser={currentUser}
              ref={tableRef}
              products={duplicateList}
            />
          </div>
        </>
      )
    }
    return <NormalMessage msg={formatMessage({ id: "no_data" })} />
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-7 align-items-center'>
        <span className='fw-bold text-gray-700'>
          <i className='text-dark fas fa-exclamation-circle'></i> {formatMessage({ id: "connected_prods_same_barcode" })}
        </span>
        <div className='d-flex align-items-center gap-2'>
          <DeleteRequestBtn />
          <button onClick={() => tableRef.current?.submitTable()} className='btn btn-dark'>
            {formatMessage({ id: "done" })}
          </button>
        </div>
      </div>
      <div className={`card`}>
        <div className='card-body'>{resultsData()}</div>
      </div>
    </>
  )
}

export default ConnectedBarcodeDetails
