import React, {FC, useState, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import InputField from '../components/InputField'
import {UserModel} from '../../auth'
import {useIntl} from 'react-intl'
import {addDailyDealProduct, dailyDealAction} from "../cors/_requests";
import {applyDailyDealProduct} from "../cors/_requests";
import {prepareErrors} from '../../errors/core/_models'

type IProps = {
    language: string
    currentUser: UserModel | undefined,
    refreshData: () => Promise<void>;
}

const DailyDealForm: FC<IProps> = ({language, currentUser, refreshData}) => {
    const {formatMessage} = useIntl()
    const [isLoading, setIsLoading] = useState(false)
    const currentDate = new Date().toISOString().split('T')[0];

    const [form, setForm] = useState({
        barcode: {
            error: false,
            value: '',
            msg: '',
        },
        discount_date: {
            error: false,
            value: '',
            msg: '',
        },
        quantity: {
            error: false,
            value: 4,
            msg: '',
        },
        discount_percentage: {
            error: false,
            value: '',
            msg: '',
        },
        msg: ""

    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target
        setForm({...form, [id]: {value: value}})
    }

    const isFormHasError = () => {
        let hasError = false
        setIsLoading(true)

        setForm((prev: any) => ({
            ...prev,
            barcode: {...prev.barcode, error: false, msg: ''},
            discount_date: {...prev.discount_date, error: false, msg: ''},
            quantity: {...prev.quantity, error: false, msg: ''},
            discount_percentage: {...prev.discount_percentage, error: false, msg: ''},
        }))

        if (form.barcode.value === '') {
            setForm((prev: any) => ({
                ...prev,
                barcode: {...prev.barcode, error: true},
            }))
            hasError = true
        }

        if (form.discount_date.value === '') {
            setForm((prev: any) => ({
                ...prev,
                discount_date: {...prev.discount_date, error: true},
            }))
            hasError = true
        }

        if (form.quantity.value === 0) {
            setForm((prev: any) => ({
                ...prev,
                quantity: {...prev.quantity, error: true},
            }))
            hasError = true
        }

        if (form.discount_percentage.value === '') {
            setForm((prev: any) => ({
                ...prev,
                discount_percentage: {...prev.discount_percentage, error: true},
            }))
            hasError = true
        }

        setIsLoading(false)
        return hasError
    }

    const handleSubmit = async () => {
        if (!isFormHasError()) {

            try {

                setIsLoading(true)
                await addDailyDealProduct(currentUser?.token, language, {
                    barcode: form.barcode.value,
                    discount_date: form.discount_date.value,
                    quantity: form.quantity.value,
                    discount_percentage: form.discount_percentage.value,
                })

                refreshData();
                resetForm();
                setIsLoading(false)
            } catch (exp: any) {
                setIsLoading(false)
                const updatedState: any = prepareErrors(exp, form)
                if (updatedState) setForm(updatedState)
            }
        } else {
        }
    }

    const applyDailyDeals = async () => {
        await applyDailyDealProduct(currentUser?.token, language)
        window.location.reload();
    }

    const resetForm = () => {
        setForm({
            barcode: {
                error: false,
                value: '',
                msg: '',
            },
            discount_date: {
                error: false,
                value: '',
                msg: '',
            },
            quantity: {
                error: false,
                value: 1,
                msg: '',
            },
            discount_percentage: {
                error: false,
                value: '',
                msg: '',
            },
            msg: ''
        });
    };


    return (
        <form className=''>
            <div className='row'>
                <span className='error-msg' style={{color: 'red'}}> {form.msg}</span>
                <div className='col-sm-2'>
                    <InputField
                        type='string'
                        id='barcode'
                        value={form.barcode.value}
                        isRequired={true}
                        hasError={form.barcode.error}
                        onChange={handleChange}
                        label={formatMessage({id: 'barcode'})}
                        errorMsg={form.barcode.msg}
                    />
                </div>

                <div className='col-sm-2'>
                    <InputField
                        type='date'
                        id='discount_date'
                        value={form.discount_date.value}
                        isRequired={true}
                        hasError={form.discount_date.error}
                        onChange={handleChange}
                        label={formatMessage({id: 'discount_date'})}
                        errorMsg={form.discount_date.msg}
                        min={currentDate}
                    />
                </div>

                <div className='col-sm-2'>
                    <InputField
                        type='number'
                        id='quantity'
                        value={form.quantity.value}
                        isRequired={true}
                        hasError={form.quantity.error}
                        onChange={handleChange}
                        label={formatMessage({id: 'quantity'})}
                        errorMsg={form.quantity.msg}
                    />
                </div>

                <div className='col-sm-2'>
                    <InputField
                        type='number'
                        id='discount_percentage'
                        value={form.discount_percentage.value}
                        isRequired={true}
                        hasError={form.discount_percentage.error}
                        onChange={handleChange}
                        label={formatMessage({id: 'discount_percentage'})}
                        errorMsg={form.discount_percentage.msg}
                    />
                </div>

                <div className='col-sm-2'
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'}}>
                    <Button disabled={isLoading}
                            onClick={handleSubmit}
                            style={{width: '72%'}}>
                        {formatMessage({id: 'add'})}
                    </Button>
                </div>

                <div className='col-sm-2'
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'}}>
                    <Button disabled={isLoading}
                            onClick={applyDailyDeals}
                            style={{width: '72%'}}>
                        {formatMessage({id: 'apply'})}
                    </Button>
                </div>

            </div>
        </form>
    )
}

export default DailyDealForm
