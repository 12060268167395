import {FC, useState} from 'react'
import {IPOInfoModel} from '../../core/_models'
import PurchaseTableRow from './PurchaseTableRow'

import PurchasePartiallyModal from './PurchasePartiallyModal'
import AlertMessage, { AlertMsgModel } from '../../../common/alert/AlertMsg'
import { useIntl } from 'react-intl'

interface IProps {
  orders: IPOInfoModel[],
  refreshData: () => Promise<void>
}
const PurchaseTable: FC<IProps> = ({orders,refreshData}) => {
  const {formatMessage} = useIntl();
  const [modalParam, setModalParam] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);

  const [error, setError] = useState<AlertMsgModel>({
    title: '',
    message: '',
    status: false,
  });


  const clearModalsData = (alertMessage?:AlertMsgModel) => {
    setStatus(null)
    setModalParam(null)
    if (alertMessage) {
      if (alertMessage.status) {
        refreshData()
      }
      setError({
        title: alertMessage.title,
        message:alertMessage.message,
        status: alertMessage.status || false
      })
    }
  }

  const hideAlertMessage = () => {
    setError({
      title: '',
      message: '',
      status: false,
    })
  }

  return (
    <>
      {/* begin::Table container */}
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          {/* begin::Table head */}
          <thead>
            <tr>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "image" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "barcode" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "model_num" })}</th>
              <th className='fw-bold p-0 text-uppercase'>{formatMessage({ id: "prod_name" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "qty" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "received_qty" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "cost_price" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "sale_price" })}</th>
              <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>{formatMessage({ id: "total_price" })}</th>
              {/* <th className='fw-bold p-0 px-2 text-nowrap'>REQUEST</th> */}
              <th className='fw-bold p-0'>{formatMessage({ id: "actions" })}</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {orders &&
              orders.map((item) => (
                <PurchaseTableRow
                  key={item.item_id}
                  item={item}
                  setStatus={setStatus}
                  setModalParam={setModalParam}
                />
              ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      {/* end::Table container */}
      {status && <PurchasePartiallyModal flushModals={clearModalsData} status={status} modalParam={modalParam} />}
      {error.title !== '' && (
        <AlertMessage title={error.title} message={error.message} status={error.status} onClose={hideAlertMessage} />
      )}
    </>
  )
}

export default PurchaseTable
