import {FC, useState} from 'react'
import TextTableFilter from '../../common/TextTableFilter'
import {DISCOUNT_FILTERS} from '../../products/cors/_models'
import {useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'

interface IProps {}

export const AddDiscountReqFilters: FC<IProps> = ({}) => {
  const {formatMessage} = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [nameFilter, setNameFilter] = useState(searchParams.get(DISCOUNT_FILTERS.NAME) || '')
  const [barcodeFilter, setBarcodeFilter] = useState(
    searchParams.get(DISCOUNT_FILTERS.BARCODE) || ''
  )
  const [modelFilter, setModelFilter] = useState(searchParams.get(DISCOUNT_FILTERS.MODEL) || '')
  const [dateFilter, setDateFilter] = useState(searchParams.get(DISCOUNT_FILTERS.DATE) || '')
  const [statusFilter, setStatusFilter] = useState(searchParams.get(DISCOUNT_FILTERS.STATUS) || '')

  const handleChange = (id: string, value: string, type: string) => {
    if (value) {
      searchParams.set(id, value)
      searchParams.set('current_page', '1')
    } else {
      searchParams.delete(id)
    }
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const clearFilters = (): void => {
    setSearchParams(
      {},
      {
        replace: true,
      }
    )
    setNameFilter('')
    setBarcodeFilter('')
    setModelFilter('')
    setDateFilter('')
    setStatusFilter('')
  }

  return (
    <>
      <div className='card-body border-bottom pb-10 mb-5'>
        <div className='row'>
          {/* start product Name */}
          <TextTableFilter
            id={DISCOUNT_FILTERS.NAME}
            label={formatMessage({id: 'prod_name'})}
            value={nameFilter}
            setValue={setNameFilter}
            onChange={handleChange}
          />
          {/* end product Name */}

          {/* start BARCODE */}
          <TextTableFilter
            id={DISCOUNT_FILTERS.BARCODE}
            label={formatMessage({id: 'barcode'})}
            value={barcodeFilter}
            setValue={setBarcodeFilter}
            onChange={handleChange}
          />
          {/* end BARCODE */}

          {/* start MODEL */}
          <TextTableFilter
            id={DISCOUNT_FILTERS.MODEL}
            label={formatMessage({id: 'model_num'})}
            value={modelFilter}
            setValue={setModelFilter}
            onChange={handleChange}
          />
          {/* end MODEL */}

          {/* start DATE */}
          <TextTableFilter
            id={DISCOUNT_FILTERS.DATE}
            label={formatMessage({id: 'date_added'})}
            value={dateFilter}
            setValue={setDateFilter}
            onChange={handleChange}
            type='date'
          />
          {/* end DATE */}

          {/* start STATUS */}
          {/* {searchParams.get('hide_status') === 'true' ? (
            <></>
          ) : (
            <>
              <TextTableFilter
                id={DISCOUNT_FILTERS.STATUS}
                label={formatMessage({id: 'status'})}
                value={statusFilter}
                setValue={setStatusFilter}
                onChange={handleChange}
                type='select'
                options={[
                  {label: formatMessage({id: 'all'}), value: ''},
                  {label: formatMessage({id: 'rejected'}), value: 'rejected'},
                  {label: formatMessage({id: 'pending'}), value: 'pending'},
                ]}
              />
            </>
          )} */}
          {/* end STATUS */}

          {/* reset button */}
          <div className='col-sm-12 col-md-3'>
            <button
              type='reset'
              className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
              onClick={clearFilters}
            >
              {formatMessage({id: 'reset'})}
            </button>
          </div>
          {/* reset buttun # */}
        </div>
      </div>
    </>
  )
}

export default AddDiscountReqFilters
