import React, {FC, useEffect, useRef, useState} from 'react'

type IProps = {
  value: any
  setValue: any
  onChange: (id: string, value: string, type: string) => void
  id: string
  label: string
  type?: string
  options?: {value: string; label: string}[]
}

let typingTimeout = 1000
let typingTimer: ReturnType<typeof setTimeout>

const TextTableFilter: FC<IProps> = ({label, id, onChange, type, value, setValue, options}) => {
  const [inputState, setInputState] = useState(value);
  useEffect(()=>{
    if (inputState !== value) {
      setInputState(value)
    }
  },[value])
  
  const handleChange = (e: React.ChangeEvent<any>) => {
    if (type === 'select') {
      onChange(id, e.target.value, type)
      setInputState(e.target.value)
      setValue(e.target.value)
    } else {
      
      clearTimeout(typingTimer)

      setValue(e.target.value)
      setInputState(e.target.value)

      typingTimer = setTimeout(() => {
        onChange(id, e.target.value, type || 'text')
      }, typingTimeout)
    }
  }

  return (
    <div className='col-sm-12 col-md-3 mb-5'>
      <label className='form-label fw-bold'>{label}</label>
      <div>
        {type && type === 'select' ? (
          <select className='form-control' id={id} value={inputState} onChange={handleChange}>
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        ) : (
          <input
            className='form-control'
            type={type || 'text'}
            id={id}
            value={inputState}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  )
}

export default TextTableFilter
