import React, { FC } from 'react'
import { useIntl } from 'react-intl'
interface IProps {
  margin: any
  st1From: any
  st2From: any
  st3From: any
  st1To: any
  st2To: any
  st3To: any
  st1Margin: any
  st2Margin: any
  st3Margin: any
  paymentType: string

}
const ContractMarginTable:FC<IProps> = ({margin,st1From,st2From,st3From,st1To,st2To,st3To,st1Margin,st2Margin,st3Margin,paymentType}) => {
  const {formatMessage} = useIntl();
  const isTrade = ()=> paymentType.toLowerCase() == 'trade'
  
  return (
    <div className='col-sm-12'>
      <label className='text-gray-600 fw-bold fs-5 d-block mb-5'>{formatMessage({ id: "A_label" })}) {isTrade() ? formatMessage({ id: "rebate_lbl" }): formatMessage({ id: "margin_lbl" })}</label>
      <div className='border-bottom row pb-1'>
        <div className='row'>
          <div className='col-md-6'>
            <label className='text-gray-600 fw-bold fs-5 px-sm-4'>{isTrade() ? formatMessage({ id: "fixed_rebate" }) : formatMessage({ id: "fixed_margin" })}</label>
          </div>
          <div className='col-md-6'>
            <p className='text-dark fs-4 mb-0 fw-bold'>{margin}</p>
          </div>
        </div>
      </div>

      <div className='pt-13'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5 border-top mt-2'>
          {/* begin::Table body */}
          <tbody>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "slabs" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{isTrade() ? formatMessage({ id: "purchase" }) : formatMessage({ id: "sales" })} ({formatMessage({ id: "jod" })}) {formatMessage({ id: "from" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{isTrade() ? formatMessage({ id: "purchase" }) : formatMessage({ id: "sales" })} ({formatMessage({ id: "jod" })}) {formatMessage({ id: "to" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{isTrade() ? formatMessage({ id: "perc_rebate" }) : formatMessage({ id: "perc_margin" })}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "step" })} 1</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st1From}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st1To}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st1Margin}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "step" })} 2</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st2From}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st2To}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st2Margin}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600'>{formatMessage({ id: "step" })} 3</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st3From}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st3To}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center'>{st3Margin}</th>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table Container */}
    </div>
  )
}

export default ContractMarginTable
