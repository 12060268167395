import {FC, useState} from 'react'
import { Alert } from 'react-bootstrap'
import './AlertMsg.css'

export interface AlertMsgModel {
  title: string
  message: string
  status?: boolean
}

interface IProps extends AlertMsgModel {
  onClose: () => void
}
const AlertMessage: FC<IProps> = ({title, message, status,onClose}) => {
    const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    if (onClose)
      onClose()
  }

  return (
    <>
      <Alert className={`popup-alert fixed-bottom w-300px`} show={show} variant={`${status ? 'success' : 'danger'}`} onClose={handleClose} dismissible>
        <Alert.Heading>{title}</Alert.Heading>
        <p>
          {message}
        </p>
      </Alert>

    </>
  )
}

export default AlertMessage
