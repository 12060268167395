import React, {useEffect, useState} from 'react'

import MyProfileField from './MyProfileField'
import MyProfileFieldText from './MyProfileFieldText'
import SharedData from '../../../common/SharedData'
import {getCities, getSupplierInfo, updateSupplierInfo} from '../../core/_requests'
import {IProfileInfoModel} from '../../core/_models'
import MyProfileCityField from './MyProfileCityField'
import MyProfileLocationField from './MyProfileLocationField'
import { useIntl } from 'react-intl'

const MyProfileInfoForm = () => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const [profileInfo, setProfileInfo] = useState<IProfileInfoModel>()

  const [fields, setFields] = useState<any>({
    email: {
      value: '',
      edit: false,
      changed: false,
    },
    telephone: {
      value: '',
      edit: false,
      changed: false,
    },
    city: {
      value: '',
      edit: false,
      changed: false,
      label: '',
    },
    location: {
      value: '',
      edit: false,
      changed: false,
    },
  })
  useEffect(() => {
    const getDate = async () => {
      const {data: data} = await getSupplierInfo(currentUser?.token, language)
      
      const info = data.supplier_info
      setProfileInfo(info)
      setFields((prev: any) => ({
        ...prev,
        email: {
          ...prev['email'],
          value: info.email,
        },
        telephone: {
          ...prev['telephone'],
          value: info.telephone,
        },
        city: {
          ...prev['city'],
          value: info.city_id,
        },
        location: {
          ...prev['location'],
          value: info.location,
        },
      }))
    }
    getDate()
  }, [])

  const fieldChangedAlert = (fieldName: string) => {
    setFields((prev: any) => ({
      ...prev,
      [fieldName]: {...prev[fieldName], changed: true},
    }))
  }
  const saveField = async (fieldName: string, params?: any) => {
    // const {value} = fields[fieldName]
    // const fieldNameToSend = ProfileBasicInfoUpdate[fieldName]
    try {
      const data = await updateSupplierInfo(currentUser?.token, language, {
        email: fields.email.value,
        telephone: fields.telephone.value,
        country_id: 108,
        city_id: fields.city.value,
        zone_id:fields.location.value ,
      });
      
      if (data) {
        fieldChangedAlert(fieldName)
      } else {
        if (params && params.oldValue) {
          setFields((prev: any) => ({
            ...prev,
            [fieldName]: {...prev[fieldName], value: params.oldValue},
          }))
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-sm-12 d-flex flex-column gap-7 pb-10'>
          {/* Supplier Name */}
          <MyProfileFieldText label={formatMessage({ id: "supplier_name" })} value={profileInfo?.name} />

          <MyProfileField
            setFields={setFields}
            label={formatMessage({ id: "email" })}
            field={fields.email}
            fieldName='email'
            saveField={saveField}
          />
          <MyProfileField
            setFields={setFields}
            type='number'
            label={formatMessage({ id: "mobile" })}
            field={fields.telephone}
            fieldName='telephone'
            saveField={saveField}
          />

          <MyProfileCityField saveField={saveField} field={fields.city} setFields={setFields} />
          <MyProfileLocationField
            cityID={fields.city.value}
            saveField={saveField}
            field={fields.location}
            setFields={setFields}
          />
          <MyProfileFieldText label={formatMessage({ id: "margin" })} value={profileInfo?.margin} />

          <MyProfileFieldText
            label={formatMessage({ id: "delivery_duration" })}
            value={profileInfo?.delivery_duration_name}
          />

          <MyProfileFieldText label={formatMessage({ id: "payment_terms" })} value={profileInfo?.payment_terms} />

          {/* <MyProfileFieldText label={formatMessage({ id: "payment_type" })} value={profileInfo?.payment_type} /> */}

          <MyProfileFieldText label={formatMessage({ id: "account_manage" })} value={profileInfo?.account_manager} />

          <MyProfileFieldText label={formatMessage({ id: "is_express" })} value={profileInfo?.express} />
        </div>
      </div>
    </>
  )
}

export default MyProfileInfoForm
