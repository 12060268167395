import {FC, useState, useEffect} from 'react'
import {IPOTypes} from '../core/_models'
import TextTableFilter from '../../common/TextTableFilter'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'

interface IProps {
  // setResultFilters: React.Dispatch<React.SetStateAction<IPOTableFilter>>
  // resultFilters: IPOTableFilter
}

export const PurchaceReturnOrdersFilters2: FC<IProps> = ({}) => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [dateFilter, setDateFilter] = useState(searchParams.get('date_added_filter') || '')
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status_filter') || '')
  const [supplierOrderFilter, setSupplierOrderFilter] = useState(
    searchParams.get('supplier_order_id_filter') || ''
  )
  const [typeFilter, setTypeFilter] = useState(searchParams.get('type_filter') || '')
  const [grnFilter, setGrnFilter] = useState(searchParams.get('GRN_filter') || '')

  useEffect(() => {
    filter();
  }, [searchParams]);

  const handleChange = (id: string, value: string, type: string) => {
    if (value) {
      searchParams.set(id, value)
      searchParams.set('current_page', '1')
    } else {
      searchParams.delete(id)
    }
  }
  const filter = (): void => {
    setSearchParams(searchParams,{
      replace:true
    })
  }

  const clearFilters = (): void => {
    setSearchParams({},{
      replace:true
    })
    setDateFilter('')
    setStatusFilter('')
    setSupplierOrderFilter('')
    setTypeFilter('')
    setGrnFilter('')
  }

  return (
    <>
      <div className='card-body border-bottom pb-10 mb-5'>
        <div className='row'>
          {/* start DATE */}
          <TextTableFilter
            id='date_added_filter'
            label={formatMessage({ id: "date_added" })}
            value={dateFilter}
            setValue={setDateFilter}
            onChange={handleChange}
            type='date'
          />
          {/* end DATE */}
          {/* start STATUS */}
          <TextTableFilter
            id='status_filter'
            label={formatMessage({ id: "status" })}
            value={statusFilter}
            setValue={setStatusFilter}
            onChange={handleChange}
            type='select'
            options={[
              {label: formatMessage({ id: "all" }), value: ''},
              {label: formatMessage({ id: "received" }), value: 'received'},
              {label: formatMessage({ id: "pending" }), value: 'pending'},
            ]}
          />
          {/* end STATUS */}

          {/* start TYPE */}
          <TextTableFilter
            id='type_filter'
            label={formatMessage({ id: "type" })}
            value={typeFilter}
            setValue={setTypeFilter}
            onChange={handleChange}
            type='select'
            options={[
              {label: formatMessage({ id: "all" }), value: ''},
              {label: formatMessage({ id: "po" }), value: IPOTypes.po},
              {label: formatMessage({ id: "return" }), value: IPOTypes.return},
            ]}
          />
          {/* end TYPE */}

          {/* GRN # */}
          <TextTableFilter
            id='GRN_filter'
            label={formatMessage({ id: "grn_num" })}
            value={grnFilter}
            setValue={setGrnFilter}
            onChange={handleChange}
            type='text'
          />
          {/* GRN # */}


          {/* start supplier id */}
          <TextTableFilter
            id='supplier_order_id_filter'
            label={formatMessage({ id: "por_id" })}
            value={supplierOrderFilter}
            setValue={setSupplierOrderFilter}
            onChange={handleChange}
            type='text'
          />
          {/* supplier id */}

          <div className='col-sm-12 col-md-3'>
            <button
                className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                onClick={filter}
            >
              {formatMessage({ id: "filter" })}
            </button>
          </div>

          {/* reset button */}
          <div className='col-sm-12 col-md-3'>
            <button
              type='reset'
              className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
              onClick={clearFilters}
            >
              {formatMessage({ id: "reset" })}
            </button>
          </div>
          {/* reset buttun # */}
        </div>
      </div>
    </>
  )
}

export default PurchaceReturnOrdersFilters2
