import {FC, useRef, useState} from 'react'
import {IProductTableFilter, ProductsTableFilterInitialState} from '../cors/_models'
import TextTableFilter from '../../common/TextTableFilter'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'

interface IProps {
  // setResultFilters: React.Dispatch<React.SetStateAction<IProductTableFilter>>
  // resultFilters: IProductTableFilter
}


export const ProductsTableFilter: FC<IProps> = ({}) => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [nameFilter, setNameFilter] = useState(searchParams.get('filter_name') || '')
  const [barcodeFilter, setBarcodeFilter] = useState(searchParams.get('filter_barcode') || '')
  const [modelFilter, setModelFilter] = useState(searchParams.get('filter_model') || '')
  const [qtyFilter, setQtyFilter] = useState(searchParams.get('filter_quantity') || '')
  const [brandFilter, setBrandFilter] = useState(searchParams.get('filter_brand') || '')
  const [statusFilter, setStatusFilter] = useState(searchParams.get('filter_status') || '')

  const handleChange = (id: string, value: string, type: string) => {
    if (value) {
      searchParams.set(id, value)
      searchParams.set('current_page', '1')
    } else {
      searchParams.delete(id)
    }
  }

  const filter = (): void => {
    setSearchParams(searchParams,{
      replace:true
    })
  }
  
  const clearFilters = (): void => {
    setSearchParams({},{
      replace:true
    })
    setNameFilter('')
    setBarcodeFilter('')
    setModelFilter('')
    setQtyFilter('')
    setBrandFilter('')
    setStatusFilter('')
  }

  return (
    <>
      <div className='card-body border-bottom pb-10 mb-5'>
        <div className='row'>
          {/* start product Name */}
          <TextTableFilter
            id='filter_name'
            label={formatMessage({ id: "prod_name" })}
            value={nameFilter}
            setValue={setNameFilter}
            onChange={handleChange}
          />
          {/* end product Name */}

          {/* start BARCODE */}
          <TextTableFilter
            id='filter_barcode'
            label={formatMessage({ id: "barcode" })}
            value={barcodeFilter}
            setValue={setBarcodeFilter}
            onChange={handleChange}
          />
          {/* end BARCODE */}

          {/* start MODEL */}
          <TextTableFilter
            id='filter_model'
            label={formatMessage({ id: "model" })}
            value={modelFilter}
            setValue={setModelFilter}
            onChange={handleChange}
          />
          {/* end MODEL */}

          {/* start STATUS */}
          <TextTableFilter
            id='filter_status'
            label={formatMessage({ id: "status" })}
            value={statusFilter}
            setValue={setStatusFilter}
            onChange={handleChange}
            type='select'
            options={[
              {label: formatMessage({ id: "all" }), value: ''},
              {label: formatMessage({ id: "active" }), value: '1'},
              {label: formatMessage({ id: "not_active" }), value: '2'},
              {label: formatMessage({ id: "out_of_stock" }), value: '3'},
            ]}
          />
          {/* end STATUS */}

          {/* start BRAND */}
          <TextTableFilter
            id='filter_brand'
            label={formatMessage({ id: "brand" })}
            value={brandFilter}
            setValue={setBrandFilter}
            onChange={handleChange}
          />
          {/* end BRAND */}
          
          
          {/* start Quantity */}
          <TextTableFilter
            id='filter_quantity'
            label={formatMessage({ id: "quantity" })}
            value={qtyFilter}
            setValue={setQtyFilter}
            onChange={handleChange}
          />
          {/* end Quantity */}

          <div className='col-sm-12 col-md-3'>
            <button
                className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                onClick={filter}
            >
              {formatMessage({ id: "filter" })}
            </button>
          </div>

          {/* reset button */}
          <div className='col-sm-12 col-md-3'>
            <button
              type='reset'
              className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
              onClick={clearFilters}
            >
              {formatMessage({ id: "reset" })}
            </button>
          </div>
          {/* reset button # */}
        </div>
      </div>
      
    </>
  )
}

export default ProductsTableFilter
