import React, {FC, useState} from 'react'
import AlertMessage from '../../../common/alert/AlertMsg'
import SharedData from '../../../common/SharedData'
import {removeSpecialBulk} from '../core/_requests'
import Loader from '../../../common/Loader'
import {useNavigate} from 'react-router-dom'
import fileDownload from 'js-file-download'
import { useIntl } from 'react-intl'
import { getBlobReqErrors } from '../../../errors/core/_models'
type IProps = {
  bulkFile: any
  setBulkFile: React.Dispatch<any>
}
const SubmitButtonRow: FC<IProps> = ({
  bulkFile,
  setBulkFile,
}) => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const isFormHasError = () => {
    let hasError = false
    setBulkFile({
      ...bulkFile,
      error: false,
    })
    if (!bulkFile.value) {
      setBulkFile({
        ...bulkFile,
        error: true,
      })
      hasError = true
    }
    return hasError
  }
  const handleSubmit = async () => {
    if (!isFormHasError()) {
      setIsLoading(true)
      try {
        const data: any = await removeSpecialBulk(
          currentUser?.token,
          {
            file: bulkFile.value,
          },
          language
        )
        if (data) {
          fileDownload(data.data, 'Remove Discount - Bulk.xlsx')
          navigate('/discount/remove-discount-bulk?from=/discount/remove-discount-bulk', {
            replace: true,
          })
        } else {
          setError(true)
        }
      } catch (exp) {
        const {error} = await getBlobReqErrors(exp);
        setError(true)
      }
      setIsLoading(false)
    } else {
    }
  }

  return (
    <>
      <div className='mt-3 d-flex justify-content-end align-items-center gap-2 flex-column-p'>
        <p className='mb-0'>
          {formatMessage({ id: "by_uploading_products_stnrd" })}
        </p>
        <button
          onClick={handleSubmit}
          className='btn btn-sm fs-4 py-1 px-9 btn-light  btn-close-white'
        >
          {formatMessage({ id: "upload" })}
        </button>
      </div>
      {isLoading && <Loader isFull={true} />}
      {error === true && (
        <AlertMessage
          title={formatMessage({ id: "something_wrong_title" })}
          message={formatMessage({ id: "something_wrong_desc" })}
          onClose={() => setError(false)}
        />
      )}
    </>
  )
}

export default SubmitButtonRow
