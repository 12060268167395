import { prepareFilterParams } from './../../../orders/core/_requests';
import axios from 'axios'
import { IDiscountBulkModel, IDiscountSpecialBody } from './_models';


const API_URL = process.env.REACT_APP_API_URL
export const BULK_SPECIAL_TEMPLATE = `${API_URL}/api/partner-portal/special/bulk-template`
export const REMOVE_BULK_SPECIAL_TEMPLATE = `${API_URL}/api/partner-portal/special/remove-special-bulk-template`
export const ADD_BULK_SELECTION_PRODUCTS = `${API_URL}/api/partner-portal/special/products`
export const GET_SELECTION_APPLY_SPECIAL = `${API_URL}/api/partner-portal/special/special`
export const SET_SELECTION_APPLY_SPECIAL = `${API_URL}/api/partner-portal/special/special`
export const REMOVE_SPECIAL_PENDING_PRODUCT = `${API_URL}/api/partner-portal/special/product`
export const SET_BULK_SPECIAL_SELECTION_FORM = `${API_URL}/api/partner-portal/special/bulk-special`
export const REMOVE_BULK_SPECIAL_SELECTION_FORM = `${API_URL}/api/partner-portal/special/remove-by-bulk-special`
export const SET_CONNECTED_PRODUCTS = `${API_URL}/api/partner-portal/special/connected-products`
export const REMOVE_PENDING_REQUEST = `${API_URL}/api/partner-portal/special/remove-special-requests`
export const REMOVE_DUPLICATE_BARCODE_REQUEST = `${API_URL}/api/partner-portal/product/delete-duplicate-barcodes`


export async function setBulkSpecial(token: string | undefined, data: IDiscountBulkModel,language:string) {
    function toDDMMYYYY(date:string){
      return new Date(date).toLocaleDateString('en-GB',{}).replaceAll('/','-')
    }
    const formData = new FormData();
  
    formData.append('file', data.file)
    formData.append('date_start', toDDMMYYYY(data.date_start))
    formData.append('date_end', toDDMMYYYY(data.date_end))    
    
    return axios.post<any>(`${SET_BULK_SPECIAL_SELECTION_FORM}`, formData, {
      responseType: "blob",
      headers: {token: token || '', 'content-type': 'multipart/form-data',language},
    })
}

export async function removeSpecialBulk(token: string | undefined, data: any,language:string) {
    const formData = new FormData();

    formData.append('file', data.file)

    return axios.post<any>(`${REMOVE_BULK_SPECIAL_SELECTION_FORM}`, formData, {
        responseType: "blob",
        headers: {token: token || '', 'content-type': 'multipart/form-data',language},
    })
}


export async function addProductsDiscount(token: string | undefined,language:string, data: IDiscountSpecialBody) {
    return axios.post<any>(`${ADD_BULK_SELECTION_PRODUCTS}`, data, {
      headers: {token: token || '',language,'ACCEPT': 'application/json'},
    })
}

export async function applySpecialPendingProducts(token: string | undefined, data: {special_ids:number[]}) {
  return axios.post<any>(`${SET_SELECTION_APPLY_SPECIAL}`, data, {
    headers: {token: token || ''},
  })
}

export async function applyConnectedProducts(token: string | undefined, data: {special_ids:number[] | string[]}) {
  return axios.post<any>(`${SET_CONNECTED_PRODUCTS}`, data, {
    headers: {token: token || ''},
  })
}

// get special
export async function getApplySpecialProducts(token: string | undefined,language:string, filters?:any) {
    return axios.get<any>(`${GET_SELECTION_APPLY_SPECIAL}?${filters ? prepareFilterParams(filters): ''}`, {
      headers: {token: token || '',language},
    })
}

export async function removePendingSpecialProduct(token: string | undefined, special_id:number |string) {
    return axios.delete<any>(`${REMOVE_SPECIAL_PENDING_PRODUCT}?special_id=${special_id}`, {
      headers: {token: token || ''},
    })
}


// get special
export async function deleteRequest(token: string | undefined,language:string,isProductBulk:boolean = false) {
  let url = REMOVE_PENDING_REQUEST
  if (isProductBulk) url = REMOVE_DUPLICATE_BARCODE_REQUEST
  return axios.delete<any>(`${url}`, {
    headers: {token: token || '',language},
  })
}
