import {FC, useEffect, useState} from 'react'
import TextTableFilter from "../../modules/common/TextTableFilter";
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import {IRemoveReason} from "../products/cors/_models";

export const LossOfOpportunityListFilter: FC = ({}) => {

    const {formatMessage} = useIntl();
    const [searchParams, setSearchParams] = useSearchParams()
    const [startDateFilter, setStartDateFilter] = useState(searchParams.get('filter_start_date') || '')
    const [endDateFilter, setEndDateFilter] = useState(searchParams.get('filter_end_date') || '')
    const [nameFilter, setNameFilter] = useState(searchParams.get('filter_name') || '')
    const [barcodeFilter, setBarcodeFilter] = useState(searchParams.get('filter_barcode') || '')
    const [poIdFilter, setPoIdFilter] = useState(searchParams.get('filter_po_id') || '')
    const [removeReasonIdFilter, setRemoveReasonIdFilter] = useState(searchParams.get('filter_remove_reason_id') || '')

    useEffect(() => {
        filter();
    }, [searchParams]);

    const handleChange = (id: string, value: string, type: string) => {
        if (value) {
            searchParams.set(id, value)
            searchParams.set('current_page', '1')
        } else {
            searchParams.delete(id)
        }
    }

    const filter = (): void => {
        setSearchParams(searchParams,{
            replace:true
        })
    }

    const clearFilters = (): void => {
        setSearchParams({},{
            replace:true
        })
        setStartDateFilter('')
        setEndDateFilter('')
    }

    return (
        <>
            <div className='card-body border-bottom pb-10 mb-5'>
                <div className='row'>

                    <TextTableFilter
                        id='filter_name'
                        label={formatMessage({ id: "prod_name" })}
                        value={nameFilter}
                        setValue={setNameFilter}
                        onChange={handleChange}
                    />

                    <TextTableFilter
                        id='filter_barcode'
                        label={formatMessage({ id: "barcode" })}
                        value={barcodeFilter}
                        setValue={setBarcodeFilter}
                        onChange={handleChange}
                    />

                    <TextTableFilter
                        id='filter_po_id'
                        label={formatMessage({ id: "po_id" })}
                        value={poIdFilter}
                        setValue={setPoIdFilter}
                        onChange={handleChange}
                    />

                    <TextTableFilter
                        id='filter_start_date'
                        label={formatMessage({ id: "date_start" })}
                        value={startDateFilter}
                        setValue={setStartDateFilter}
                        onChange={handleChange}
                        type='date'
                    />

                    <TextTableFilter
                        id='filter_end_date'
                        label={formatMessage({ id: "date_end" })}
                        value={endDateFilter}
                        setValue={setEndDateFilter}
                        onChange={handleChange}
                        type='date'
                    />

                    <TextTableFilter
                        id='filter_remove_reason_id'
                        label={formatMessage({ id: "remove_reason" })}
                        value={removeReasonIdFilter}
                        setValue={setRemoveReasonIdFilter}
                        onChange={handleChange}
                        type='select'
                        options={[
                            {label: formatMessage({ id: "all" }), value: ''},
                            {label: formatMessage({ id: "out_of_stock" }), value: IRemoveReason.out_of_stock},
                            {label: formatMessage({ id: "partially_available" }), value: IRemoveReason.partially_available},
                        ]}
                    />

                    <div className='col-sm-12 col-md-3'>
                        <button
                            className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                            onClick={filter}
                        >
                            {formatMessage({ id: "filter" })}
                        </button>
                    </div>
                    <div className='col-sm-12 col-md-3'>
                        <button
                            type='reset'
                            className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
                            onClick={clearFilters}
                        >
                            {formatMessage({ id: "reset" })}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default LossOfOpportunityListFilter
