import { useIntl } from 'react-intl';
import MyProfileBasicInfo from './components/my-profile/MyProfileBasicInfo'
import MyProfileNav from './components/my-profile/MyProfileNav'

const MyProfile = () => {
  const {formatMessage} = useIntl();
  return (
    <>
      <MyProfileNav pageTitle={formatMessage({ id: "my_profile" })} />
      <MyProfileBasicInfo />
    </>
  )
}

export default MyProfile
