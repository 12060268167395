import {useEffect, useState} from 'react'
import SubmitButtonRow from './components/SubmitButtonRow'
import {BULK_SPECIAL_TEMPLATE, getApplySpecialProducts} from './core/_requests'
import SharedData from '../../common/SharedData'
import InputField from '../components/InputField'
import BulkUploadField from '../add-bulk/components/BulkUploadField'
import { useIntl } from 'react-intl'

const SpecialBulkCard = () => {
  const {formatMessage} = useIntl();
  const {currentUser,language} = SharedData()
  const currentDate = new Date().toISOString().split('T')[0];
  
  const [bulkFile, setBulkFile] = useState<any>({
    value: null,
    error: false
  })
  const [startDate, setStartDate] = useState<any>({
    value: '',
    error: false,
    msg: ''
  })
  const [endDate, setEndDate] = useState<any>({
    value: '',
    error: false,
    msg: ''
  })

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    if (id === 'date_start') {
      setStartDate({
        ...startDate,
        value: value,
      })
    } else {
      setEndDate({
        ...endDate,
        value: value,
      })
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <>
            <div className='d-flex flex-column gap-9'>
              {/* Bulk sheet */}
              <BulkUploadField
                hasError={bulkFile.error}
                id='file'
                setField={setBulkFile}
                isRequired={true}
                templateUrl={BULK_SPECIAL_TEMPLATE}
                token={currentUser?.token}
                user={currentUser}
              />

              <div className='row gap-9 gap-md-0'>
                <div className='col-md-3'>
                  <InputField
                    type='date'
                    id='date_start'
                    value={startDate.value}
                    isRequired={true}
                    hasError={startDate.error}
                    onChange={handleDateChange}
                    label={formatMessage({ id: "date_start" })}
                    errorMsg={startDate.msg || formatMessage({ id: "date_start_required" })}
                    min={currentDate}
                  />
                </div>
                <div className='col-md-3'>
                  <InputField
                    type='date'
                    id='date_end'
                    value={endDate.value}
                    isRequired={true}
                    hasError={endDate.error}
                    onChange={handleDateChange}
                    label={formatMessage({ id: "date_end" })}
                    errorMsg={endDate.msg || formatMessage({ id: "date_end_required" })}
                    min={currentDate}
                  />
                </div>
              </div>

              <SubmitButtonRow
                bulkFile={bulkFile}
                setBulkFile={setBulkFile}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                
              />
            </div>
          </>
        </div>
      </div>
    </>
  )
}

export default SpecialBulkCard
