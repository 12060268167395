import {FC, useRef} from 'react'
import { IProductModel } from '../../cors/_models'
type IProps = {
  item: IProductModel
  onChange: (productID: number) => void,
  selectedProductIDs:number[]
}

const SpecialSelectionTableRow: FC<IProps> = ({item,onChange,selectedProductIDs}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(item.product_id)
  }
  
  return (
    <tr className='border-bottom border-dashed border-gray-500'>
      <td>
        <input
          ref={inputRef}
          onChange={handleChange}
          className='form-check-input'
          type='checkbox'
          checked={selectedProductIDs.includes(item.product_id)}
          id='flexCheckDefault'
        />
      </td>
      <th>
        <img src={item.image} className='table-img' />
      </th>
      <td className='min-w-200px'>{item.name}</td>
      <td>{item.barcode}</td>
      <td className='text-nowrap'>{item.model}</td>
      <td className='text-nowrap'>{(+item.cost_price).toFixed(2)}</td>
      <td className='text-nowrap'>{(+item.price).toFixed(2)}</td>
    </tr>
  )
}

export default SpecialSelectionTableRow
