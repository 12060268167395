export async function getBlobReqErrors(exp: any) {
    return JSON.parse(await exp.response.data.text())
}

export function prepareErrors(exp: any, state: any) {
    if (exp.response?.data?.error) {
        const errorsObj = exp.response.data.error
        const updatedState: any = state
        for (let key of Object.keys(errorsObj)) {
            const keyObj = state[key as keyof typeof state]
            updatedState[key] = {
                ...keyObj,
                error: true,
                msg: errorsObj[key],
            }
        }
        return updatedState
    }

    if (exp.response?.data?.message) {
        const keyObj = state
        const updatedState = {
            ...keyObj,
            error: true,
            msg: exp.response.data.message,
        }
        return updatedState
    }

    return null
}
