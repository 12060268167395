import {FC, useEffect, useState} from 'react'
import InputField from '../components/InputField'
import {IProductProps, productActionType} from '../cors/reducers'
import { ISelectOptions } from '../cors/_models'
import { getTax } from '../cors/_requests'
import SharedData from '../../common/SharedData'
import { useIntl } from 'react-intl'

const ProductPriceTab: FC<IProductProps> = ({prodState, prodDispatch}) => {
  const {formatMessage} = useIntl()
  const {currentUser,language} = SharedData()
  const [tax, setTax] = useState<ISelectOptions[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    prodDispatch({type: productActionType.text, payload: {id, value}})
  }

  useEffect(() => {
    const getTaxData = async () => {
      const {data} = await getTax(currentUser?.token, language);
      const convertData = data.map((item: any) => {
        return {label: item, value: item}
      })

      setTax(convertData)
    }
    getTaxData()
  }, [])

  return (
    <>
      <div className='card default-image-box'>
        <div className='card-body'>
          <div className='d-flex flex-column gap-9'>
            {/* Qunatity  */}
            <div className='w-100'>
              <div className='row gap-9 gap-md-0'>
                <div className='col-md-3 d-flex'>
                  <InputField
                    hasError={prodState.quantity.error}
                    value={prodState.quantity.value}
                    isRequired={true}
                    type='number'
                    onChange={handleChange}
                    id='quantity'
                    label={formatMessage({id: 'quantity'})}
                  />
                </div>
                {/* <div className='col-md-3'>
                  <InputField
                    // hasError={prodState.quantityPerBox}
                    value={prodState.quantityPerBox}
                    isRequired={false}
                    onChange={handleChange}
                    // type='select'
                    type='number'
                    id='quantityPerBox'
                    label={formatMessage({id: 'quantity_per_box'})}
                    min="1"
                    // options={[{label: 'Weight Class', value: ''}]}
                  />
                </div> */}
                <InputField
                  hasError={prodState.tax_class_id.error}
                  value={prodState.tax_class_id.value}
                  isRequired={true}
                  onChange={handleChange}
                  label={formatMessage({id: 'tax'})}
                  id='tax_class_id'
                  type='select'
                  options={[{label: formatMessage({id: 'tax'}), value: ''}, ...tax]}
                />
                </div>
              </div>
            </div>

            {/* Cost Price  */}
            <div className='w-100'>
              <div className='row gap-9 gap-md-0'>
                <div className='col-md-3 d-flex'>
                <div className='col-md-3'>
                  <InputField
                    hasError={prodState.salePrice.error}
                    value={prodState.salePrice.value}
                    isRequired={true}
                    onChange={handleChange}
                    type='number'
                    id='salePrice'
                    label={formatMessage({id: 'sale_price'})}
                    errorMsg={prodState.salePrice.msg}
                  />
                </div>
              </div>
            </div>

            {/* <div className='row'>
              <div className='col-md-6'>
                
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductPriceTab
