import { FC } from 'react'
import ContractInfo from '../../modules/my-profile/ContractInfo'

const ContractInfoPage:FC = () => {
  return (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
            <ContractInfo />
        </div>
        {/* end::Col */}
        </div>
        {/* begin::Row */}
    </>
  )
}

export default ContractInfoPage