import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import PendingOrdersCardRow from './PendingOrdersCardRow'
import { getPendingPOSInfo } from '../core/_requests'
import { IPendingPOSModel } from '../core/_models'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getPurchaseReturnOrders } from '../../orders/core/_requests'
import { PO } from '../../orders/core/_models'

type Props = {
  className: string
  language: string
  currentUser: UserModel | undefined
}

const PendingOrdersCard: React.FC<Props> = ({language, currentUser, className}) => {
  const {formatMessage} = useIntl()
  const [state, setState] = useState<PO[]>([])
  const [pendingCount, setPendingCount] = useState<number>()

  useEffect(() => {
    const getData = async () => {
      const {data} = await getPurchaseReturnOrders('?status_filter=pending&limit=5',currentUser?.token, language)
      
      if (data) {
        setState(data.data.po_list)
        setPendingCount(data.data.total_po)
      }
    }
    getData()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='h-100 d-flex flex-column justify-content-between'>
        <div className=''>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5 flex-column'>
            {/* begin:: */}
            <h3 className='card-title align-items-start justify-content-between align-items-center'>
              <span className='card-label fw-bold fs-2hx mb-1 d-flex'>{formatMessage({ id: "pending" })}</span>
              <span className='fw-bold fs-2hx text-gray-700'>{pendingCount}</span>
            </h3>
            {/* end:: */}
            <p className='text-gray-600 mb-0 fw-bold'>{formatMessage({ id: "por" })}</p>
          </div>
          {/* end::Header */}

          {/* begin::Table container */}
        <div className='card-body'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='pb-1 pt-0 fw-bold text-gray-600 '>{formatMessage({ id: "por_id" })}</th>
                <th className='pb-1 pt-0 fw-bold text-gray-600 px-3 text-nowrap'>{formatMessage({ id: "qty" })}</th>
                <th className='pb-1 pt-0 fw-bold text-gray-600 px-3 text-nowrap'>{formatMessage({ id: "total_cost" })}</th>
                <th className='pb-0 pt-0 fw-bold text-gray-600 px-3 text-nowrap mw-70px last-th'><Link to="/orders?status_filter=pending" className='btn btn-secondary px-3 py-2 fs-7 min-w-70px'>{formatMessage({ id: "view_all" })}</Link></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {state && (
                state.map((item)=>(
                  <PendingOrdersCardRow key={item.supplier_order_id} item={item} />
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}


        </div>
      </div>
    </div>
  )
}

export default PendingOrdersCard
