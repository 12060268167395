export interface IBulkModel {
    sheet: any,
    action:BulkActions,
    length_class_id?:string,
    weight_class_id?:string
}

export enum BulkActions  {
    ADD = 'add',
    EDIT = 'edit'
}