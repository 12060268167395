import {useEffect, useState} from 'react'
import {UserModel} from '../../auth'
import { getBestSellerProductsInfo } from '../core/_requests'
import { IBestSellerProductsModel } from '../core/_models'
import BestSellerProductsCardRow from './BestSellerProductsCardRow'
import { useIntl } from 'react-intl'

type Props = {
  className: string
  language: string
  currentUser: UserModel | undefined
}

const BestSellerProductsCard: React.FC<Props> = ({language, currentUser, className}) => {
  const {formatMessage} = useIntl()
  const [state, setState] = useState<IBestSellerProductsModel[]>([])

  useEffect(() => {
    const getData = async () => {
      const {data} = await getBestSellerProductsInfo(currentUser?.token, language)
      if (data) {
        setState(data.data.best_seller_products)
      }
    }
    getData()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='h-100 d-flex flex-column justify-content-between'>
        <div className=''>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5 flex-column'>
            {/* begin::Daily sales */}
            <h3 className='card-title align-items-start flex-column pb-5'>
              <span className='card-label fw-bold fs-2hx mb-1'>{formatMessage({ id: "best_seller_prods" })}</span>
            </h3>
            {/* end::Daily sales */}
          </div>
          {/* end::Header */}

          {/* begin::Table container */}
        <div className='card-body'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='pt-2 pb-0 fw-bold text-gray-600'>{formatMessage({ id: "prod_name" })}</th>
                <th className='pt-2 pb-0 fw-bold text-gray-600 px-3 text-nowrap'>{formatMessage({ id: "barcode" })}</th>
                <th className='pt-2 pb-0 fw-bold text-gray-600 px-3 text-nowrap'>{formatMessage({ id: "sold_qty" })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {state && (
                state.map((item)=>(
                  <BestSellerProductsCardRow key={item.product_id} item={item} />
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}


        </div>
      </div>
    </div>
  )
}

export default BestSellerProductsCard
