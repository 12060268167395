import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {useAuth} from '../auth'

export interface reqHeadersModel {
  token: string;
  language: string;
}

const SharedData = () => {
  const language = useLang()
  const {currentUser} = useAuth()
  return {language, currentUser}
}

export default SharedData
