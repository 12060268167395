import {FC, useState} from 'react'
import {
  ISuggestedCostTableFilter,
  SUGGESTED_COST_FILTERS,
  SuggestedCostTableFilterInitialState,
} from '../cors/_models'
import TextTableFilter from '../../common/TextTableFilter'
import {useIntl} from 'react-intl'

interface IProps {
  setResultFilters: React.Dispatch<React.SetStateAction<ISuggestedCostTableFilter>>
  resultFilters: ISuggestedCostTableFilter
}

export const SuggestedCostFilters: FC<IProps> = ({setResultFilters, resultFilters}) => {
  const {formatMessage} = useIntl()
  const [nameFilter, setNameFilter] = useState('')
  const [barcodeFilter, setBarcodeFilter] = useState('')
  const [modelFilter, setModelFilter] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const handleChange = (id: string, value: string, type: string) => {
    setResultFilters({...resultFilters, current_page: 1, [id]: value})
  }

  const clearFilters = (): void => {
    setResultFilters({...SuggestedCostTableFilterInitialState})
    setNameFilter('')
    setBarcodeFilter('')
    setModelFilter('')
    setDateFilter('')
    setStatusFilter('')
  }

  return (
    <>
      <div className='card-body border-bottom pb-10 mb-5'>
        <div className='row'>
          {/* start product Name */}
          <TextTableFilter
            id={SUGGESTED_COST_FILTERS.NAME}
            label={formatMessage({id: 'prod_name'})}
            value={nameFilter}
            setValue={setNameFilter}
            onChange={handleChange}
          />
          {/* end product Name */}

          {/* start BARCODE */}
          <TextTableFilter
            id={SUGGESTED_COST_FILTERS.BARCODE}
            label={formatMessage({id: 'barcode'})}
            value={barcodeFilter}
            setValue={setBarcodeFilter}
            onChange={handleChange}
          />
          {/* end BARCODE */}

          {/* start MODEL */}
          <TextTableFilter
            id={SUGGESTED_COST_FILTERS.MODEL}
            label={formatMessage({id: 'model_num'})}
            value={modelFilter}
            setValue={setModelFilter}
            onChange={handleChange}
          />
          {/* end MODEL */}

          {/* start DATE */}
          <TextTableFilter
            id={SUGGESTED_COST_FILTERS.DATE}
            label={formatMessage({id: 'date_added'})}
            value={dateFilter}
            setValue={setDateFilter}
            onChange={handleChange}
            type='date'
          />
          {/* end DATE */}

          {/* start STATUS */}
          <TextTableFilter
            id={SUGGESTED_COST_FILTERS.STATUS}
            label={formatMessage({id: 'status'})}
            value={statusFilter}
            setValue={setStatusFilter}
            onChange={handleChange}
            type='select'
            options={[
              {label: formatMessage({id: 'all'}), value: ''},
              {label: formatMessage({id: 'rejected'}), value: 'rejected'},
              {label: formatMessage({id: 'pending'}), value: 'pending'},
            ]}
          />
          {/* end STATUS */}

          {/* reset button */}
          <div className='col-sm-12 col-md-3'>
            <button
              type='reset'
              className='btn btn-sm btn-light mt-md-10 w-100 btn-close-white me-2'
              onClick={clearFilters}
            >
              {formatMessage({id: 'reset'})}
            </button>
          </div>
          {/* reset buttun # */}
        </div>
      </div>
    </>
  )
}

export default SuggestedCostFilters
