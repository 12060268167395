import { FC } from "react"
import { useIntl } from "react-intl"

interface IProps  {
  dumyah_activity_percentage: any
  subscription_percentage:any
  special_event_percentage:any
  paymentType: string
}

const ContractGrowthTable:FC<IProps> = ({paymentType,dumyah_activity_percentage,subscription_percentage,special_event_percentage}) => {
  const {formatMessage} = useIntl();
  const isTrade = ()=> paymentType.toLowerCase() == 'trade'
  return (
    <div className='col-sm-12'>
      <label className='text-gray-600 fw-bold fs-5 d-block mb-5'>{formatMessage({ id: "bsns_growth_lbl" })}</label>
      
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5 border-top mt-2'>
          {/* begin::Table body */}
          <tbody>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600 w-50'>{formatMessage({ id: "dumyah_stndrd" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{formatMessage({ id: "percentage_discount_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{dumyah_activity_percentage}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600 w-50'>{formatMessage({ id: "sub_loyalty_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{formatMessage({ id: "percentage_discount_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{subscription_percentage}</th>
            </tr>
            <tr className='border-bottom'>
              <th className='fs-5 fw-bold px-sm-4 text-gray-600 w-50'>{isTrade() ? formatMessage({ id: "extra_per_ramadan_label" }) : formatMessage({ id: "extra_per_ramadan_label_2" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-gray-600'>{formatMessage({ id: "contract_info" })}{formatMessage({ id: "percentage_discount_lbl" })}</th>
              <th className='fs-5 fw-bold px-sm-4 text-center text-nowrap'>{special_event_percentage}</th>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table Container */}
    </div>
  )
}

export default ContractGrowthTable
