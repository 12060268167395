import {FC} from 'react'
import {IPOInfoModel} from '../../core/_models'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type IProps = {
  item: IPOInfoModel
  setStatus: React.Dispatch<React.SetStateAction<null>>
  setModalParam: React.Dispatch<any>
}
const PurchaseTableRow: FC<IProps> = ({item, setStatus, setModalParam}) => {
  const {formatMessage} = useIntl();
  const handleStatusChange = (e: any) => {
    const value = e
    if (value === '') return
    setStatus(value)
    if (value === 'partially_available') {
      setModalParam({
        id: '10',
        key: 'available_quantity',
        value: item.item_id,
        type: 'number',
        label: formatMessage({id: 'available_quantity'}),
        errorLabel: formatMessage({id: 'please_enter_value'}),
      })
    }  else if (value === 'suggest_cost') {
      setModalParam({
        id: '2',
        key: 'suggested_cost',
        value: item.item_id,
        type: 'number',
        label: 'Suggested new cost',
        errorLabel: 'Please enter a value',
        description: 'Once the request is sent, our team will review it',
        api: 'cost-request',
      })
    } else if (value === 'out_of_stock') {
      setModalParam({
        id: '1',
        key: 'out_of_stock',
        value: item.item_id,
        type: 'text',
        label: '',
        errorLabel: '',
      })
    } else if (value === 'ready') {
      setModalParam({
        id: '3',
        key: 'ready',
        value: item.item_id,
        type: '',
      })
    }
  }

  return (
    <tr className='border-bottom border-dashed border-gray-400'>
      <th>
        <img className='table-img' width='100' height='100' src={item.image} />
      </th>
      <td>{item.barcode}</td>
      <td>{item.model}</td>
      <td className='w-250px'>{item.name}</td>
      <td>{item.quantity}</td>
      <td>{item.received_quantity}</td>
      <td>{item.cost}</td>
      <td>{item.price}</td>
      <td>{item.total_cost.toFixed(2)}</td>
      {/* <td>
        <button value='suggest_cost' className='btn p-0' onClick={handleStatusChange}>
          suggest cost
        </button>
      </td> */}
      <td>
        {['pending','قيد الانتظار'].includes(item.status) ? <DropdownButton onSelect={handleStatusChange} className='table-select-action' variant="secondary" id='dropdown-basic-button' title={formatMessage({ id: "select_status" })}>
          <Dropdown.Item eventKey="ready" as="button">{formatMessage({ id: "ready" })}</Dropdown.Item>
          <Dropdown.Item eventKey="out_of_stock" as="button">{formatMessage({ id: "out_of_stock" })}</Dropdown.Item>
          <Dropdown.Item eventKey="partially_available" as="button">{formatMessage({ id: "partially_available" })}</Dropdown.Item>
        </DropdownButton> : <>{formatMessage({id: item.status})}</>}
      </td>
    </tr>
  )
}

export default PurchaseTableRow
