import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IProductProps, productActionType} from '../../cors/reducers'
import {useIntl} from 'react-intl'
import Loader from '../../../common/Loader'

const ProductDefaultImage: FC<IProductProps> = ({prodState, prodDispatch}) => {
  const {formatMessage} = useIntl()
  const [image, setImage] = useState(prodState.image.value)
  const [isLoading,setIsLoading] = useState(false)
  const handleChange = (files: FileList | null) => {
    
    if (files) {
      setIsLoading(true)
      var reader = new FileReader()
      reader.onload = function (e: any) {
        prodDispatch({type: productActionType.image, payload: {id: 'image', value: files[0]}})
        setImage(e.target.result)
        setIsLoading(false)
      }
      reader.readAsDataURL(files[0])
    }
  }

  return (
    <div className='card default-image-box'>
      <div className='card-body'>
        <h3 className='card-title mb-8'>
          <span className='card-label fw-bold fs-3'>
            {formatMessage({id: 'default_image'})} <sup className='text-danger'>*</sup>
          </span>
        </h3>
        <div className='w-75 mb-5 mx-auto shadow rounded-2 position-relative'>
          <div className='position-absolute overflow-hidden shadow rounded-circle d-flex align-items-center justify-content-center bg-white upload-img-pin'>
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg,image/jpg, image/webp'
              onChange={(e) => handleChange(e.target.files)}
              className='opacity-0 position-absolute z-index-1 cursor-pointer'
            />
            <img width='17' src={toAbsoluteUrl('/media/logos/pen-solid-edit.svg')} />
          </div>
          <img className='rounded-2' src={image} width='100%' />
        </div>
          <p className={`${prodState.image.error && 'text-danger'} mb-0 text-center fw-bold`}>
            {prodState.image.error
              ? prodState.image.msg
                ? prodState.image.msg
                : formatMessage({id: 'required_field'})
              : formatMessage({id: 'default_image_hint'})}
          </p>

      </div>
      {isLoading && <Loader isFull={true} />}
    </div>
  )
}

export default ProductDefaultImage
