import {useState} from 'react'
import BulkUploadField from './components/BulkUploadField'
import BulkImageField from './components/BulkImageField'
import SubmitButtonRow from './components/SubmitButtonRow'
import {PRODUCTS_BULK_TEMPLATE} from './core/_requests'
import SharedData from '../../common/SharedData'
// import WeightLengthFields from './components/WeightLengthFields'

const BulkCard = () => {
  const {currentUser} = SharedData()
  const [bulkFile, setBulkFile] = useState<any>({
    value: null,
    error: false,
  })
  const [imagesFile, setImagesFile] = useState<any>({
    value: null,
    error: false,
  })


  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <>
            <div className='d-flex flex-column gap-9'>
              {/* Bulk sheet */}
              <BulkUploadField
                hasError={bulkFile.error}
                id='sheet'
                setField={setBulkFile}
                isRequired={false}
                templateUrl={PRODUCTS_BULK_TEMPLATE}
                isDownloadable={true}
                token={currentUser?.token}
                user={currentUser}
              />

              

              <BulkImageField
                hasError={imagesFile.error}
                id='images'
                setField={setImagesFile}
                isRequired={false}
              />

              <SubmitButtonRow
                bulkFile={bulkFile}
                setBulkFile={setBulkFile}
                imagesFile={imagesFile}
                setImagesFile={setImagesFile}
              />
            </div>
          </>
        </div>
      </div>
    </>
  )
}

export default BulkCard
