import { useIntl } from 'react-intl';
import PageHeader from '../common/PageHeader'
import RemoveDiscountBulkCard from './add-special/RemoveDiscountBulkCard'
import React from 'react';
const RemoveSpecialBulk = () => {
    const {formatMessage} = useIntl();
    return (
        <>
            <PageHeader title={formatMessage({ id: "delete_discount_bulk" })} />
            <RemoveDiscountBulkCard />
        </>
    )
}

export default RemoveSpecialBulk