import {FC, useRef} from 'react'
import {ISelectOptions} from '../cors/_models'
import { useIntl } from 'react-intl'

type IProps = {
  label?: string
  isRequired?: boolean
  id: string
  type?: string
  onChange?: any
  value?: any
  options?: ISelectOptions[]
  errorMsg?: string
  hasError?: boolean
  readOnly?: boolean
  min?: string
  hint?:string
}

const InputField: FC<IProps> = ({
  label,
  isRequired,
  id,
  type,
  onChange,
  value,
  options,
  errorMsg,
  hasError,
  readOnly,
  min,
  hint
}) => {
  const {formatMessage} = useIntl();
  const inputRef = useRef<any>('')

  return (
    <div>
      {label && (
        <label className='form-label fw-bold'>
          {label} {isRequired && <sup className='text-danger'>*</sup>} {hint && <small className='text-gray-600'>{hint}</small>}
        </label>
      )}
      <div>
        {type && type == 'select' ? (
          <>
            <select
              value={value}
              onChange={onChange}
              className='form-control w-100'
              id={id}
              name={id}
              ref={inputRef}
              data-isrequired={isRequired}
            >
              {options &&
                options.map((item: ISelectOptions) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </select>
            {isRequired && hasError && (
              <label className='form-label fw-bold text-danger'>
                {errorMsg ? errorMsg : formatMessage({ id: "required_field" })}
              </label>
            )}
          </>
        ) : (
          <>
            {type === 'number' ? (
              <input
                value={value}
                onChange={onChange}
                className={`form-control w-100 no-arrows ${readOnly && 'form-control-solid'}`}
                type={type || 'text'}
                id={id}
                name={id}
                readOnly={readOnly || false}
                ref={inputRef}
                data-isrequired={isRequired}
                min={min}
                onWheel={(e: any) => e.target.blur()}
                autoComplete='off'
              />
            ) : (
              <input
                value={value || ''}
                onChange={onChange}
                className={`form-control w-100 no-arrows ${readOnly && 'form-control-solid'}`}
                type={type || 'text'}
                id={id}
                name={id}
                readOnly={readOnly || false}
                ref={inputRef}
                data-isrequired={isRequired}
                min={min}
                autoComplete='off'
              />
            )}

            {isRequired && hasError && (
              <label className='form-label fw-bold text-danger'>
                {errorMsg ? errorMsg : formatMessage({ id: "required_field" })}
              </label>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default InputField
