/* eslint-disable jsx-a11y/anchor-is-valid */
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import SharedData from '../common/SharedData'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import {getGrnInvoces} from './core/_requests'
import {IGRNInvoicesModel} from './core/_models'
import GrnRow from './components/grn/GrnRow'
type Props = {
  className?: string
}

const GRNInvoices: React.FC<Props> = ({className}) => {
  const {formatMessage} = useIntl()
  const {orderID} = useParams()

  const {currentUser, language} = SharedData()
  const [invoiceData, setInvoiceData] = useState<IGRNInvoicesModel>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getDate = async () => {
      setIsLoading(true)
      const {data: data} = await getGrnInvoces(orderID || '', currentUser?.token, language)
      setInvoiceData(data)
      console.log(data)
      setIsLoading(false)
    }
    getDate()
  }, [])

  if (isLoading) {
    return <Loader isFull={true} />
  }

  return (
    <div className='card'>
      <div className='card-body py-3'>
        <div className='w-md-500px'>
          <div className='d-flex border-bottom border-dark pb-5 pt-5 mb-10'>
            <img src={invoiceData?.GRN.dumyah_logo} />
          </div>
          <div className=''>
            <h1 className='mb-9 text-primary'>
              {formatMessage({
                id:
                  invoiceData?.GRN.supplier_order_info.is_returned_type === 1
                    ? 'goods_returned_notes'
                    : 'goods_received_notes',
              })}
            </h1>
            <div className='d-flex flex-column fw-bold fs-4 gap-4 border-bottom border-dark pb-4 mb-4'>
              <GrnRow
                value={invoiceData?.GRN.supplier_order_info.GRN_number}
                label={formatMessage({id: 'grn_number'})}
              />

              <GrnRow value={orderID} label={formatMessage({id: 'po_number'})} />

              <GrnRow
                value={invoiceData?.GRN.supplier_order_info.supplier_name}
                label={formatMessage({id: 'supplier_name'})}
              />

              <GrnRow
                value={invoiceData?.GRN.payment_type}
                label={formatMessage({id: 'payment_type'})}
              />

              <GrnRow
                value={invoiceData?.GRN.supplier_order_info.date_added}
                label={formatMessage({id: 'date_added'})}
              />
            </div>

            {invoiceData?.GRN.supplier_order_item_list.map((item, index) => (
              <div
                key={index}
                className='d-flex flex-column fw-bold fs-4 gap-2 border-bottom border-dark pb-4 mb-4'
              >
                <p className=''>{decodeURIComponent(item.name)}</p>
                <p className=''>
                  {formatMessage({id: 'model'})}: {item.model}
                </p>
                <p className=''>
                  {formatMessage({id: 'barcode'})}: {item.barcode}
                </p>
                <div className='d-flex'>
                  <p className='w-25 mb-0'>{item.received_quantity}X</p>
                  <p className='w-25 mb-0'>{item.cost_excluding_tax}</p>
                  <p className='w-25 mb-0'>{item.tax_type}</p>
                  <p className='w-25 mb-0 border border-dark px-1'>
                    {item.total_cost_excluding_tax}
                  </p>
                </div>
              </div>
            ))}

            <div className='d-flex flex-column fw-bold fs-4 gap-2 border-bottom border-dark pb-4 mb-4'>
              <GrnRow
                value={invoiceData?.GRN.supplier_order_total.total_received_quantity}
                label={formatMessage({id: 'num_items'})}
              />
              <GrnRow
                value={invoiceData?.GRN.supplier_order_total.total_cost_excluding_tax}
                label={formatMessage({id: 'sub_total'})}
              />
            </div>

            <div className='d-flex flex-column fw-bold fs-4 gap-2 border-bottom border-dark pb-4 mb-4'>
              <GrnRow
                value={invoiceData?.GRN.tax_list[0].val}
                label={formatMessage({id: 'tax16'})}
              />
            </div>

            <div className='mb-5 fw-bold fs-4'>
              <GrnRow
                value={invoiceData?.GRN.supplier_order_total.total_cost}
                label={formatMessage({id: 'grand_total'})}
              />
            </div>
            <p className='fw-bold fs-4'>{formatMessage({id: 'grn_invoice_note'})}</p>
          </div>
        </div>

        {invoiceData?.invoices && invoiceData.invoices.length > 0 && (
          <>
            <h1 className='mb-9 text-primary mt-10'>{formatMessage({id: 'invoices'})}</h1>
            <div className='d-flex flex-column gap-5 mw-50 pb-3'>
              {invoiceData?.invoices.map(({invoice_image}) => (
                <img key={`${invoice_image}`} src={invoice_image} />
              ))}
            </div>
          </>
        )}
        {/* <NormalMessage msg={formatMessage({ id: "no_data" })} />  */}
      </div>
    </div>
  )
}

export default GRNInvoices
