import { toAbsoluteUrl } from "../../../../_metronic/helpers"

export enum productActionType {
  text,
  editor,
  isLoading,
  hasError,
  product_image,
  image,
  fieldRequired,
  clearErrors,
  fillProductData,
  isRendering,
  validationErrors,
  barcode_generated
}
type IBooleanString = {
  error: boolean
  value: string
  msg?:string
}
export interface productState {
  isLoading: boolean
  isRendering?: boolean
  hasError: boolean
  image: {
    error: boolean
    value: any
    msg: string
  }
  product_image: any[]
  product_specials: any[]
  deleted_images: any[]

  barcode: IBooleanString
  generate_flag: boolean
  model: IBooleanString
  companyCode: string

  categoryName: IBooleanString
  categoryID: IBooleanString

  productNameEn: IBooleanString
  productNameAr: IBooleanString
  productDescriptionEn: IBooleanString
  productDescriptionAr: IBooleanString

  aboutEn: IBooleanString
  aboutAr: IBooleanString

  manufacturer_id: IBooleanString

  dimensionL: string
  dimensionW: string
  dimensionH: string
  lengthClass: string

  weight: string
  weightClass: string

  view: string
  category: any

  color: string
  gender: string
  recommendedAge: IBooleanString
  youtubeUrl: string
  expiryDate: IBooleanString

  quantity: IBooleanString

  // costPrice: IBooleanString
  salePrice: IBooleanString

  tax_class_id: IBooleanString
}

const TempProductInitVal: IBooleanString = {
  error: false,
  value: '',
}

export const productInitialState: productState = {
  isLoading: false,
  isRendering:false,
  hasError: false,
  image: {
    error: false,
    value: toAbsoluteUrl('/media/logos/img-placeholder.png'),
    msg: ''
  },
  product_image: [],
  deleted_images: [],
  product_specials: [],
  barcode: TempProductInitVal,
  generate_flag: false,
  model: TempProductInitVal,
  companyCode: '',

  categoryName: TempProductInitVal,
  categoryID: TempProductInitVal,

  productNameEn: TempProductInitVal,
  productNameAr: TempProductInitVal,
  productDescriptionEn: TempProductInitVal,
  productDescriptionAr: TempProductInitVal,

  aboutEn: TempProductInitVal,
  aboutAr: TempProductInitVal,

  manufacturer_id: TempProductInitVal,

  dimensionL: '',
  dimensionW: '',
  dimensionH: '',
  lengthClass: '',

  weight: '',
  weightClass: '',

  view: '',
  category: '',

  color: '',
  gender: '',
  recommendedAge: TempProductInitVal,
  youtubeUrl: '',
  expiryDate: TempProductInitVal,

  quantity: TempProductInitVal,

  // costPrice: TempProductInitVal,
  salePrice: TempProductInitVal,

  tax_class_id: TempProductInitVal,
}

export interface IProductProps {
  prodState: productState
  prodDispatch: React.Dispatch<{
    type: productActionType
    payload: any
  }>
}

export const fieldReducer = (
  state: productState,
  action: {type: productActionType; payload?: any}
) => {
  const {type} = action
  let id, value
  if (action.payload) {
    if (action.payload.id)
      id = action.payload.id
    if (action.payload.value)
      value = action.payload.value
  }
  switch (type) {
    case productActionType.clearErrors:
      Object.keys(state).map((item) => {
        const key = state[item as keyof productState]
        if (typeof key === 'object' && key.hasOwnProperty('error')) {
              state = {
              ...state,
            [item]: {...key, error: false,msg:''},
              }
          }
      })
      state.hasError = false;
      return {...state}

    case productActionType.fieldRequired:
      const keyObj = state[id as keyof productState]
      if (typeof keyObj === 'object') {
        return {
          ...state,
          [id]: {
            ...keyObj,
            error: value,
          },
        }
      } else return {...state}
    case productActionType.validationErrors:
      const {errors} = action.payload;
      let stateTemp:any = {...state}
      for (let key in errors) {
        const alert = errors[key];
        if (key === 'about_product') {
          let aboutProduct = 'aboutEn'
          let errorMsg = ''
          if (errors[key]['6']) {
            aboutProduct = 'aboutAr'
            errorMsg = errors[key]['6']
          } else {
            errorMsg = errors[key]['1']
          }
          
          stateTemp = {
            ...stateTemp,
            [aboutProduct]: {
              ...stateTemp[aboutProduct],
              error:true,
              msg: errorMsg
            }
          }
          
        } else if (key === 'name') {
          let productName = 'productNameEn'
          let errorMsg = ''
          if (errors[key]['6']) {
            productName = 'productNameAr'
            errorMsg = errors[key]['6']
          } else {
            errorMsg = errors[key]['1']
          }
          
          stateTemp = {
            ...stateTemp,
            [productName]: {
              ...stateTemp[productName],
              error:true,
              msg: errorMsg
            }
          }
        } else if (key === 'price') {
          stateTemp = {
            ...stateTemp,
            ['salePrice']: {
              ...stateTemp['salePrice'],
              error:true,
              msg: alert
            }
          }
        } else if (key === 'supplier_quantity') {
          stateTemp = {
            ...stateTemp,
            ['quantity']: {
              ...stateTemp['quantity'],
              error:true,
              msg: alert
            }
          }
        } else {
          stateTemp = {
            ...stateTemp,
            [key]: {
              ...stateTemp[key],
              error:true,
              msg: alert
            }
          }
        }
      }
      
      return {...stateTemp}
    case productActionType.barcode_generated:
      return {
        ...state,
        generate_flag: true
      }
    case productActionType.text:
    case productActionType.editor:
      const key = state[id as keyof productState]
      if (typeof key === 'object') {
        return {
          ...state,
          [id]: {
            ...key,
            value,
          },
        }
      } else {
        return {
          ...state,
          [id]: value,
        }
      }
      
    case productActionType.product_image:
      if (value === 'remove') {
        const {index} = action.payload
        let deleted_images  = state.deleted_images;
        if(state.product_image[index].product_image_id) {
          deleted_images = [state.product_image[index].product_image_id, ...state.deleted_images];
        }

        const restImages = [...state.product_image]
        restImages.splice(index, 1)
        return {
          ...state,
          product_image: [...restImages],
          deleted_images: deleted_images
        }
      } else {
        return {
          ...state,
          product_image: [...state.product_image, value],
        }
      }
    case productActionType.image:
      return {
        ...state,
        image: {...state.image, value},
      }
    case productActionType.isLoading:
      return {
        ...state,
        isLoading: !state.isLoading,
        hasError: false,
      }
    case productActionType.isRendering:
      return {
        ...state,
        isRendering: !state.isRendering,
      }
    case productActionType.hasError:
      return {
        ...state,
        isLoading: false,
        hasError: value,
      }
    case productActionType.fillProductData:
      const data = action.payload;
      
      return {
        ...state,
        productDescriptionEn: {
          value:data.product_description[1].description,
          error:false
        },
        productDescriptionAr: {
          value:data.product_description[6].description,
          error:false
        },

        productNameEn: {
          error:false,
          value:data.product_description[1].name,
        },
        productNameAr: {
          error:false,
          value:data.product_description[6].name,
        },

        aboutEn:{
          error:false,
          value:data.product_description[1].about_product,
        }, 
        aboutAr:{
          error:false,
          value:data.product_description[6].about_product,
        },

        barcode: {
          error:false,
          value:data.product_info.barcode,
        },
        color: data.product_info.color_id,
        companyCode: data.product_info.company_code,

        categoryName: {
          error:false,
          value:data.product_info.category,
        },

        categoryID: {
          error:false,
          value:data.product_info.categoryID,
        },

        // costPrice:{
        //   error:false,
        //   value:data.product_info.cost_price
        // },
        salePrice: {
          error:false,
          value:data.product_info.price
        },

        gender: data.product_info.gender_id,
        dimensionL: data.product_info.length,
        dimensionW: data.product_info.width,
        dimensionH: data.product_info.height,

        model:{
          error:false,
          value: data.product_info.model
        },
        recommendedAge: {
          error:false,
          value: data.product_info.recommended_age_id
        },

        quantity: {
          error:false,
          value: data.product_info.supplier_quantity
        },

        tax_class_id: {
          error:false,
          value: data.product_info.tax_type
        },
        weight: data.product_info.weight,
        weightClass: data.product_info.weight_class_id,
        view: data.product_info.view,
        category: data.product_info.category,
        youtubeUrl: data.product_info.youtube_video_url,
        expiryDate: {
          error: false,
          value: data.product_info.expiry_date
        },
        manufacturer_id: {
          error:false,
          value: data.product_info.manufacturer_id
        },
        image: {
          error:false,
          value:data.product_image
        },
        product_image: data.product_images,
        product_specials: data.product_specials,
        deleted_images: [],
      }
    default:
      return state
  }
}

