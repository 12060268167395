import {
  DAILY_DEAL_ACTIONS,
  IProductModel,
  PRODUCT_ACTIONS,
  PRODUCT_REQUEST_TYPE,
} from './_models'
import axios from 'axios'
import {productState} from './reducers'
import {prepareFilterParams} from '../../orders/core/_requests'

const API_URL = process.env.REACT_APP_API_URL
export const ADD_PRODUCT = `${API_URL}/api/partner-portal/product`
export const VALIDATE_ADD_PRODUCT = `${API_URL}/api/partner-portal/product/validate-duplicate-barcode`
export const EDIT_PRODUCT = `${API_URL}/api/partner-portal/product/edit-product`
export const GET_PRODUCT_INFO = `${API_URL}/api/partner-portal/product/product`
export const PRODUCT_ACTION = `${API_URL}/api/partner-portal/product/` // ex: out-of-stock?product_id=47979
export const GET_LENGTH_CLASSES = `${API_URL}/api/partner-portal/common/length-classes`
export const GET_WEIGHT_CLASSES = `${API_URL}/api/partner-portal/common/weight-classes`
export const GET_BRANDS = `${API_URL}/api/partner-portal/common/brands`
export const GET_COLORS = `${API_URL}/api/partner-portal/common/colors`
export const GET_RECOMMENDED_AGES = `${API_URL}/api/partner-portal/common/recommended-ages`
export const GET_TAX = `${API_URL}/api/partner-portal/common/tax-types`
export const GET_GENDERS = `${API_URL}/api/partner-portal/common/genders`
export const GET_PRODUCTS_LIST = `${API_URL}/api/partner-portal/product/products`
export const GET_PRODUCTS_REQ_LIST = `${API_URL}/api/partner-portal/product/product-request`
export const GET_ADD_DISCOUNT_REQ_LIST = `${API_URL}/api/partner-portal/special/request`
export const GET_DAILY_DEAL_LIST = `${API_URL}/api/partner-portal/special/get-daily-deals`
export const DELETE_DAILY_DEAL_PRODUCT = `${API_URL}/api/partner-portal/special/daily-deals`
export const ADD_ADD_DAILY_DEAL_PRODUCT = `${API_URL}/api/partner-portal/special/daily-deals`
export const APPLY_DAILY_DEAL_PRODUCT = `${API_URL}/api/partner-portal/special/apply-daily-deals`
export const APPLIED_DAILY_DEAL_LIST = `${API_URL}/api/partner-portal/special/applied-daily-deal`
export const SET_PRODUCT_TO_INACTIVE = `${API_URL}/api/partner-portal/special/deactivate-discount`
export const GET_LOSS_PRODUCTS_LIST = `${API_URL}/api/partner-portal/po/loss-products-list`
export const AUTO_COMPLETE_CATEGORIES = `${API_URL}/api/partner-portal/product/category-complete`

export function addProduct(
  language: string,
  token: string | undefined,
  data: productState,
  productID: string | undefined | null
) {
  const formData = new FormData()
  let url = ADD_PRODUCT
  if (typeof data.image.value != 'string') formData.append('image', data.image.value)
  if (productID) {
    url = EDIT_PRODUCT
    formData.append('product_id', productID)
  }

  formData.append('barcode', data.barcode.value)
  if(!productID){
    formData.append('category', data.categoryName.value)
  }
  formData.append('categoryID', data.categoryID.value)
  formData.append('generate_flag', String(data.generate_flag))
  // formData.append('quantity_per_box', data.quantityPerBox || '0')
  // formData.append('cost_price', data.costPrice.value)
  formData.append('price', data.salePrice.value)
  formData.append('supplier_quantity', data.quantity.value)
  //formData.append('tax_class_id', data.tax_class_id.value)
  formData.append('tax_type', data.tax_class_id.value)
  formData.append('manufacturer_id', data.manufacturer_id.value)
  formData.append('model', data.model.value)
  formData.append('company_code', data.companyCode)
  formData.append('product_description[1][name]', data.productNameEn.value)
  formData.append('product_description[1][description]', data.productDescriptionEn.value)
  formData.append('product_description[6][name]', data.productNameAr.value)
  formData.append('product_description[6][description]', data.productDescriptionAr.value)

  if (data.aboutEn.value) {
    formData.append('product_description[1][about_product]', data.aboutEn.value)
  }

  if (data.aboutAr.value) {
    formData.append('product_description[6][about_product]', data.aboutAr.value)
  }

  formData.append('color_id', data.color)
  formData.append('gender_id', data.gender)

  formData.append('length', data.dimensionL)
  formData.append('length_class_id', data.lengthClass)
  formData.append('width', data.dimensionW)
  formData.append('height', data.dimensionH)
  formData.append('recommended_age_id', data.recommendedAge.value)

  formData.append('weight', data.weight)
  formData.append('weight_class_id', data.weightClass)
  formData.append('youtube_video_url', data.youtubeUrl)
  formData.append('expiry_date', data.expiryDate.value || '0000-00-00')

  data.product_image.forEach((file) => {
    if (file instanceof File) formData.append('product_image[]', file)
  })

  data.deleted_images.forEach((product_image_id) => {
    formData.append('deleted_images[]', product_image_id)
  })

  return axios
    .post<any>(`${url}`, formData, {
      headers: {token: token || '', language, 'content-type': 'multipart/form-data'},
    })
    .catch((error) => {

      if (error.response && error.response.status === 400) {
        return {errors:error.response.data.error}
      }
    })
}

export function checkDuplicateBarcode(
    barcode: string | undefined | null,
    token: string | undefined,
    language: string,
) {

  return axios.post<any>(`${VALIDATE_ADD_PRODUCT}`, { barcode: barcode },
  {
    headers: {token: token || '', language: language },
  });
}
export function getLengthClasses(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_LENGTH_CLASSES}`, {headers: {token: token || '', language}})
}
export function setDiscountToInactive(
    token: string | undefined, language: string, product_special_id: string |undefined
){
  const data = { product_special_id };
  return axios.put<any>(
      `${SET_PRODUCT_TO_INACTIVE}`,
      data,
      {
        headers: { token: token || '', language: language }
      }
  );
}
export function getCategoryAutoComplete(
    token: string | undefined,
    language: string,
    name: string
){
  return axios.get<any>(
      `${AUTO_COMPLETE_CATEGORIES}`,
      {
        headers: { token: token || '', language},
        params: { name: name }
      }
  );
}
export function getWeightClasses(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_WEIGHT_CLASSES}`, {headers: {token: token || '', language}})
}
export function getColors(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_COLORS}`, {headers: {token: token || '', language}})
}
export function getRecommendedAges(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_RECOMMENDED_AGES}`, {headers: {token: token || '', language}})
}
export function getTax(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_TAX}`, {headers: {token: token || '', language}})
}
export function getGenders(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_GENDERS}`, {headers: {token: token || '', language}})
}
export function getBrands(token: string | undefined, language: string) {
  return axios.get<any>(`${GET_BRANDS}`, {headers: {token: token || '', language}})
}

// GET PRODUCTS LIST
export function getProductsList(
  filtersObj: any,
  token: string | undefined,
  language?: string,
  type?: PRODUCT_REQUEST_TYPE
) {
  return axios.get<{data: {products: IProductModel[]; total_products: number}}>(
    `${GET_PRODUCTS_LIST}${filtersObj}${type ? '&request_type=' + type : ''}`,
    {headers: {token: token || '', language: language || 'en'}}
  )
}

// GET PRODUCTS LIST REQ
export function getProductsListReq(
  filtersObj: any,
  token: string | undefined,
  language?: string,
  type?: PRODUCT_REQUEST_TYPE
) {
  if (filtersObj === '') filtersObj = '?'
  return axios.get<any>(
    `${GET_PRODUCTS_REQ_LIST}${filtersObj}${type ? '&request_type=' + type : ''}`,
    {headers: {token: token || '', language: language || 'en'}}
  )
}

// GET ADD DISCOUNT REQ LIST
export function getAddDiscountReq(filtersObj: any, token: string | undefined, language?: string) {
  return axios.get<any>(`${GET_ADD_DISCOUNT_REQ_LIST}${filtersObj}`, {
    headers: {token: token || '', language: language || 'en'},
  })
}

// UPDATE PRODUCT
export function updateProduct(data: any, token: string | undefined, language?: string) {
  return axios.post<any>(`${EDIT_PRODUCT}`, data, {
    headers: {token: token || '', language: language || 'en'},
  })
}

// UPDATE PRODUCT
// like:
// out-of-stock?product_id=47979
// inactivate-product?product_id=47986
export function productAction(
  filtersObj: any,
  action: PRODUCT_ACTIONS,
  token: string | undefined,
  language?: string
) {
  return axios.put<any>(
    `${PRODUCT_ACTION}${action}?${prepareFilterParams(filtersObj)}`,
    {},
    {headers: {token: token || '', language: language || 'en'}}
  )
}

// GET PRODUCT INFO
export function getProduct(product_id: number | string, language: string, token: string | undefined) {
  return axios.get<any>(`${GET_PRODUCT_INFO}?product_id=${product_id}`, {
    headers: {token: token || '', language: language },
  })
}

export function getDailyDealList(token: string | undefined, language?: string) {
  return axios.get<any>(
      `${GET_DAILY_DEAL_LIST}`,
      {headers: {token: token || '', language: language || 'en'}}
  )
}

export function dailyDealAction(
    supplier_deal_id: number | string,
    action: DAILY_DEAL_ACTIONS,
    token: string | undefined,
) {
  return axios.delete<any>(` ${DELETE_DAILY_DEAL_PRODUCT}?supplier_deal_id=${supplier_deal_id}`, {
    headers: {token: token || ''},
  })
}

export async function addDailyDealProduct(token: string | undefined, language: string,
                                          data: {
                                            discount_date: string;
                                            quantity: number;
                                            discount_percentage: string;
                                            barcode: string
                                          }
) {
  return axios.get<any>(` ${ADD_ADD_DAILY_DEAL_PRODUCT}?barcode=${data.barcode}&discount_date=${data.discount_date}&quantity=${data.quantity}&discount_percentage=${data.discount_percentage}`, {
    headers: {token: token || '', language: language || 'en'},
  })
}

export function applyDailyDealProduct(token: string | undefined, language: string) {
  return axios.get<any>(` ${APPLY_DAILY_DEAL_PRODUCT}`, {
    headers: {token: token || '',language: language || 'en'},
  })
}

export function getAppliedDailyDealList(token: string | undefined, language: string) {
  return axios.get<any>(` ${APPLIED_DAILY_DEAL_LIST}`, {
    headers: {token: token || '', language: language || 'en'},
  })
}

export function getLossOfOpportunityProductsList(filtersObj: any, token: string | undefined,language:string) {
  return axios.get<any>(
      `${GET_LOSS_PRODUCTS_LIST}${filtersObj}${filtersObj === '' ? '?' : '&'}`,
      {headers: {token: token || '',language: language}}
  )
}