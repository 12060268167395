import { useIntl } from 'react-intl';
import PageHeader from '../../modules/common/PageHeader'
import MyDashboard from '../../modules/my-dashboard/MyDashboard'
import './dashboard.css';
import OrangeButton from "../../modules/common/OrangeButton";
import React from "react";

const MyDashboardPage = () => {
  const {formatMessage} = useIntl();
  return (
    <>
        <div className='card-header border-0 pt-5 mb-7 justify-content-between align-items-center d-flex flex-column-p'>

            <div className='d-flex align-items-center'>
                <PageHeader title={formatMessage({id: "DASHBOARD.PAGE_NAME"})}/>
                <a href="https://www.youtube.com/watch?v=NN2AYpc5O3A" target="_blank" rel="noopener noreferrer" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '4px'}}>
                    {formatMessage({ id: "video_tutorial" })}
                </a>
            </div>

            <div>
                <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1' style={{fontSize: '12px', color: 'gray'}}>
                   {formatMessage({id: "DASHBOARD.DAILY_DEAL_LABEL"})} </span>
                </h3>
                <OrangeButton
                    title={formatMessage({id: "DASHBOARD.ADD_DAILY_DEAL"})}
                    url={'/daily-deal/list'}
                />
            </div>

        </div>
      <MyDashboard />
    </>
  )
}

export default MyDashboardPage