import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import InTransitReportTableRow from './InTransitReportsTableRow'
import {IInTransitReportModel, ITotalInTransitRow} from '../core/_models'
import '../../../stylesheet/transactionReport.css'

interface IProps {
    data: IInTransitReportModel[];
    inTransitTotal?: ITotalInTransitRow | null;
}

const InTransitReportsTable: FC<IProps> = ({data, inTransitTotal}) => {
    const {formatMessage} = useIntl()
    const [modalParam, setModalParam] = useState<{ show: boolean, data: IInTransitReportModel | null }>({
        show: false,
        data: null
    });

    const toggleModal = (params?: IInTransitReportModel) => {
        setModalParam((prev) => ({
            data: params ? params : prev.data,
            show: !modalParam.show
        }))
    }

    return (
        <>
            <table className='table align-middle gs-0 gy-5'>
                <thead>
                <tr>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                        {formatMessage({id: 'image'})}
                    </th>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                        {formatMessage({id: 'barcode'})}
                    </th>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                        {formatMessage({id: 'model_num'})}
                    </th>
                    <th className='fw-bold p-0 text-uppercase'>{formatMessage({id: 'prod_name'})}</th>
                    <th className='fw-bold p-0 px-2 text-uppercase'>{formatMessage({id: 'sold_qty'})}</th>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                        {formatMessage({id: 'avg_cost_price'})}
                    </th>
                    <th className='fw-bold p-0 px-2 text-nowrap text-uppercase'>
                        {formatMessage({id: 'total_cost_price'})}
                    </th>
                </tr>
                </thead>
                <tbody>
                {data && data.map((item, index) => (
                    <InTransitReportTableRow
                        showDetails={toggleModal}
                        key={`${item.product_id}-${index}`}
                        item={item}
                    />
                ))}
                <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>  {formatMessage({id: 'total'})}</td>
                        <td className='green-text td-border'>
                            {String(inTransitTotal?.total_quantity || 0)}
                        </td>
                        <td className='green-text td-border'>
                        </td>
                        <td className='green-text td-border'>
                            {String(inTransitTotal?.total_cost_price || 0)}
                        </td>
                    </tr>
                </>
                </tbody>
            </table>
        </>
    )
}

export default InTransitReportsTable
