import { FC } from 'react'
import { ISuggestedCostModel } from '../cors/_models'
import SuggestedCostTableRow from './SuggestedCostTableRow'
import { useIntl } from 'react-intl'
interface IProps {
    data: ISuggestedCostModel[]
}
const SuggestedCostTable:FC<IProps> = ({data}) => {
  const {formatMessage} = useIntl()
  return (
    <>
        {/* begin::Table container */}
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({id: 'image'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({id: 'po'})} #</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({id: 'prod_name'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({id: 'barcode'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase'>{formatMessage({id: 'model_num'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({id: 'cost_price'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({id: 'sugg_cost'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({id: 'date_added'})}</th>
                <th className='fw-bold p-0 px-3 text-uppercase text-nowrap'>{formatMessage({id: 'status'})}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data && (
                data.map((item)=>(
                  <SuggestedCostTableRow key={item.supplier_order_id} item={item} />
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        {/* end::Table container */}
    </>
  )
}

export default SuggestedCostTable