import {FC, useEffect, useState} from 'react'
import {DAILY_DEAL_ACTIONS, IDailyDealModel} from '../cors/_models'
import DailyDealTableRow from '../daily-deal/DailyDealTableRow'
import DailyDealForm from '../daily-deal/DailyDealForm'
import {useIntl} from 'react-intl'
import NormalMessage from '../../common/NormalMessage'
import {dailyDealAction, getDailyDealList} from "../cors/_requests";
import SharedData from "../../common/SharedData";

interface IProps {
    products: IDailyDealModel[]
    refreshData: () => Promise<void>;
}

const DailyDealTable: FC<IProps> = ({products, refreshData}) => {

    const {formatMessage} = useIntl()
    const {currentUser, language} = SharedData()

    const doDailyDealAction = async (action: DAILY_DEAL_ACTIONS, supplierDealId: number): Promise<any> => {
        try {
            await dailyDealAction(
                supplierDealId,
                action,
                currentUser?.token
            )
            refreshData();
        } catch {
            return
        }
    }

    return (
        <>
            <DailyDealForm
                currentUser={currentUser}
                language={language}
                refreshData={refreshData}
            />

            {products.length > 0 ? (
                    <table className='table align-middle gs-0 gy-5'>
                        <thead>
                        <tr>
                            <th className='fw-bold p-0'>{formatMessage({id: 'image'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'prod_name'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'barcode'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'model_num'})}</th>
                            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'qty'})}</th>
                            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'sale_price'})}</th>
                            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'cost_price'})}</th>
                            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'discount_percentage'})}</th>
                            <th className='fw-bold p-0 px-3 text-nowrap'>{formatMessage({id: 'discount_date'})}</th>
                            <th className='fw-bold p-0'>{formatMessage({id: 'actions'})}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {products &&
                            products.map((product) => (
                                <DailyDealTableRow
                                    onAction={doDailyDealAction}
                                    key={product.supplier_deal_id}
                                    product={product}
                                />
                            ))}
                        </tbody>
                    </table>
            ) : (
                <NormalMessage msg={formatMessage({id: 'no_data'})}/>
            )}
        </>
    )
}

export default DailyDealTable
