import { useEffect, useState} from 'react'
import SharedData from '../../../common/SharedData'
import {getApplySpecialProducts} from '../core/_requests'
import Loader from '../../../common/Loader'
import PendingSpecialTable from './PendingSpecialTable'
import PageHeader from '../../../common/PageHeader'
import {ISpecialProductModel} from '../core/_models'
import {useNavigate, useSearchParams} from 'react-router-dom'
import PendingSpecialSubmit from './PendingSpecialSubmit'
import DeleteRequestBtn from '../components/DeleteRequestBtn'
import { useIntl } from 'react-intl'

const PendingSpecial = () => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const {currentUser, language} = SharedData()
  const [list, setList] = useState<ISpecialProductModel[]>([])
  // const [totalComp, setTotalComp] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setIsLoading(true)
    const {data} = await getApplySpecialProducts(currentUser?.token, language)
    // if list is empty, return the cycle
    // debugger;
    if (data.special_list?.length === 0) {
      const prevPage = search.get('from')
      let from = document.location.pathname
      if (prevPage) {
        from = prevPage
      } 
      navigate(`${from}`, {
        replace: true,
      })
    }
    setList(
      data.special_list.map((item:ISpecialProductModel) => {
        let temp = {...item}
        if (typeof temp.data == 'string') temp.data = JSON.parse(temp.data)
        return {...temp}
      })
    )

    // setTotalComp(data.total_compensation)
    setIsLoading(false)
  }

  return (
    <>
      <PageHeader title={formatMessage({id: 'add_discount_selected_prods'})}>
        <DeleteRequestBtn />
      </PageHeader>
      <div className={`card`}>
        <div className='card-body py-10'>
          <PendingSpecialTable
            // totalComp={totalComp}
            refreshData={getData}
            products={list}
          ></PendingSpecialTable>
          <PendingSpecialSubmit  products_id={list.map((item)=>item.supplier_product_special_id)} currentUser={currentUser}  />
        </div>
      </div>
      {isLoading && <Loader isFull={true} />}
    </>
  )
}

export default PendingSpecial
