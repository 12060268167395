import React, { FC, useEffect, useState } from 'react'
import SharedData from '../../../common/SharedData'
import { ILocations } from '../../core/_models'
import { getLocations } from '../../core/_requests'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
interface IProps {
  field: any
  setFields: any
  saveField: (fieldName: string) => void,
  cityID: string|number
}
const MyProfileLocationField: FC<IProps> = ({field, setFields, saveField,cityID}) => {
  const {formatMessage} = useIntl();
  const {currentUser, language} = SharedData()
  const [locationsData, setLocationsData] = useState<ILocations[]>([])
  const [location, setLocation] = useState({...field});


  useEffect(() => {
    if (cityID) {
      const getDate = async () => {
        const {data: locationsData} = await getLocations(currentUser?.token, language,cityID)
        setLocationsData(locationsData.zones)
      }
      getDate()
    }
  }, [cityID])

  const editField = (fieldID: string) => {
    setFields((prev: any) => ({
      ...prev,
      [fieldID]: {...prev[fieldID], edit: !prev[fieldID].edit},
    }))
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const {id} = e.currentTarget
    const {value} = e.target
    setFields((prev: any) => ({
      ...prev,
      [id]: {...prev[id], value, edit: !prev[id].edit},
    }))
    setLocation({...location, value: value})
  }

  useEffect(() => {
    if (location.value !== field.value || field.value === '') {
      return
    }
    saveField('location')
  }, [field.value])

  const getLocationName = () => {
    const locationIDProp = +field.value
    const locationName = locationsData.filter((item: ILocations) => item.zone_id === locationIDProp)
    if (locationName && locationName.length > 0) return <>{locationName[0].name}</>
    else return <>{formatMessage({ id: "select_location" })}</>
  }

    return (
        <div className='row'>
          <div className='col-md-6'>
            <label className='text-gray-600 fw-bold fs-5'>{formatMessage({ id: "location" })}:</label>
          </div>
          <div className='col-md-6 d-flex'>
            {!field.edit ? (
              <div className='d-flex gap-1 flex-wrap-p'>
                <p className='text-dark fs-4 mb-0'>{getLocationName()}</p>
                <div className='cursor-pointer w-25px' onClick={() => editField('location')}>
                  <img alt='edit' width='15' src={toAbsoluteUrl('/media/logos/pen-solid.svg')} />
                </div>
                {field.changed && (
                  <p className='mb-0 text-success fw-bold w-100-p'>{formatMessage({ id: "your_change_saved" })}</p>
                )}
              </div>
            ) : (
              <select
                className='form-control border-gray-900 text-gray-900'
                // type={type || 'text'}
                id='location'
                value={field.value}
                onChange={handleChange}
              >
                <option value="">{formatMessage({ id: "please_select" })}</option>
                {locationsData &&
                  locationsData.map((item: ILocations) => (
                    <option key={item.zone_id} value={item.zone_id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
      )
}

export default MyProfileLocationField