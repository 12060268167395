import axios from 'axios'
import {IBulkModel} from './_models'
import {IDuplicateBarcodeModel} from '../../cors/_models'

const API_URL = process.env.REACT_APP_API_URL
export const BULK_ACTION = `${API_URL}/api/partner-portal/product/bulk`
export const BULK_TEMPLATE = `${API_URL}/api/partner-portal/special/bulk-template`
export const BULK_IMAGES_UPLOAD = `${API_URL}/api/partner-portal/product/upload`
export const GET_DUPLICATE_BARCODES = `${API_URL}/api/partner-portal/product/duplicate-barcodes`
export const FIX_DUPLICATE_BARCODES = `${API_URL}/api/partner-portal/product/fix-duplicate-request`
export const PRODUCTS_BULK_TEMPLATE = `https://cdn1.dumyah.com/image/data/partners/Empty_bulk_sheet.xlsx`
export const PRODUCTS_UPDATE_BULK_TEMPLATE = `https://cdn1.dumyah.com/image/data/partners/Update_by_bulk_empty.xlsx`

export async function bulkAction(token: string | undefined, data: IBulkModel, images?: any) {
  const formData = new FormData()

  formData.append('sheet', data.sheet)
  formData.append('action', data.action)
  // if (images) {
  //   await uploadImagesWithBulk(images, token || '')
  // }

  return axios.post<any>(`${BULK_ACTION}`, formData, {
    responseType: 'blob',
    headers: {token: token || '', 'content-type': 'multipart/form-data'},
  })
}

export async function uploadImagesWithBulk(imagesList: any, token: string | undefined) {
  const formData = new FormData()
  formData.append('images', imagesList)
  return await axios.post<any>(`${BULK_IMAGES_UPLOAD}`, formData, {
    headers: {token: token || '', 'content-type': 'multipart/form-data'},
  })
}

// GET Bulk Template => download
export function getBulkTemplate(url: string, token: string | undefined) {
  return axios.get<any>(`${url}`, {
    responseType: 'blob', 
    headers: {token: token || ''}
  })
}

// GET DUPLICATE BARCODES
export function getDuplicateBarcodes(token: string | undefined, language?: string) {
  return axios.post<{requests: IDuplicateBarcodeModel[]}>(
    `${GET_DUPLICATE_BARCODES}`,
    {},
    {headers: {token: token || '', language: language || 'en'}}
  )
}

// FIX DUPLICATE BARCODES
export function fixDuplicateBarcode(keys: string[], token: string | undefined) {
  const formData = new FormData()

  keys.forEach((key) => {
    formData.append('request_products_id[]', key)
  })

  return axios.post<any>(`${FIX_DUPLICATE_BARCODES}`, formData, {headers: {token: token || ''}})
}
