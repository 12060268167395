import {ReactNode, useEffect, useState} from 'react'
import SharedData from '../common/SharedData'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import {getAppliedDailyDealList} from '../products/cors/_requests'
import {IAppliedDailyDealModel} from "../products/cors/_models";
import Loader from "../common/Loader";
import AppliedDailyDealTable from "../products/daily-deal/AppliedDailyDealTable";

const AppliedDailyDealList = () => {

    const {formatMessage} = useIntl();
    const [isLoading, setIsLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const {currentUser, language} = SharedData()
    const [appliedDailyDealList, setAppliedDailyDealList] = useState<IAppliedDailyDealModel[]>([])

    //TO DO : filter
    useEffect(() => {
        getDate()
    }, [searchParams])

    const getDate = async () => {

        setIsLoading(true)

        try {
            const data = await getAppliedDailyDealList(currentUser?.token, language)
            if (data.data.daily_deal_list) {
                setAppliedDailyDealList(data.data.daily_deal_list)
            } else {
                setAppliedDailyDealList([])
            }

        } catch (exp) {

        }

        setIsLoading(false)
    }

    const resultsData = (): ReactNode => {
        return (
            <div className='card-body py-3'>
                {isLoading && <Loader isFull={true}/>}
                <AppliedDailyDealTable refreshData={getDate} products={appliedDailyDealList}/>
            </div>
        )
    }

    return (
        <div className={`card`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "daily_deals_products_list" })}</span>
                </h3>
            </div>
            {resultsData()}
        </div>
    )
}

export default AppliedDailyDealList

