import {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {UserModel} from '../../../auth'
import { applySpecialPendingProducts } from '../core/_requests'
import { useIntl } from 'react-intl'

type IProps = {
  currentUser: UserModel | undefined
  products_id: number[]
  // totalComp: number | undefined
}

const PendingSpecialSubmit: FC<IProps> = ({currentUser, products_id}) => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const data = await applySpecialPendingProducts(currentUser?.token, {
        special_ids: products_id,
      })
      
      if (data) {
        localStorage.setItem('thankYouPageURL', window.location.href);
        navigate('/thank-you?go=request-add-discount')
      }
      setIsLoading(false)
    } catch (exp) {
      // alert('something went wrong!')
      setIsLoading(false)
    }
  }
  
  return (
    <div className='mt-3 mb-5 d-flex justify-content-end align-items-center gap-2'>
      {/* {totalComp !== 0 && <p className='mb-0'>By sending, you agree to pay the total compensation</p>} */}
      <button
        disabled={isLoading}
        className='btn btn-sm fs-4 py-1 px-9 btn-light  btn-close-white'
        onClick={handleSubmit}
      >
        {formatMessage({id: 'send_req'})}
      </button>
    </div>
  )
}

export default PendingSpecialSubmit
