import React from 'react'
import MyProfileInfoForm from './MyProfileInfoForm'
import { useIntl } from 'react-intl';

const MyProfileBasicInfo = () => {
  const {formatMessage} = useIntl();
  return (
    <>
      <div className='card undefined'>
        <div className='card-header pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "basic_info" })}</span>
          </h3>
        </div>
        <div className='card-body py-3 pt-10'>
          <MyProfileInfoForm />
        </div>
      </div>
    </>
  )
}

export default MyProfileBasicInfo
