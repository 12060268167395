/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, useEffect, useState} from 'react'
import PurchaceReturnOrdersTable from './components/PoTable/PurchaceReturnOrdersTable'
import {useAuth} from '../auth'
import { PO} from './core/_models'
import NormalMessage from '../common/NormalMessage'
import Loader from '../common/Loader'
import PurchaceReturnOrdersFilters2 from './components/PurchaceReturnOrdersFilters2'
import {getPurchaseReturnOrders, prepareFilters} from './core/_requests'
import Paginator from '../common/Paginator'
import {useSearchParams} from 'react-router-dom'
import { useIntl } from 'react-intl'
import SharedData from '../common/SharedData'
import * as XLSX from 'xlsx';
import fileDownload from "js-file-download";
type Props = {
  className?: string
}

const PurchaceReturnOrders: React.FC<Props> = ({className}) => {
  const {formatMessage} = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const {currentUser,language} = SharedData()
  const [poList, setPoList] = useState<PO[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [canFilter, setCanFilter] = useState(true)
  const [totalResult, setTotalResult] = useState<number>(0)

 

  useEffect(() => {

    const getDate = async () => {
      setIsLoading(true)
      const queryParams = document.location.search
      const {data: data} = await getPurchaseReturnOrders(queryParams, currentUser?.token,language)
      
      if (data.data.hasOwnProperty('po_list')) {
        setPoList(data.data.po_list)
        setTotalResult(data.data.total_po)
      } else {
        setTotalResult(0)
        setPoList([])
      }
      setIsLoading(false)
    }
    getDate()
  }, [searchParams])

  const ExportPOReport = async (): Promise<void> => {
    try {
      searchParams.set('export', '1');
      setSearchParams(searchParams);

      prepareFilters(searchParams, setSearchParams);
      const queryParams = document.location.search;
      const {data: data} = await getPurchaseReturnOrders(queryParams, currentUser?.token, language);

      const worksheet = XLSX.utils.json_to_sheet(data.data.po_list);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, 'POs Report');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        try {
          fileDownload(blob, 'POs Report.xlsx');
          searchParams.delete('export');
          setSearchParams(searchParams);
        } catch (error) {
          console.error('Error during file download:', error);
        }
    } catch (error) {
      console.error('Error preparing export data:', error);
    }
  };
  const resultsData = (): ReactNode => {
    if (isLoading) {
      return <Loader />
    }
    if (poList.length > 0) {
      return (
        <div className='card-body py-3'>
          <PurchaceReturnOrdersTable orders={poList} />
          <Paginator totalResult={totalResult} />
        </div>
      )
    }
    return <NormalMessage msg={formatMessage({ id: "no_data" })} />
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{formatMessage({ id: "por_title" })}</span>
        </h3>
        {/* begin::Menu */}
        <div className='card-toolbar'>
          <button
            type='button'
            className={`${
              canFilter && 'active'
            } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
            onClick={() => setCanFilter(!canFilter)}
          >
            {formatMessage({ id: "filter" })} <i className='fas fa-filter'></i>
          </button>
          <button
              type='button'
              className={`${
                  canFilter && 'active'
              } btn btn-sm  btn-color-primary btn-active-light-primary fs-6 gap-2`}
              onClick={ExportPOReport}
          >
            {formatMessage({ id: "export" })} <i className="fa-solid fa-file-export"></i>
          </button>
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Header */}

      {canFilter && <PurchaceReturnOrdersFilters2 />}

      {resultsData()}
    </div>
  )
}

export {PurchaceReturnOrders}
